import React, { useMemo } from 'react';
import { unRef } from 'bb/utils';
import { type MaybeRef } from '../types';

export const useOutsideClick = <
    THTMLElement extends HTMLElement | null | undefined
>(
    ref: MaybeRef<THTMLElement>,
    callback: () => void,
    enabled = false,
    eventName: 'click' | 'mousedown' | 'mouseup' = 'click'
) => {
    const element = useMemo(() => unRef(ref), [ref]);

    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        if (enabled) {
            const handleClick = (event: Event) => {
                if (element && !element.contains(event.target as Node)) {
                    callback();
                }
            };

            document.addEventListener(eventName, handleClick);

            return () => {
                document.removeEventListener(eventName, handleClick);
            };
        }
    }, [callback, element, enabled, eventName]);

    return ref;
};
