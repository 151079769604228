import {
    type AppViewItemResponse,
    AppViewItemType,
    type BookListResponse
} from '../types';

// Handle BookList and BookTopList the same for now (treated diffrently in the app)
export const isBookListOrBookTopList = (
    item: AppViewItemResponse
): item is BookListResponse =>
    item.type === AppViewItemType.BookList ||
    item.type === AppViewItemType.BookTopList;
