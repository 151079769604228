// @ts-strict-ignore
import { type LoggerStore, type LogPayload } from './types';

const initialState = {
    message: null
};

export function loggerStore(): LoggerStore {
    return {
        ...initialState,

        setMessage(msg: LogPayload) {
            this.message = msg;
        }
    };
}
