import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const AbcIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -4 16 16">
            <path d="M3.799 2.07h.016l.558 2.429h-1.14zM2.701 6.78l.324-1.265h1.59l.29 1.264h1.099L4.556.772H3.084l-1.49 6.007h1.107zm3.757-.001h1.68c1.216 0 2.015-.54 2.015-1.73 0-.75-.25-1.082-.883-1.482.483-.291.741-.723.741-1.248 0-1.032-.707-1.547-1.938-1.547H6.458Zm1.148-2.68h.4c.599 0 .998.284.998.858 0 .524-.316.873-.915.873h-.483Zm0-2.378h.458c.54 0 .799.282.799.723 0 .408-.342.707-.858.707h-.399Zm4.096.915c0-.666.291-.85.749-.85s.749.184.749.849v.167h1.148c0-1.497-.782-2.03-1.896-2.03-1.116 0-1.898.533-1.898 2.013v2.147c0 1.48.783 2.013 1.897 2.013 1.115 0 1.897-.533 1.897-2.013v-.25H13.2v.4c0 .665-.291.848-.749.848s-.749-.183-.749-.848z" />
        </svg>
    </Icon>
);
