// @ts-strict-ignore
import { useRef, useEffect, type ReactNode } from 'react';
import { useRouter } from 'bb/app/router';
import { findRouteByPath } from 'bb/app/router/utils';
import { useNavigationTracker } from 'bb/tracker/hooks/useNavigationTracker';

export const TrackNavigation = (): ReactNode => {
    const clientNavigation = useRef(false);
    const { router, routes } = useRouter();
    const tracker = useNavigationTracker();

    useEffect(() => {
        if (clientNavigation.current) {
            const currentRoute = findRouteByPath(router.pathname);

            currentRoute &&
                tracker.navigation(router.asPath, currentRoute.name);
        } else {
            clientNavigation.current = true;
        }
    }, [router.pathname, router.asPath, routes, tracker]);

    return null;
};
