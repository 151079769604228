import { type LogFormatter, type LogPayload } from './types';

export const simpleFormatter: LogFormatter = ({
    message,
    level,
    ...data
}): LogPayload => {
    let formattedMessage = message;
    data &&
        Object.keys(data).forEach((key) => {
            if (data[key]) {
                formattedMessage += ` ${key}=${data[key]}`;
            }
        });
    return {
        message: formattedMessage,
        level,
        ...data
    };
};

export const defaultFormatter: LogFormatter = ({
    message,
    level,
    ...data
}): LogPayload => ({
    message,
    level,
    ...data
});
