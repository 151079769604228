import React from 'react';
import { COLOR_GRAY_01, COLOR_GRAY_06 } from '../Styles';
import { Icon, type BaseIconProps } from './Icon';

export const NoteCircleIcon: React.FunctionComponent<BaseIconProps> = ({
    color,
    inline,
    className,
    size,
    onClick
}) => {
    // swap inner color based on outer color
    const lightColor = COLOR_GRAY_01;
    const darkColor = COLOR_GRAY_06;

    const innerColor =
        !color || color === 'primary-black' ? lightColor : darkColor;

    return (
        <Icon
            inline={inline}
            className={className}
            size={size}
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <g fillRule="evenodd">
                    <path d="M 20,10 A 10,10 0 0 1 10,20 10,10 0 0 1 0,10 10,10 0 0 1 10,0 10,10 0 0 1 20,10 Z" />
                    <path
                        fill={innerColor}
                        d="M10.5 5v9c0 1.105-1.007 2-2.25 2S6 15.105 6 14s1.007-2 2.25-2c.463 0 .892.124 1.25.337V4.5a.5.5 0 0 1 .552-.497.506.506 0 0 1 .051-.003h.397A3.5 3.5 0 0 1 14 7.5c0 .752-.031 1-.247 1.367a2.296 2.296 0 0 1-.408.495.5.5 0 1 1-.69-.724 1.34 1.34 0 0 0 .237-.279c.091-.155.108-.29.108-.859A2.5 2.5 0 0 0 10.5 5z"
                    />
                </g>
            </svg>
        </Icon>
    );
};
