import type React from 'react';
import { withHookFormSelect } from '../hookForm';
import { MarketPhoneSelect, type MarketPhoneSelectProps } from './components';
import { Select } from './Select';

export * from './SelectItem';
export * from './SelectDropdown';
export * from './Select.types';
export * from './useSelect';

/**
 * Needs a control to be passed from react-hook-form.
 */
export const HookFormSelect = withHookFormSelect(Select);
export type HookFormSelectProps = React.ComponentProps<typeof HookFormSelect>;

/**
 * Needs a control to be passed from react-hook-form.
 */
export const HookFormMarketPhoneSelect = withHookFormSelect(MarketPhoneSelect);
export type HookFormMarketPhoneSelectProps = React.ComponentProps<
    typeof HookFormMarketPhoneSelect
>;

export { Select, MarketPhoneSelect };

export type { MarketPhoneSelectProps };
