import React, { type ElementType } from 'react';
import classnames from 'classnames';
import { type Color } from 'bb/style/types';
import { getContrastColor, makeCSSVariableFromColor } from 'bb/style/utils';
import { type PolymorphicRef } from '../types';
import commonCss from './button.common.module.scss';
import css from './button.module.scss';
import { type ButtonVariant, type ButtonProps } from './Button.types';
import { ButtonContent } from './ButtonContent';

export const getActiveColor = (color: Color, variant: ButtonVariant) => {
    if (variant === 'outlined') return color;

    if (color === 'primary-black') return 'gray-04';

    return getContrastColor(color);
};

const ButtonComponent = (
    props: ButtonProps<ButtonVariant, 'button'>,
    ref?: PolymorphicRef<'button'>
) => {
    const {
        as: Component = 'button',
        variant = 'contained',
        startAdornment,
        endAdornment,
        children,
        color = 'primary-purple',
        className,
        size = 'large',
        fluid = false,
        block = false,
        isLoading = false,
        disableAnimation = false,
        textProps = {},
        'data-key': dataKey = 'bb-button',
        ...restProps
    } = props;

    const activeColor = getActiveColor(color, variant);

    return (
        <Component
            {...restProps}
            data-key={dataKey}
            className={classnames(
                css.root,
                css[`variant-${variant}`],
                css[`size-${size}`],
                fluid && commonCss.fluid,
                block && commonCss.block,
                isLoading &&
                    !disableAnimation &&
                    classnames(css.isLoading, css.isLoadingAnimation),
                !disableAnimation && size !== 'small' && css.activeAnimation,
                className
            )}
            style={{
                ...makeCSSVariableFromColor('--button-primary-color', color),
                ...makeCSSVariableFromColor(
                    '--button-active-color',
                    activeColor
                ),
                ...makeCSSVariableFromColor(
                    '--button-text-color',
                    variant === 'contained' ? getContrastColor(color) : color
                ),
                ...makeCSSVariableFromColor(
                    '--button-active-text-color',
                    getContrastColor(activeColor)
                )
            }}
            ref={ref}
        >
            <ButtonContent
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                textProps={textProps}
            >
                {children}
            </ButtonContent>
        </Component>
    );
};

export const Button = React.forwardRef(ButtonComponent) as <
    TVariant extends ButtonVariant = 'contained',
    TElementType extends ElementType = 'button'
>(
    props: ButtonProps<TVariant, TElementType>
) => JSX.Element;
