import { type UseApiOptions, useApi } from 'bb/api/browser/useApi';
import { type NextApi } from 'bb/api/types';
import { useAccountStore } from './useAccountStore';

export const useVerifyLoggedIn = (
    config?: UseApiOptions<NextApi<'/api/login/verify', 'get'>['response']>
) => {
    const accountStore = useAccountStore();

    return useApi('/api/login/verify', {
        ...config,
        onSuccess: ({ isLoggedIn }) => accountStore.setIsLoggedIn(isLoggedIn),
        revalidateOnFocus: true
    });
};

export const useIsLoggedIn = () => {
    const { data: { isLoggedIn } = { isLoggedIn: false } } =
        useVerifyLoggedIn();

    return isLoggedIn;
};
