import { createPortal } from 'react-dom';
import { isServer } from 'bb/utils/environment';

export const getModalRoot = () => document.body;

export type CreateModalPortalNode = Parameters<typeof createPortal>[0];

export const createModalPortal = (node: CreateModalPortalNode) => {
    if (isServer()) return null;

    return createPortal(node, getModalRoot());
};
