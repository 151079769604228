import useSWRImmutable from 'swr/immutable';
import type { Feature, Features } from '../types';

interface UseFetchFeatures {
    (skip?: boolean): Features | undefined;
    (name: string, skip?: boolean): boolean;
}

/**
 * This is mainly used as part of the `useFeature` hook. Don't reach for this if you don't have to for some reason.
 */
export const useFetchFeatures: UseFetchFeatures = (
    arg1: string | boolean = false,
    arg2 = false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
    const single = typeof arg1 !== 'boolean';
    const skip = single ? arg2 : arg1;
    const { data } = useSWRImmutable<Features>('/api/features', {
        isPaused: () => Boolean(skip)
    });

    return single ? (data?.[arg1 as Feature] ?? false) : data;
};
