import React from 'react';
import { HashtagIcon } from 'bb/ui/Icons/svgs';
import { Input } from '../Input';
import { type InputProps } from '../Input.types';

export type IdentityInputProps = Omit<InputProps, 'startAdornment'>;

export const IdentityInput = React.forwardRef<
    HTMLInputElement,
    IdentityInputProps
>((props, ref) => (
    <Input startAdornment={<HashtagIcon size="small" />} ref={ref} {...props} />
));
