import { useContext, useEffect } from 'react';
import { Debug } from 'bb/app/debug';
import { FeatureContext } from '../context';
import type { Feature, Features } from '../types';

const debug = Debug('feature');

interface UseFeature {
    (): Features;
    (name: Feature): boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFeature: UseFeature = (name?: Feature): any => {
    const features = useContext(FeatureContext);

    useEffect(() => {
        if (
            name &&
            typeof features[name] === 'undefined' &&
            Object.keys(features).length
        ) {
            debug.warn(`Feature flag "${name}" does not exist`);
        }
    }, [name, features]);

    return name ? (features[name] ?? false) : features;
};
