export const cleanQueryParams = (url: string, paramsToOmit: string[]) => {
    const [path, query] = url.split('?');

    const searchParams = new URLSearchParams(query);

    paramsToOmit.forEach((param) => {
        searchParams.delete(param);
    });

    return [path, searchParams.toString()].filter(Boolean).join('?');
};
