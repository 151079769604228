import React from 'react';
import ImportedSkeleton, {
    type SkeletonProps as ImportedSkeletonProps
} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export type SkeletonProps = ImportedSkeletonProps;

export const Skeleton = (props: SkeletonProps) => {
    const {
        /**
         * We don't typically have borderRadius on our skeletons so we default it to 0.
         */
        borderRadius = 0,
        ...restProps
    } = props;

    return <ImportedSkeleton {...restProps} borderRadius={borderRadius} />;
};
