// @ts-strict-ignore
import { useMemo, useContext } from 'react';
import { AppContext } from 'bb/app';
import { Debug } from 'bb/app/debug';
import { type TrackingStrategy } from 'bb/tracker/types';
import { cleanQueryParams } from 'bb/utils';

type NavigationTracker = {
    navigation(url: string, routeName?: string): void;
};

const debug = Debug('useNavigationTracker');

/**
 * For GDPR reasons we want to clean the url of data
 * that cannot be stored in Analytics.
 */
const QUERY_PARAMS_TO_OMIT = ['email', 'token', 'mlt'];

const navigationTracker = (tracker: TrackingStrategy): NavigationTracker => ({
    navigation: (url, routeName): void => {
        tracker.track({
            event: 'navigation',
            url: cleanQueryParams(url, QUERY_PARAMS_TO_OMIT),
            routeName
        });
        debug.info(`track navigation to ${url}`);
    }
});

export const useNavigationTracker = (): NavigationTracker => {
    const { trackingStrategy } = useContext(AppContext);
    return useMemo(
        () => navigationTracker(trackingStrategy),
        [trackingStrategy]
    );
};
