import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const CopyIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m10.376 7.252-1.04 1.04c.37.036.693.114.968.235.276.12.512.277.708.468.36.36.601.757.722 1.193.12.435.12.869 0 1.301-.12.433-.361.83-.722 1.19L8.851 14.83c-.36.36-.757.6-1.19.721-.434.12-.867.12-1.3 0-.433-.12-.83-.361-1.19-.72-.36-.36-.6-.757-.722-1.19a2.37 2.37 0 0 1 0-1.298c.121-.433.36-.83.715-1.19l1.04-1.038a3 3 0 0 1-.209-.813c-.034-.292-.02-.57.045-.834l-1.834 1.824c-.533.533-.89 1.123-1.07 1.77a3.587 3.587 0 0 0-.003 1.945c.177.649.538 1.242 1.08 1.78.538.542 1.132.902 1.782 1.08.65.177 1.299.176 1.946-.004.648-.18 1.238-.536 1.772-1.07l2.264-2.261c.533-.533.89-1.123 1.07-1.77.18-.647.18-1.295 0-1.945-.18-.649-.539-1.242-1.077-1.78a2.883 2.883 0 0 0-.667-.478 3.278 3.278 0 0 0-.927-.308Zm-.752 5.502 1.033-1.04a3.19 3.19 0 0 1-.968-.235 2.12 2.12 0 0 1-.694-.475c-.365-.36-.608-.756-.729-1.189a2.37 2.37 0 0 1 0-1.298c.12-.433.36-.83.715-1.19l2.168-2.159c.356-.36.75-.6 1.183-.718a2.42 2.42 0 0 1 1.3 0c.433.119.832.358 1.197.718.36.365.6.764.722 1.2.12.434.12.867 0 1.298-.121.43-.362.826-.722 1.185l-1.033 1.04c.105.245.175.514.209.806.034.291.02.571-.045.84l1.834-1.824c.538-.538.896-1.13 1.073-1.774A3.575 3.575 0 0 0 16.864 6c-.18-.65-.54-1.243-1.077-1.78-.543-.543-1.138-.904-1.786-1.084a3.562 3.562 0 0 0-1.942 0c-.648.18-1.236.539-1.765 1.076l-2.27 2.262c-.534.533-.89 1.123-1.068 1.77a3.62 3.62 0 0 0-.003 1.941c.175.647.534 1.242 1.077 1.784.182.177.404.335.664.471.26.137.57.242.93.315Z"
                fillRule="nonzero"
            />
        </svg>
    </Icon>
);
