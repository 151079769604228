import { useVerifyLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { createUseApiHook } from 'bb/api/browser/useApi';

export const useVerifyIpHook = createUseApiHook('/api/user/verifyIp', {
    fixed: true,
    shouldRetryOnError: false
});

export const useVerifyIp = () => {
    const verifyLoggedIn = useVerifyLoggedIn();

    return useVerifyIpHook({
        enabled: verifyLoggedIn.data?.isLoggedIn === false
    });
};
