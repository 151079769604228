import { SupportedPaymentMethods } from 'bb/config/paymentMethods';
import { type PaymentMethodsResponse } from 'bb/config/types';

const PaymentMethodValues = Object.values(SupportedPaymentMethods);

export const getSupportedPaymentMethods = (data?: PaymentMethodsResponse) => {
    const supportedPaymentMethods = data?.paymentMethods?.filter(
        (paymentMethod) =>
            PaymentMethodValues.includes(
                paymentMethod.type as (typeof PaymentMethodValues)[number]
            )
    );
    const formattedPaymentMethods = supportedPaymentMethods?.map((p) => ({
        isDefault: p.isDefault,
        type: p.type,
        name: p.type,
        ...p.details
    }));

    return formattedPaymentMethods?.length
        ? formattedPaymentMethods
        : undefined;
};
