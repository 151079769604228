import { useEffect } from 'react';
import { unRef } from 'bb/utils';
import { type MaybeRef } from '../types';

let previousBodyPaddingRight: string | undefined;
let previousBodyOverflowSetting: string | undefined;
let previousBodyPositionSetting: string | undefined;
let scrollPosition: number | undefined;
let activeLocks = 0;

function lock() {
    setTimeout(() => {
        if (activeLocks > 0) {
            activeLocks += 1;
            return;
        }

        const scrollBarGap =
            window.innerWidth - document.documentElement.clientWidth;

        if (scrollBarGap > 0) {
            previousBodyPaddingRight = document.body.style.paddingRight;
            document.body.style.paddingRight = `${scrollBarGap}px`;
        }

        previousBodyOverflowSetting = document.body.style.overflow;
        previousBodyPositionSetting = document.body.style.position;
        scrollPosition = window.scrollY;
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.left = '0';
        document.body.style.right = '0';
        activeLocks += 1;
    });
}

function unlock() {
    setTimeout(() => {
        if (activeLocks === 0) {
            return;
        }
        activeLocks -= 1;
        if (activeLocks !== 0) {
            return;
        }

        if (previousBodyPaddingRight !== undefined) {
            document.body.style.paddingRight = previousBodyPaddingRight;
            previousBodyPaddingRight = undefined;
        }

        if (previousBodyOverflowSetting !== undefined) {
            document.body.style.overflow = previousBodyOverflowSetting;
            previousBodyOverflowSetting = undefined;
        }

        if (previousBodyPositionSetting !== undefined) {
            document.body.style.position = previousBodyPositionSetting;
            previousBodyPositionSetting = undefined;
        }

        if (scrollPosition !== undefined) {
            document.body.style.top = '';
            document.body.style.left = '';
            document.body.style.right = '';
            window.scrollTo(0, scrollPosition);
            scrollPosition = undefined;
        }
    });
}

export const useLockBodyScroll = (
    lockScroll: boolean,
    targetElement: MaybeRef<HTMLElement | null>
): void => {
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const element = unRef(targetElement);
        if (element) {
            if (lockScroll) {
                lock();
            } else {
                unlock();
            }
            return () => {
                unlock();
            };
        }
    }, [lockScroll, targetElement]);
};
