/**
 * Returns the key of the object that satisfies the comparison function.
 */
export const getKey = <
    TObject extends Record<string, unknown>,
    TValue extends TObject[keyof TObject]
>(
    object: TObject,
    comparisonFn: (value: TValue) => boolean
) => Object.keys(object).find((key) => comparisonFn(object[key] as TValue));
