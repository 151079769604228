import React, { type FunctionComponent } from 'react';
import { observer } from 'bb/app/stores';
import { useFormatAmount } from './useFormatAmount';

export const Amount: FunctionComponent<{
    amount?: number;
}> = observer(({ amount }) => {
    const formattedAmount = useFormatAmount(amount);

    return <>{formattedAmount}</>;
});
