import { useTrackingStrategy } from './useTrackingStrategy';

export const useFormValidationTracker = () => {
    const { track } = useTrackingStrategy();

    return {
        validationError: (category: string, name: string) =>
            track({ event: 'formValidation', category, name })
    };
};
