import { useIsClient } from './useIsClient';

/**
 * Returns handler that is only invoked when isClient is true.
 * The return value is inferred, but defaults to null when the
 * state is false.
 */
export const useClientHandler = () => {
    const isClient = useIsClient();

    return <T extends () => unknown>(cb: T) =>
        (isClient ? cb() : null) as ReturnType<typeof cb> | null;
};
