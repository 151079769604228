import React from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useIsLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { useRouter } from 'bb/app/router';
import Logo from 'bb/common/Logo';
import { useTranslation } from 'bb/i18n';
import { Row } from 'bb/ui/Flex/Row';
import { Link } from '../Link';
import { NAV_BAR_ID } from './consts';
import css from './navbar.module.scss';

const SideMenu = dynamic(
    () => import('./SideMenu').then((mod) => mod.SideMenu),
    { ssr: true }
);

export type SlimNavbarProps = {
    /**
     * Whether to show the side menu or not.
     *
     * Defaults to the value of `useIsLoggedIn()`.
     */
    showSideMenu?: boolean;
};

export const SlimNavbar = (props: SlimNavbarProps) => {
    const { t } = useTranslation(['common', 'nav']);
    const { routes } = useRouter();
    const isLoggedIn = useIsLoggedIn();
    const { showSideMenu = isLoggedIn } = props;

    return (
        <nav
            role="navigation"
            aria-label={t('nav:mobileNavBar')}
            className={classnames(css.slim)}
            id={NAV_BAR_ID}
        >
            <Row
                justifyContent={showSideMenu ? 'spaceBetween' : 'center'}
                alignItems="center"
                className={css.container}
            >
                {showSideMenu && <div className={css.spacer} />}

                <Link
                    route={routes.start}
                    title={t('common:startPageLinkLabel')}
                >
                    <span className={css.slimLogo}>
                        <Logo variant="black" />
                    </span>
                </Link>
                {showSideMenu && (
                    <SideMenu
                        showGiftCard={false}
                        showStudent={false}
                        inverted={false}
                    />
                )}
            </Row>
        </nav>
    );
};
