import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const ArrowRightIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="m11.997 15.602 5.868-5.67-5.868-5.847-.982.911 2.306 2.452 2.087 1.909-1.582-.094H3.5v1.359h10.326l1.59-.095-2.095 1.91-2.285 2.24z"
                fillRule="evenodd"
            />
        </svg>
    </Icon>
);
