import { useRef, useState, useEffect } from 'react';
import { type AnyFunction } from '../types';
import { useStableCallback } from './useStableCallback';

/**
 * Like useState but accepts a second callback argument which
 * triggers everytime the state value is changed.
 */
export const useStateWithOnChange = <
    TAnyFunction extends AnyFunction,
    TState = undefined
>(
    passedInitialValue: TState,
    passedCallback: TAnyFunction | undefined
) => {
    const lastValueRef = useRef(passedInitialValue);
    const state = useState(lastValueRef.current);
    const callback = useStableCallback(passedCallback);

    useEffect(() => {
        if (state[0] !== lastValueRef.current) {
            callback(state[0]);
            // eslint-disable-next-line prefer-destructuring
            lastValueRef.current = state[0];
        }
    }, [callback, state]);

    return state;
};
