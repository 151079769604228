import React, { type PropsWithChildren, useEffect, useState } from 'react';
import { isShallowEqualObjects } from 'bb/discovery/utils';
import { FeatureContext } from '../context';
import { useFetchFeatures } from '../hooks/useFetchFeatures';
import { type Features } from '../types';

export type FeatureProviderProps = PropsWithChildren<{
    features?: Features;
    addToWindowObject?: boolean;
}>;

export const FeatureProvider = ({
    children,
    features,
    addToWindowObject = false
}: FeatureProviderProps) => {
    const [memoizedFeatures, setNewValue] = useState<Features>(
        (features ?? {}) as Features
    );

    const fetchedFeatures = useFetchFeatures(Boolean(features));

    if (features && typeof window !== 'undefined' && addToWindowObject) {
        window.__PUBLIC_FEATURES__ = features;
    }

    useEffect(() => {
        if (features)
            setNewValue((state) =>
                !isShallowEqualObjects(state, features) ? features : state
            );
    }, [features]);

    return (
        <FeatureContext.Provider value={fetchedFeatures ?? memoizedFeatures}>
            {children}
        </FeatureContext.Provider>
    );
};
