import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const MoreIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path d="M1 8h3v3H1ZM8.5 8h3v3h-3zM16 8h3v3h-3z" />
            </g>
        </svg>
    </Icon>
);
