// check if x is undefined or not
export const isDef = <T>(x: T): x is NonNullable<T> =>
    typeof x !== 'undefined' && x !== null;

export const isObject = (val: unknown) => {
    return val !== null && typeof val === 'object';
};

/**
 * Used as enum
 */
export const MobileAgents = {
    Android: 'Android',
    webOS: 'webOS',
    iPhone: 'iPhone',
    iPad: 'iPad',
    iPod: 'iPod',
    IEMobile: 'IEMobile',
    OperaMini: 'Opera Mini'
} as const;

export const MOBILE_DEVICES = Object.values(MobileAgents);

export const isUserAgent = (agents: string[]) =>
    new RegExp(agents.join('|'), 'i').test(navigator.userAgent);

/**
 * There is not a 100% solid way to check if a user is on
 * a mobile. We can either check userAgent, which can be
 * spoofed, or we can rely on screen size, which can be
 * small even on a larger device.
 *
 * In the future we might want to use:
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/mobile
 * when/if it gets wider browser support.
 */
export const isMobileDevice = () => isUserAgent(MOBILE_DEVICES);

export const isRef = (obj: unknown): boolean =>
    obj !== null &&
    typeof obj === 'object' &&
    Object.prototype.hasOwnProperty.call(obj, 'current');

export const isInternalUrl = (url: string) => {
    try {
        const baseUrl = window.location.origin;
        const parsedUrl = new URL(url, baseUrl);
        const currentHostname = window.location.hostname;
        return parsedUrl.hostname === currentHostname;
    } catch (_e) {
        // If URL constructor throws an error, it's likely an invalid URL
        return false;
    }
};
