import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const InfoIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
            <path
                d="M18.83 34.146c8.32 0 15.064-6.794 15.064-15.176 0-8.381-6.744-15.176-15.064-15.176S3.766 10.589 3.766 18.97c0 8.382 6.744 15.176 15.064 15.176zm1.253-23.952c-.497 0-.92.179-1.27.535a1.8 1.8 0 0 0-.513 1.293c0 .506.175.937.525 1.294.35.356.77.535 1.258.535.497 0 .92-.179 1.27-.535a1.78 1.78 0 0 0 .525-1.294c0-.506-.175-.936-.525-1.293a1.688 1.688 0 0 0-1.27-.535zm.757 5.722-.043.15.043-.005zm-.043.15c-1.608.237-3.213.358-4.823.581l-.165.734c.17-.009.317-.003.44-.003.268 0 .48.07.635.211a.64.64 0 0 1 .231.485c0 .29-.081.429-.244 1l-1.697 5.947c-.139.472-.208.861-.208 1.168 0 .465.155.85.464 1.157.318.299 1.31.448 1.84.448 1.384 0 3.667-.752 4.896-2.933l-.988-.587c-.513.804-1.44 1.386-1.863 1.647-.457.222-.706.206-.844.206-.081 0-.16-.037-.232-.112a.389.389 0 0 1-.11-.26c0-.158.07-.49.207-.996z"
                fillRule="evenodd"
            />
        </svg>
    </Icon>
);
