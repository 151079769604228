import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const BookmarkIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M3.35 1.35v17.933l6.683-4.904 6.617 4.914V1.35Zm1.3 1.3h10.7V16.71l-5.313-3.947-.387.283-5 3.672z"
                fillRule="evenodd"
            />
        </svg>
    </Icon>
);
