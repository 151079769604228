import { SAME_SITE_DEFAULT } from './config';

export const getCookieValue = (cookieName: string): string => {
    if (typeof document !== 'undefined') {
        const name = `${cookieName}=`;
        const cookieParts = document.cookie.split(';');
        return (
            cookieParts
                .find((cookiePart) => cookiePart.trim().startsWith(name))
                ?.trim()
                .substring(name.length) || ''
        );
    }
    return '';
};

type SetCookieValue = {
    name: string;
    value: string;
    maxAge?: number; // Seconds
    setSecure?: boolean;
    path?: string;
};

export const setCookieValue = ({
    name,
    value,
    maxAge,
    setSecure = true,
    path = '/'
}: SetCookieValue): void => {
    if (typeof document !== 'undefined') {
        const host = window.location.hostname;
        const domain = `domain=${
            host.includes('bookbeat')
                ? host.split('.').slice(1).join('.')
                : host
        }`;
        const samesite = `samesite=${SAME_SITE_DEFAULT.toLowerCase()}`;
        const expires = maxAge ? `max-age=${maxAge};` : '';
        const secure = setSecure ? 'Secure;' : '';
        document.cookie = `${name}=${value}; path=${path}; ${domain}; ${samesite}; ${secure} ${expires}`;
    }
};
