import React from 'react';
import { AvatarIcon } from 'bb/ui/Icons/svgs';
import { Input } from '../Input';
import { type InputProps } from '../Input.types';

export type AvatarInputProps = Omit<InputProps, 'startAdornment'>;

export const AvatarInput = React.forwardRef<HTMLInputElement, AvatarInputProps>(
    (props, ref) => (
        <Input
            startAdornment={<AvatarIcon size="small" />}
            ref={ref}
            {...props}
        />
    )
);
