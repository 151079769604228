import {
    observer,
    Observer,
    useLocalObservable,
    enableStaticRendering
} from 'mobx-react-lite';
import { isServer } from 'bb/utils/environment';

enableStaticRendering(isServer());

export { observer, Observer, useLocalObservable };
export { Dehydrate, DehydrateLocalState } from './Dehydrate';
export { getHydratedState } from './getHydratedState';

export { createStore } from './simpleStore';
