// @ts-strict-ignore
import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { type AlignItems, type GapSize } from '../../types';
import alignCss from '../alignItems.module.scss';
import flexWrap from '../flexWrap.module.scss';
import gapCss from '../gap.module.scss';
import justifyContentCss from '../justifyContent.module.scss';
import { type FlexWrapType, type JustifyContentType } from '../types';
import css from './flex.module.scss';

type Direction = 'row' | 'column';

export type FlexProps = {
    className?: string;
    wrap?: FlexWrapType;
    gap?: GapSize;
    alignItems?: AlignItems;
    justifyContent?: JustifyContentType;
    direction?: Direction;
    children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
    (
        {
            className,
            justifyContent,
            wrap = 'wrap',
            gap,
            alignItems,
            direction = 'row',
            children,
            ...restProps
        },
        ref
    ) => (
        <div
            ref={ref}
            className={classnames(
                css.base,
                className,
                css[direction],
                flexWrap[wrap],
                {
                    [gapCss[gap as string]]: Boolean(gap),
                    [alignCss[alignItems as string]]: Boolean(alignItems),
                    [justifyContentCss[justifyContent as string]]:
                        Boolean(justifyContent)
                }
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        >
            {children}
        </div>
    )
);
