import { withHookFormInput } from '../hookForm/helpers';
import {
    CreatePasswordInput,
    type CreatePasswordInputProps,
    EmailInput,
    type EmailInputProps,
    PasswordInput,
    type PasswordInputProps,
    AvatarInput,
    type AvatarInputProps,
    FeedbackInput,
    type FeedbackInputProps,
    GiftcardInput,
    type GiftcardInputProps,
    OfferInput,
    type OfferInputProps,
    IdentityInput,
    type IdentityInputProps,
    FeedbackTextarea
} from './components';
import { Input } from './Input';
import { Textarea } from './Textarea';

export * from './Input.types';

/**
 * Hook Form input
 */

// Base
export const HookFormInput = withHookFormInput(Input);
export type HookFormInputProps = React.ComponentProps<typeof HookFormInput>;

// Other
export const HookFormIdentityInput = withHookFormInput(IdentityInput);
export type HookFormIdentityInputProps = React.ComponentProps<
    typeof HookFormIdentityInput
>;

export const HookFormAvatarInput = withHookFormInput(AvatarInput);
export type HookFormAvatarInputProps = React.ComponentProps<
    typeof HookFormAvatarInput
>;

export const HookFormFeedbackInput = withHookFormInput(FeedbackInput);
export type HookFormFeedbackInputProps = React.ComponentProps<
    typeof HookFormFeedbackInput
>;

export const HookFormGiftcardInput = withHookFormInput(GiftcardInput);
export type HookFormGiftcardInputProps = React.ComponentProps<
    typeof HookFormGiftcardInput
>;

export const HookFormOfferInput = withHookFormInput(OfferInput);
export type HookFormOfferInputProps = React.ComponentProps<
    typeof HookFormOfferInput
>;

export const HookFormPasswordInput = withHookFormInput(PasswordInput);
export type HookFormPasswordInputProps = React.ComponentProps<
    typeof HookFormPasswordInput
>;

/**
 * Needs a control to be passed from react-hook-form in order
 * to give live feedback on validation errors.
 */
export const HookFormCreatePasswordInput =
    withHookFormInput(CreatePasswordInput);
export type HookFormCreatePasswordInputProps = React.ComponentProps<
    typeof HookFormCreatePasswordInput
>;

export const HookFormEmailInput = withHookFormInput(EmailInput);
export type HookFormEmailInputProps = React.ComponentProps<
    typeof HookFormEmailInput
>;

/**
 * Hook Form textarea
 */
export const HookFormTextarea = withHookFormInput(Textarea);
export type HookFormTextareaProps = React.ComponentProps<
    typeof HookFormTextarea
>;

export const HookFormFeedbackTextarea = withHookFormInput(FeedbackTextarea);
export type HookFormFeedbackTextareaProps = React.ComponentProps<
    typeof HookFormFeedbackTextarea
>;

/**
 * Actual inputs
 */
export {
    AvatarInput,
    AvatarInputProps,
    CreatePasswordInput,
    CreatePasswordInputProps,
    EmailInput,
    EmailInputProps,
    FeedbackInput,
    FeedbackInputProps,
    GiftcardInput,
    GiftcardInputProps,
    Input,
    OfferInput,
    OfferInputProps,
    PasswordInput,
    PasswordInputProps,
    Textarea,
    IdentityInput,
    IdentityInputProps
};
