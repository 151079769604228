/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import { type StaticImageData } from 'next/image';
import { Icon } from '../Icon';
import { type ImageIconProps } from './imageIcon.types';

export const ImageIcon = (props: ImageIconProps) => {
    const { svg, alt = '', ...restProps } = props;
    let src: StaticImageData['src'] = '';
    try {
        const svgObj =
            // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-dynamic-require, global-require
            require(`../${svg}.svg?url`).default as StaticImageData;
        src = svgObj.src;
    } catch {
        src = '';
    }

    if (!src) return null;

    return (
        <Icon {...restProps}>
            <img src={src} alt={alt} loading="lazy" />
        </Icon>
    );
};
