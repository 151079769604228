import { type InitOptions } from 'i18next';
import { type NextConfig } from 'next';
// import { isDevEnvironment } from 'bb/utils/environment';

export type CreateI18NextConfig = InitOptions & NextConfig['i18n'];

/**
 * Creates an i18next configuration object based on the passed configuration.
 */
export const createI18NextConfig = (passedConfig: CreateI18NextConfig) => {
    const {
        defaultLocale,
        defaultNS = 'common',
        partialBundledLanguages = true,
        localeDetection = false,
        fallbackLng = false,
        react = {},
        load = 'currentOnly',
        ...restPassedConfig
    } = passedConfig;

    const {
        useSuspense = false,
        transWrapTextNodes = 'span',
        ...restReactConfig
    } = react;

    const config: typeof passedConfig = {
        /**
         * Remove the comment below to get the debug logs.
         */
        // debug: true,
        load,
        lng: defaultLocale,
        defaultLocale,
        preload: [defaultLocale],
        defaultNS,
        partialBundledLanguages,
        localeDetection,
        fallbackLng,
        react: {
            useSuspense,
            transWrapTextNodes,
            ...restReactConfig
        },
        ...restPassedConfig
    };

    return config;
};
