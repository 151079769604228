/* eslint-disable @next/next/no-img-element */
import React, { type FunctionComponent } from 'react';
import { Header, Text, useTranslation } from 'bb/i18n';
import { Stack } from 'bb/ui';

type ErrorProps = {
    header?: string;
    message?: string;
};

export const CommonError: FunctionComponent<ErrorProps> = ({
    header,
    message
}) => {
    const { t } = useTranslation(['errorBoundary']);

    return (
        <Stack className="error-fallback">
            <Header marginTop="large" size="large" center>
                <span>{header || t('errorBoundary:header')}</span>
            </Header>
            <Text marginBottom="large">
                <span>{message || t('errorBoundary:suggestion')}</span>
            </Text>
            <img src="/images/svg/error-boy.svg" alt="" />
        </Stack>
    );
};
