/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import { Input } from 'bb/ui/Form/Input';
import { ChevronDown, SearchIcon } from 'bb/ui/Icons';
import { Typography } from 'bb/ui/Typography';
import { makeSpacing } from 'bb/utils';
import { InputController } from '../InputController';
import css from './select.module.scss';
import { type SelectItemType, type SelectProps } from './Select.types';
import { SelectDropdownModal } from './SelectDropdown';
import { SelectItem } from './SelectItem';
import { useSelect } from './useSelect';

const SelectComponent = <TSelectItem extends SelectItemType>(
    props: SelectProps<TSelectItem>,
    ref: React.ForwardedRef<HTMLSpanElement>
) => {
    const {
        block,
        borderColor,
        className,
        defaultSelectedItem,
        disableBorder,
        disableDefaultGetButtonProps = false,
        disablePadding,
        disabled = false,
        endAdornment = <ChevronDown size="small" />,
        error,
        fluid,
        helperText,
        isSearchable = false,
        items: passedItems,
        inputProps: passedInputProps = {},
        label,
        labelProps: passedLabelProps = {},
        name,
        onChange: passedOnChange,
        onIsOpenChange: passedOnIsOpenChange,
        placeholder,
        renderItem,
        renderSelected,
        searchableInputProps = {},
        searchableProps = {},
        startAdornment,
        ...restProps
    } = props;

    const { keys = ['label'], ...restSearchableProps } = searchableProps;

    const inputRef = useRef<HTMLSpanElement | null>(null);
    const [items, setItems] = useState(passedItems);

    const {
        getButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getSearchInputProps,
        isOpen,
        selectedItem
    } = useSelect<TSelectItem>({
        defaultSelectedItem: defaultSelectedItem
            ? items.find((item) => item.value === defaultSelectedItem)
            : undefined,
        onChange: ({ value }) => passedOnChange?.(value),
        onIsOpenChange: (...args) => {
            setItems(passedItems);
            passedOnIsOpenChange?.(...args);
        },
        disabled,
        items,
        isMatchFn: (item, item2) => item.value === item2.value,
        searchableProps: { keys, ...restSearchableProps },
        ...restProps
    });

    useEffect(() => {
        setItems(passedItems);
    }, [passedItems]);

    const buttonProps = getButtonProps({ ref: inputRef });

    return (
        <InputController
            borderColor={borderColor}
            className={classNames(css.root, className)}
            inputProps={{
                ...passedInputProps,
                ...(disableDefaultGetButtonProps ? {} : buttonProps),
                className: classNames(
                    css.input,
                    disabled && css.disabled,
                    passedInputProps.className
                )
            }}
            disableBorder={disableBorder}
            disablePadding={disablePadding}
            endAdornment={endAdornment}
            error={error}
            fluid={fluid}
            label={label}
            labelProps={{ ...passedLabelProps, ...getLabelProps() }}
            name={name}
            placeholder={placeholder}
            startAdornment={startAdornment}
            helperText={helperText}
            ref={ref}
            block={block}
            disabled={disabled}
        >
            {renderSelected?.({
                placeholder,
                selectedItem,
                ...buttonProps
            }) ?? <Typography>{selectedItem?.label ?? placeholder}</Typography>}

            {isOpen && (
                <SelectDropdownModal
                    {...getMenuProps()}
                    anchorElementRef={inputRef}
                    widget={
                        isSearchable ? (
                            <Input
                                {...getSearchInputProps<HTMLInputElement>()}
                                {...searchableInputProps}
                                style={
                                    /**
                                     * Override CSS variable globals.
                                     */
                                    {
                                        ...searchableInputProps.style,
                                        '--input-spacing': `${makeSpacing(
                                            3
                                        )}px`,
                                        '--input-height': 'auto'
                                    } as React.CSSProperties
                                }
                                className={classNames(
                                    css.searchable,
                                    searchableInputProps.className
                                )}
                                onChange={(event) => {
                                    const { value } = event.target;

                                    if (isSearchable) {
                                        const newItems = value.length
                                            ? new Fuse(passedItems, {
                                                  keys,
                                                  ...searchableProps
                                              })
                                                  .search(value)
                                                  .map(({ item }) => item)
                                            : passedItems;

                                        setItems(newItems);
                                    }
                                }}
                                startAdornment={<SearchIcon size="small" />}
                                fluid
                                disableFocusIndicator
                                disableBorder
                            />
                        ) : null
                    }
                >
                    {items.map((item, index) => {
                        const isSelected = item.value === selectedItem?.value;

                        const selectItemProps = {
                            item,
                            key: `${item.value}|${index}`,
                            ...getItemProps({
                                item,
                                index,
                                isSelected
                            })
                        };

                        return (
                            renderItem?.(selectItemProps) ?? (
                                <SelectItem {...selectItemProps} />
                            )
                        );
                    })}
                </SelectDropdownModal>
            )}
        </InputController>
    );
};

export const Select = React.forwardRef(SelectComponent) as <
    TSelectItem extends SelectItemType
>(
    props: SelectProps<TSelectItem> & {
        ref?: React.ComponentPropsWithRef<'span'>['ref'];
    }
) => React.ReactNode;
