import React from 'react';
import classnames from 'classnames';
import { useIsScreenSize } from 'bb/common/hooks/useIsScreenSize';
import { BaseModal, type BaseModalProps } from '../Modal/BaseModal';
import { createModalPortal } from '../Modal/utils';
import css from './drawer.module.scss';

const DIRECTION_MAP = {
    left: {
        transformFrom: 'translate3d(-100%, 0, 0)',
        transformTo: 'translate3d(0, 0, 0)',
        left: 0,
        right: 'auto',
        top: 0,
        bottom: 'auto',
        width: '390px',
        height: '100%'
    },
    right: {
        transformFrom: 'translate3d(100%, 0, 0)',
        transformTo: 'translate3d(0, 0, 0)',
        top: 0,
        bottom: 'auto',
        left: 'auto',
        right: 0,
        width: '390px',
        height: '100%'
    },
    bottom: {
        transformFrom: 'translate3d(100%, 0, 0)',
        transformTo: 'translate3d(0, 0, 0)',
        top: 'auto',
        bottom: 0,
        left: 'auto',
        right: 'auto',
        width: '100%',
        height: '75vh'
    }
};

export type DirectionMap = typeof DIRECTION_MAP;
export type DirectionProperties = DirectionMap[keyof DirectionMap];

const ALL_PROPERTIES = Object.keys(
    DIRECTION_MAP.right
) as (keyof DirectionProperties)[];

export type DrawerProps = {
    /**
     * The direction from which the drawer will appear
     *
     * @default 'right'
     */
    direction?: 'left' | 'right' | 'bottom';
    /**
     * Animation time in milliseconds
     *
     * @default 300
     */
    animationTime?: number;
    /**
     * Determines if the drawer should render on the bottom
     * in responsive mode.
     *
     * @default false
     */
    shouldAppearOnBottomInResponsive?: boolean;
    /**
     * Determines if the drawer should cover the full screen
     * in responsive mode.
     *
     * @default false
     */
    shouldCoverFullScreenInResponsive?: boolean;
    /**
     * className passed to the content container.
     */
    contentClassName?: string;
    /**
     * Overrides for each direction.
     *
     * @default {}
     */
    directionOverrides?: Partial<
        Record<keyof DirectionMap, Partial<DirectionMap[keyof DirectionMap]>>
    >;
} & BaseModalProps;

export const Drawer = ({
    isOpen = false,
    children,
    direction: passedDirection = 'right',
    animationTime = 250,
    shouldAppearOnBottomInResponsive = false,
    shouldCoverFullScreenInResponsive = false,
    contentClassName,
    directionOverrides = {},
    ...restProps
}: DrawerProps) => {
    const isSmUp = useIsScreenSize('sm');
    const contentRef = React.useRef<HTMLDivElement>(null);

    const direction =
        !isSmUp && shouldAppearOnBottomInResponsive
            ? 'bottom'
            : passedDirection;

    /**
     * Get the properties for the direction of the drawer.
     *
     * If a property is passed in the `directionOverrides` prop,
     * it will override the default properties for that direction.
     */
    const getDirectionProperties = () =>
        ALL_PROPERTIES.reduce((acc, prop) => {
            const passedValue = directionOverrides?.[direction]?.[prop];
            if (passedValue) {
                acc[prop] = passedValue.toString();
            }

            return acc;
        }, DIRECTION_MAP[direction]);

    const {
        transformFrom,
        transformTo,
        left,
        right,
        top,
        bottom,
        ...restDirectionProperties
    } = getDirectionProperties();

    let { width, height } = restDirectionProperties;

    if (!isSmUp && shouldCoverFullScreenInResponsive) {
        width = '100%';
        height = '100%';
    }

    return createModalPortal(
        <BaseModal
            {...restProps}
            isOpen={isOpen}
            closeTimeoutMS={animationTime * 2}
            boxProps={{
                ...restProps.boxProps,
                'data-testid': 'bb-drawer'
            }}
            getScrollableElement={() => contentRef.current}
        >
            {(ctx) => {
                const { isActive, isClosing } = ctx;

                return (
                    <div
                        style={
                            {
                                '--animation-time': `${animationTime}ms`,
                                '--animation-from': transformFrom,
                                '--animation-to': transformTo,
                                '--animation-left': left,
                                '--animation-right': right,
                                '--animation-top': top,
                                '--animation-bottom': bottom,
                                '--animation-width': width,
                                '--animation-height': height
                            } as React.CSSProperties
                        }
                        className={css.animationColorContainer}
                    >
                        <div
                            className={classnames(
                                css.animationColor,
                                isActive && css.showAnimationColor,
                                !isClosing &&
                                    !isActive &&
                                    css.hideAnimationColor
                            )}
                        >
                            <div
                                ref={contentRef}
                                className={classnames(
                                    css.content,
                                    isActive && css.showContent,
                                    contentClassName
                                )}
                            >
                                {typeof children === 'function'
                                    ? children(ctx)
                                    : children}
                            </div>
                        </div>
                    </div>
                );
            }}
        </BaseModal>
    );
};
