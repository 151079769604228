// @ts-strict-ignore
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-this-alias */
/* https://davidwalsh.name/javascript-debounce-function */
export function debounce(func, wait: number, immediate?: boolean): () => void {
    let timeout: number;

    function withTimeout(): void {
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const later = (): void => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        window.clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    }

    return withTimeout;
}
