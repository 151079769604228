import { type Module, type i18n as I18n } from 'i18next';

export const addPluginsToI18Next = (
    instance: I18n,
    plugins: (Module | null)[]
) => {
    plugins.forEach((plugin) => {
        if (plugin) {
            instance.use(plugin);
        }
    });

    return instance;
};
