import React, { type FunctionComponent, useState } from 'react';
import useSWR from 'swr';
import { api } from 'bb/api/browser/api';
import { type ErrorResponse } from 'bb/api/browser/types';
import { useRouter } from 'bb/app/router';
import { DeprecatedLinkButton } from 'bb/common/DeprecatedLinkButton';
import { ToggleRow } from 'bb/common/ToggleRow';
import { DeprecatedButton, Row } from 'bb/ui';
import { CountItem } from './CountItem';
import { type Link, type MenuItemProps } from './types';

const linksContainsCurrentPath = (currentPath?: string, links: Link[] = []) =>
    links.some((link) => link?.route?.href === currentPath);

export const MenuItem: FunctionComponent<MenuItemProps> = ({
    currentPath,
    link,
    inverted,
    sub,
    menuItemsComponent: MenuItems
}) => {
    const [isOpen, setIsOpen] = useState(
        linksContainsCurrentPath(currentPath, link.links)
    );
    const { routes } = useRouter();

    const { data: unreadInboxItems } = useSWR<number, ErrorResponse>(
        link.route === routes.inbox ? '/api/my/inbox' : null,
        {
            fetcher: (url) => api.get(url).then((res) => res?.data?.count),
            revalidateOnMount: true
        }
    );

    const size = sub ? 'medium' : 'large';
    const selected =
        link.matchSub && link.route?.href
            ? currentPath?.includes(link.route?.href)
            : currentPath === link.route?.href;

    const linkElement = link.route ? (
        <Row>
            <DeprecatedLinkButton
                inverted={inverted}
                route={link.route}
                query={link.query}
                variant="text"
                size={size}
                selected={selected}
            >
                {link.label}
            </DeprecatedLinkButton>
            {Boolean(unreadInboxItems) && (
                <CountItem count={unreadInboxItems} />
            )}
        </Row>
    ) : (
        <DeprecatedButton
            inverted={inverted}
            inline
            variant="text"
            selected={selected}
            size={size}
            onClick={() => {
                setIsOpen((prev) => !prev);
            }}
        >
            {link.label}
        </DeprecatedButton>
    );

    return link.links ? (
        <ToggleRow isOpen={isOpen} useIcon button={linkElement}>
            <MenuItems currentPath={currentPath} sub links={link.links} />
        </ToggleRow>
    ) : (
        linkElement
    );
};
