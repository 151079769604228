import React, { type FunctionComponent } from 'react';
import classnames from 'classnames';
import { Box, type BoxProps } from '../Box';
import css from './page.module.scss';

export type PageProps = {
    withPadding?: boolean;
    children?: React.ReactNode;
    flex?: boolean;
} & BoxProps<'main'>;

export const Page: FunctionComponent<PageProps> = ({
    withPadding,
    className,
    children,
    flex,
    fluid = false,
    ...restProps
}) => (
    <Box
        {...restProps}
        as="main"
        className={classnames(
            className,
            withPadding && css.padding,
            flex && css.flex
        )}
        fluid={fluid}
    >
        {children}
    </Box>
);
