/* eslint-disable @next/next/no-img-element */
import React from 'react';
import classnames from 'classnames';
import { ALL_MARKETS } from 'bb/config/marketConfigUtils';
import { Icon, type BaseIconProps } from './Icon';
import css from './icons.module.scss';

export type FlagIconProps = Omit<BaseIconProps, 'color'> & {
    /**
     * Two-letter ISO code, for example "de" or "se"
     */
    market?: string;
};

/**
 * Display an icon either based on two-letter ISO code or two-letter language code
 */
export const FlagIcon: React.FunctionComponent<FlagIconProps> = ({
    market,
    size,
    inline,
    className
}) => {
    const key = market?.toLowerCase() as
        | (typeof ALL_MARKETS)[number]
        | undefined;
    const flag = key && ALL_MARKETS.includes(key) && key;

    return (
        flag && (
            <Icon
                inline={inline}
                size={size}
                className={classnames(css.flag, className)}
            >
                <img
                    alt={`${key} market flag`}
                    src={`/images/flags/${flag}.png`}
                />
            </Icon>
        )
    );
};
