import { useCallback } from 'react';
import queryString from 'query-string';
import useSWR from 'swr';
import { useRouter, useShouldRedirect } from 'bb/app/router';
import { findRouteByPath } from 'bb/app/router/utils';
import { type ReactivationOffer } from 'bb/campaigns/types';
import { useAccount } from './useAccount';

export const useRedirectReactivationOffer = (
    redirectTo?: string,
    isLoggedIn?: boolean
) => {
    const { routes } = useRouter();
    const { data, isLoading } = useSWR<ReactivationOffer>(
        isLoggedIn && !redirectTo
            ? '/api/accounts/offers/reactivation/active'
            : null
    );
    const { account } = useAccount({ enabled: Boolean(isLoggedIn) });
    const { url: path, query = {} } = (
        redirectTo ? queryString.parseUrl(redirectTo) : {}
    ) as queryString.ParsedUrl;

    const route = path && findRouteByPath(decodeURIComponent(path));

    const shouldRedirectPredicate = useCallback(
        () => Boolean(isLoggedIn && !isLoading && (account || path)),
        [isLoggedIn, isLoading, account, path]
    );

    useShouldRedirect(
        shouldRedirectPredicate,
        route ||
            (data?.offer?.isValid
                ? routes.subscription
                : routes[account?.isActivated ? 'welcome' : 'activateaccount']),
        query
    );
};
