import React, {
    createContext,
    type PropsWithChildren,
    useContext
} from 'react';
import { type Route } from 'bb/app/router/config';

export type RemappedRoutesContextType = Partial<Record<Route, Route>>;

export type RemappedRoutesProviderProps = {
    value: RemappedRoutesContextType;
} & PropsWithChildren;

export const RemappedRoutesContext = createContext<RemappedRoutesContextType>(
    {}
);

export const useRemappedRoutes = () => useContext(RemappedRoutesContext);

/**
 * This provider allows us to re-map a route to another one. This is useful
 * when we need to change legacy routes to new ones without breaking old
 * functionality, for instance through feature flagging routing. It can be
 * used either application-wide or in a specific part of the application.
 *
 * See `AppRemappedRoutesProvider` for an example of how to use this provider
 * application-wide.
 */
export const RemappedRoutesProvider = ({
    children,
    value
}: RemappedRoutesProviderProps) => {
    return (
        <RemappedRoutesContext.Provider value={value}>
            {children}
        </RemappedRoutesContext.Provider>
    );
};
