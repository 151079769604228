type TimeInHoursAndMinutes = {
    minutes: number;
    hours: number;
};
export const getTimeAndMinutes = (time: number): TimeInHoursAndMinutes => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return {
        hours,
        minutes
    };
};

export const getTimeLeft = (
    totalTime: number,
    consumedTime: number
): TimeInHoursAndMinutes => {
    const timeLeft = totalTime - consumedTime;
    return getTimeAndMinutes(timeLeft);
};
