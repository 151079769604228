import { useEffect, useMemo } from 'react';
import { atom, createStore, useAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { type UseHeadlessModalProps } from './useHeadlessModal';

export const MODAL_ENTITIES = [
    'marketPicker',
    'filterDrawer',
    'sortingDrawer'
] as const;

export type ModalEntityName = (typeof MODAL_ENTITIES)[number];

export type ModalAtom = Record<ModalEntityName, boolean>;

export type UseModalStoreReturnType<TEntityName extends ModalEntityName> =
    ReturnType<typeof useModalStore<TEntityName>>;

/**
 * The initial state of the modal atom. This is a record where the keys
 * are the entity names and the values are booleans indicating if the
 * modal is open or not. Always default to `false`.
 */
const INITIAL_ATOM_STATE = MODAL_ENTITIES.reduce<ModalAtom>(
    (acc, entityName) => {
        acc[entityName] = false;

        return acc;
    },
    {} as ModalAtom
);

export const modalAtom = atom(INITIAL_ATOM_STATE);

export const modalStore = createStore();

export const useModalAtom = () => useAtom(modalAtom);

/**
 * A hook to interact with an entity in the modal store. For the a11y
 * to work properly the entity name should be unique and has to be
 * passed to the `Modal` component in addition to calling this hook
 * with the same name. Preferably we also want to pass the property
 * `persistChildrenWhenClosed={true}` to the `Modal` as well so the screen
 * readers can still read the content when the modal is closed.
 *
 * @example
 * ```tsx
 * const [[_isOpen, _setIsOpen], getButtonProps, getModalProps] = useModalStore('foo');
 *
 * return (
 *    <>
 *      <Button {...getButtonProps()}>Open modal</Button>
 *      <Modal {...getModalProps()} />
 *   </>
 * )
 * ```
 */
export const useModalStore = <TEntityName extends ModalEntityName>(
    entityName: TEntityName
) => {
    /**
     * Since we are using a store with many different entities we need
     * to focus the one we are actually interested in. This means that
     * the atom state will only be updated when the focused entity changes.
     * In practice this means that it's very similar to `useState(false)` but
     * with the added benefit of a centralized store and improved a11y due
     * to `getButtonProps` and `getModalProps`.
     *
     * We are using a ref here to avoid creating a new atom every time the
     * component re-renders. Not doing this would cause an infinite loop.
     */
    const focused = useMemo(
        () => focusAtom(modalAtom, (optic) => optic.prop(entityName)),
        [entityName]
    );
    const atomState = useAtom(focused);
    const [isOpen, setIsOpen] = atomState;

    /**
     * Helper function to get the props needed to interact with the modal plus
     * some additional a11y props. This is supposed to be spread on the button
     * element.
     */
    const getButtonProps = <
        TElement extends HTMLElement = HTMLButtonElement
    >() => {
        return {
            'aria-haspopup': 'dialog',
            'aria-describedby': entityName as TEntityName,
            onClick: () => setIsOpen((prev) => !prev)
        } as const satisfies Partial<React.HTMLAttributes<TElement>>;
    };

    /**
     * Helper function to get a11y props for the modal.
     */
    const getModalProps = () => {
        return {
            entityName: entityName as TEntityName,
            isOpen
        } as const satisfies Pick<
            UseHeadlessModalProps,
            'isOpen' | 'entityName'
        >;
    };

    useEffect(() => {
        return () => {
            setIsOpen(false);
        };
    }, [setIsOpen]);

    return [atomState, getButtonProps, getModalProps] as const;
};
