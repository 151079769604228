import React from 'react';
import classNames from 'classnames';
import { Typography } from 'bb/ui/Typography';
import css from './select.module.scss';
import { type SelectItemType, type SelectItemProps } from './Select.types';

const SelectItemComponent = <TSelectItem extends SelectItemType>(
    { isHighlighted, item, ...restProps }: SelectItemProps<TSelectItem>,
    ref: React.ForwardedRef<HTMLLIElement>
) => (
    <li
        className={classNames(css.item, isHighlighted && css.itemIsHighlighted)}
        ref={ref}
        {...restProps}
    >
        <Typography>{item.label}</Typography>
    </li>
);

export const SelectItem = React.forwardRef(SelectItemComponent) as <
    TSelectItem extends SelectItemType
>(
    props: SelectItemProps<TSelectItem>
) => React.ReactNode;
