/* eslint-disable react/jsx-props-no-spreading */
// @ts-strict-ignore
import React, { type FunctionComponent } from 'react';
import classnames from 'classnames';
import alignItemsCss from '../alignItems.module.scss';
import justifyContentCss from '../justifyContent.module.scss';
import { type JustifyContentType, type AlignItemsType } from '../types';
import css from './stack.module.scss';

export type StackProps = {
    justifyContent?: JustifyContentType;
    alignItems?: AlignItemsType;
    className?: string;
    children?: React.ReactNode;
} & React.ComponentProps<'div'>;

export const Stack: FunctionComponent<StackProps> = ({
    justifyContent = 'flexStart',
    alignItems = 'center',
    className = '',
    children,
    ...restProps
}) => (
    <div
        className={classnames(css.flex, className, {
            [alignItemsCss[alignItems as string]]: Boolean(alignItems),
            [justifyContentCss[justifyContent as string]]:
                Boolean(justifyContent)
        })}
        {...restProps}
    >
        {children}
    </div>
);
