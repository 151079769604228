import React, {
    type FunctionComponent,
    type ReactElement,
    useCallback,
    useEffect,
    useId,
    useMemo,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';
import { useWindowWidth } from 'bb/app/useWindowWidth';
import { Row, Stack, Display, ArrowRightIcon } from 'bb/ui';
import { useResizeObserver } from 'bb/ui/hooks';
import { type BaseIconProps } from 'bb/ui/Icons/Icon';
import { usePreviousValue } from './hooks';
import css from './ToggleRow.module.scss';

type ToggleRowProps = {
    /**
     * Do not pass in a button here, use the button prop instead
     */
    header?: ReactElement;
    /**
     * Can be used to replace the default button that opens the ToggleRow
     */
    button?: ReactElement;
    disableToggle?: boolean;
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    useIcon?: boolean;
    flexDirection?: 'column' | 'row';
    alignItems?: 'flexStart' | 'center' | 'flexEnd';
    justifyContent?: 'flexStart' | 'spaceBetween';
    animate?: boolean;
    children?: React.ReactNode;
    renderChildrenIfClosed?: boolean;
    customIcon?: React.FC<BaseIconProps>;
};

const alignmentMapper = {
    flexStart: 'flex-start',
    flexEnd: 'flex-end',
    center: 'center',
    spaceBetween: 'space-between'
};

export const ToggleRow: FunctionComponent<ToggleRowProps> = ({
    header = null,
    button = null,
    disableToggle,
    useIcon,
    flexDirection = 'row',
    alignItems = 'center',
    justifyContent = 'flexStart',
    isOpen,
    children,
    animate = true,
    setIsOpen,
    renderChildrenIfClosed = true,
    customIcon = null
}) => {
    const drawerId = useId();
    const [maxHeight, setMaxHeight] = useState<number | null>(null);
    const [drawerIsClosed, setDrawerIsClosed] = useState(!isOpen);
    const childContainerRef = useRef<HTMLDivElement>(null);
    const previousIsOpenValue = usePreviousValue(isOpen);
    const windowWidth = useWindowWidth();
    const onClick = setIsOpen ? () => setIsOpen(!isOpen) : undefined;
    const IconElement = customIcon || ArrowRightIcon;
    const icon = useIcon && (
        <IconElement
            className={classNames(css.arrow, isOpen && css.open)}
            inline
            size="small"
        />
    );

    const handleMaxHeight = useCallback((): void => {
        if (!childContainerRef.current) return;

        const { height } = childContainerRef.current.getBoundingClientRect();
        if (Math.round(height) !== maxHeight) {
            setMaxHeight(Math.round(height));
        }
    }, [maxHeight]);

    useResizeObserver(childContainerRef, handleMaxHeight);

    useEffect(() => {
        if (isOpen) {
            setDrawerIsClosed(false);
        } else if (!isOpen && !animate) {
            setDrawerIsClosed(true);
        }
    }, [animate, isOpen]);

    useEffect(() => {
        animate && handleMaxHeight();
    }, [children, windowWidth, handleMaxHeight, animate]);

    const getMaxHeight = () => {
        if (animate) {
            return isOpen ? `${maxHeight}px` : '0px';
        }
        return isOpen ? 'none' : '0px';
    };

    const renderChildren = isOpen || renderChildrenIfClosed;

    const headerProps =
        button || disableToggle
            ? {}
            : {
                  'aria-expanded': isOpen,
                  'aria-controls': drawerId,
                  tabIndex: 0,
                  role: 'button',
                  onClick,
                  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault(); // Prevent page scroll if key is space
                          onClick && onClick();
                      }
                  }
              };

    const enhancedButton = useMemo(
        () =>
            button && !disableToggle
                ? React.cloneElement(button, {
                      'aria-controls': drawerId,
                      'aria-expanded': isOpen
                  })
                : null,
        [button, disableToggle, drawerId, isOpen]
    );

    return (
        <Stack>
            <Row
                justifyContent="spaceBetween"
                flexDirection={flexDirection}
                alignItems={alignItems}
                style={{ width: '100%' }}
            >
                <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...headerProps}
                    className={classNames(css.header, !button && css.clickable)}
                    style={{
                        justifyContent: alignmentMapper[justifyContent],
                        alignItems: alignmentMapper[alignItems],
                        width: '100%'
                    }}
                >
                    <Display on={Boolean(header)}>{header}</Display>
                    <Display on={Boolean(enhancedButton)}>
                        {enhancedButton}
                    </Display>
                    <Display on={Boolean(icon)}>{icon}</Display>
                </div>
            </Row>
            <div
                id={drawerId}
                aria-hidden={!isOpen}
                role="region"
                className={classNames(css.content, !animate && css.noAnimate)}
                style={{
                    maxHeight: getMaxHeight()
                }}
                onTransitionEnd={() => {
                    if (!isOpen && previousIsOpenValue) setDrawerIsClosed(true);
                }}
            >
                <div
                    ref={childContainerRef}
                    className={css.children}
                    // Set inline style in order for jest tests to work, styles from external css files are not loaded during tests.
                    // Required for testing focus logic on open/closed rows.
                    style={{
                        visibility: drawerIsClosed ? 'hidden' : 'visible'
                    }}
                >
                    {renderChildren && children}
                </div>
            </div>
        </Stack>
    );
};
