import React from 'react';
import classNames from 'classnames';
import { type Color } from 'bb/style/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import css from './divider.module.scss';

export type DividerProps = {
    /**
     * The color of the divider.
     *
     * Uses the color palette from the design system.
     *
     * @default 'gray-01'
     */
    color?: Color;
    /**
     * The height of the border in pixels.
     *
     * @default 1
     */
    size?: number;
} & React.ComponentProps<'div'>;

export const Divider = ({
    color = 'gray-01',
    className,
    style,
    size = 1,
    ...restProps
}: DividerProps) => (
    <div
        {...restProps}
        style={
            {
                ...style,
                ...makeCSSVariableFromColor('--divider-component-color', color),
                '--divider-component-size': `${size}px`
            } as React.CSSProperties
        }
        className={classNames(css.root, className)}
    />
);
