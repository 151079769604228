import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const PencilIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path
                    d="M8.672-.646A2.413 2.413 0 0 0 6.8.383L-.15 9.89l-.62 5.41 4.906-1.977 7.207-9.945-1.053-.764-6.988 9.645L.77 13.28l.333-2.9 6.761-9.25.004-.007c.222-.32.506-.453.865-.47.36-.018.79.113 1.166.408l2.647 2.062-3.04 4.174 1.052.766 3.779-5.192L10.7.037 10.7.035c-.6-.468-1.317-.715-2.027-.681Z"
                    transform="rotate(-10 21.876 -13.336)"
                />
                <path
                    d="m1.023 9.064.028 1.2c.67-.016 1.182.136 1.611.441.385.274.698.777.883 1.621l1.172-.258c-.225-1.026-.66-1.844-1.36-2.341-.655-.467-1.458-.683-2.334-.663Z"
                    transform="rotate(-10 21.876 -13.336)"
                />
            </g>
        </svg>
    </Icon>
);
