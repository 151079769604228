import { useContext } from 'react';
import { FeatureContext } from '../context';
import type { Feature } from '../types';

/**
 * Like useFeature but provides a handler that can be used to
 * check for features inline.
 */
export const useHasFeature = () => {
    const features = useContext(FeatureContext);

    return (feature: Feature) => features[feature] ?? false;
};
