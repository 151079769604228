import { useEffect, useState } from 'react';

export const useCountDown = (from = 0, delay = 1000) => {
    const [count, setCount] = useState(from);

    useEffect(() => {
        from && setCount(from);
    }, [from]);

    useEffect(() => {
        const timer =
            from && count
                ? window.setTimeout(() => {
                      setCount((c) => c - 1);
                  }, delay)
                : 0;

        return () => {
            window.clearTimeout(timer);
        };
    }, [from, count, delay]);

    return count;
};
