import React from 'react';
import classnames from 'classnames';
import { Box, type BoxProps } from '../Box';
import { type AlignItems, type PolymorphicRef } from '../types';
import css from './card.module.scss';

export type CardProps<TElementType extends React.ElementType = 'div'> = {
    withBorder?: boolean;
    alignItems?: AlignItems;
    dataKey?: string;
    noSmPadding?: boolean;
    grow?: boolean;
} & Omit<BoxProps<TElementType>, 'border'>;

const CardComponent = (
    {
        withBorder,
        alignItems,
        dataKey,
        noSmPadding,
        className,
        children,
        grow = false,
        bgColor = 'primary-white',
        ...rest
    }: CardProps<'div'>,
    ref?: PolymorphicRef<'div'>
) => (
    <Box
        ref={ref}
        className={classnames(className, {
            [css[alignItems as string] as string]: Boolean(alignItems),
            [css.zeroPadding as string]: Boolean(noSmPadding),
            [css.grow as string]: grow
        })}
        border={withBorder ? '1px solid gray-02' : undefined}
        data-key={dataKey}
        bgColor={bgColor}
        {...rest}
    >
        {children}
    </Box>
);

/**
 * @deprecated Use Box instead.
 */
export const Card = React.forwardRef(CardComponent) as <
    TElementType extends React.ElementType = 'div'
>(
    props: CardProps<TElementType>
) => JSX.Element;
