import { useEffect, useCallback } from 'react';
import { EXECUTING, IDLE, SUCCESS } from './symbols';
import { type State, type RetryStrategy } from './types';
import { usePut } from './usePut';
import { useRetryStrategy } from './useRetryStrategy';

export function useRetryPut<Response, Payload>(
    url: string,
    strategy: RetryStrategy<Response>,
    payload: Payload
): State<Response> {
    const { result, error, status, execute } = usePut<Response, Payload>(url);
    const [state, dispatch] = useRetryStrategy(strategy);
    const { status: retryStatus } = state;
    const retry = useCallback(() => {
        setTimeout(() => {
            execute(payload);
        }, strategy.delay);
    }, [execute, strategy.delay, payload]);

    useEffect(() => {
        if (payload) {
            dispatch({ type: EXECUTING });
            execute(payload);
        }
    }, [payload, execute, dispatch]);

    useEffect(() => {
        if (result) {
            dispatch({ type: SUCCESS, result });
        }
    }, [result, execute, dispatch]);

    useEffect(() => {
        if (retryStatus === IDLE) {
            retry();
        }
    }, [retryStatus, retry]);

    return { result, error, status };
}
