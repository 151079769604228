import React from 'react';
import classNames from 'classnames';
import { type PolymorphicRef, type PolymorphicComponent } from '../types';
import { Card, type CardProps } from './Card';
import css from './pageContentCard.module.scss';

const PageContentCardComponent = (
    props: CardProps<'div'>,
    ref?: PolymorphicRef<'div'>
) => {
    const { children, className, ...rest } = props;
    return (
        <Card
            {...rest}
            className={classNames(css.padding, className)}
            ref={ref}
        >
            {children}
        </Card>
    );
};

export const PageContentCard = React.forwardRef(PageContentCardComponent) as <
    T extends React.ElementType
>(
    props: PolymorphicComponent<T, CardProps<T>>
) => JSX.Element;
