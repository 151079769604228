/**
 * Traverse through the DOM to find the next focusable element
 * @param element
 * @returns
 */
export const getNextFocusableElement = <T extends Element>(
    element: T
): HTMLElement | null => {
    const siblingElement = element.nextElementSibling;

    if (siblingElement) {
        const focusableElement = siblingElement.querySelector<HTMLElement>(
            'a, button:not([disabled]), select:not([disabled]), input:not([disabled]), textarea:not([disabled]), [tabindex="0"]'
        );

        if (focusableElement) return focusableElement;
        if (siblingElement.nextElementSibling)
            return getNextFocusableElement(siblingElement);
    }
    if (element?.parentElement) {
        return getNextFocusableElement(element.parentElement);
    }
    return null;
};
