import { useApi } from 'bb/api/browser/useApi';
import { useOnChanged } from 'bb/common/hooks/useOnChanged';
import { useApp } from './AppContext';

export const useMarket = () => {
    const { market } = useApp();
    const { data, mutate } = useApi('/api/discovery', { fixed: true });

    useOnChanged(market, () => mutate());

    return {
        market,
        marketSettings: data?.marketsettings,
        links: data?._links
    };
};
