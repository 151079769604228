import React from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { getMargins, type MarginProps } from 'bb/ui/Margin';
import {
    type PolymorphicComponent,
    type FontSize,
    type PolymorphicRef
} from 'bb/ui/types';
import { Typography, type TypographyProps } from 'bb/ui/Typography';
import { isProductionEnvironment } from 'bb/utils/environment';
import { type TranslationProps } from '../types';
import css from './text.module.scss';

export const TYPOGRAPHY_MAP: Record<FontSize, TypographyProps['variant']> = {
    small: 'body3',
    medium: 'body1',
    large: 'h6Secondary'
};

export type TextProps<TElementType extends React.ElementType = 'p'> =
    PolymorphicComponent<
        TElementType,
        TranslationProps & {
            /**
             * @deprecated
             * Use the variant prop instead
             */
            size?: FontSize;
            wrap?: boolean;
            testId?: string;
            suppressHydrationWarning?: boolean;
            /**
             * We type `t` and ` values`ourselves.
             *
             * `i18nKey` is not used since we use the `t` prop.
             */
            transProps?: Omit<
                React.ComponentProps<typeof Trans>,
                't' | 'values' | 'i18nKey'
            >;
        } & TypographyProps<TElementType> &
            MarginProps
    >;

const TextComponent = (props: TextProps<'p'>, ref?: PolymorphicRef<'p'>) => {
    const {
        /**
         * Translation props
         */

        t,
        values,
        transProps,
        /**
         * Other props
         */
        as = 'p',
        bold = false,
        center = false,
        children,
        className: passedClassName,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        size,
        suppressHydrationWarning,
        testId,
        upperCase = false,
        variant: passedVariant,
        wrap,
        ...restProps
    } = props;

    const variant = passedVariant ?? (size ? TYPOGRAPHY_MAP[size] : undefined);

    return (
        <Typography
            {...restProps}
            {...(!isProductionEnvironment()
                ? { 'data-translation-key': t }
                : {})}
            variant={variant}
            center={center}
            bold={bold}
            upperCase={upperCase}
            as={as}
            ref={ref}
            data-testid={testId}
            className={classNames(
                passedClassName,
                getMargins({
                    margin,
                    marginLeft,
                    marginRight,
                    marginBottom,
                    marginTop
                }),
                wrap && css.wrap
            )}
            suppressHydrationWarning={suppressHydrationWarning}
        >
            {t ? (
                <Trans {...transProps} i18nKey={t} values={values}>
                    {children}
                </Trans>
            ) : (
                children
            )}
        </Typography>
    );
};

/**
 * Wrapper around Typography with legacy support for size/margin
 * props and with support for translations. Use if a translation
 * is needed, otherwise use Typography.
 */
export const Text = React.forwardRef(TextComponent) as <
    T extends React.ElementType = 'p'
>(
    props: TextProps<T>
) => JSX.Element;

export default Text;
