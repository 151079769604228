import { type MutableRefObject } from 'react';
import { type MaybeRef } from 'bb/ui/types';
import { isRef } from './assert';

/**
 * It the target that is passed is a ref we extract
 * the value from it. Otherwise we just return what
 * we pass.
 */
export const unRef = <T>(target: MaybeRef<T>) =>
    isRef(target) ? (target as MutableRefObject<T>).current : (target as T);
