import { type Entry } from 'contentful';
import { type NextRouter } from 'next/router';
import { type HalLink } from 'bb/common/types';

export const getFromQuery = <T = undefined>(
    queryObject?: NextRouter['query'],
    property?: string,
    fallback: T | undefined = undefined
) => {
    if (!queryObject) return fallback;

    const target = property && queryObject[property];
    const result = Array.isArray(target) ? target[0] : target;

    return result ?? fallback;
};

export const getImageUrl = (
    entry: Entry<{
        fields: {
            file?: {
                url?: string;
            };
        };
        contentTypeId: string;
    }>
) => entry?.fields?.file?.url;

export const setSearchParams = <T extends string | HalLink | null>(
    input?: T,
    params?: Record<string, string>
): T | undefined => {
    if (!input || !params) return input as T;
    const isHAL = typeof input !== 'string';

    const url = new URL(isHAL ? input.href : input);

    Object.entries(params).forEach(([key, val]) => {
        url.searchParams.set(key, val);
    });

    return (
        isHAL
            ? {
                  ...(input as HalLink),
                  href: url.href
              }
            : url.href
    ) as T;
};
