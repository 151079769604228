import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const CheckmarkIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="m15.28 3.693 1.202.614-6.232 12.187-6.296-4.363.769-1.11 5.027 3.485Z"
            />
        </svg>
    </Icon>
);
