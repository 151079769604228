import React, { type FunctionComponent } from 'react';
import classnames from 'classnames';
import css from './toggleButton.module.scss';

export type ToggleButtonProps = {
    onClick?(): void;
    selected?: boolean;
    id?: string;
    name?: string;
    label: string;
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
};

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
    selected = false,
    onClick,
    id,
    name,
    disabled,
    label,
    className,
    children
}) => (
    <button
        className={classnames(css.base, className)}
        id={id}
        name={name}
        onClick={onClick}
        disabled={disabled}
        type="button"
        aria-pressed={selected}
        aria-label={label}
        tabIndex={0}
    >
        {children}
    </button>
);
