import React from 'react';
import { type PolymorphicComponent, type PolymorphicRef } from 'bb/ui';
import { Text } from '../Text';
import type { TextProps } from '../Text';

/**
 * This component used to only allow 55ch. Since then
 * we have migrated towards a more dynamic approach.
 * To keep compatibility with legacy code we default
 * to 55.
 */
export const DEFAULT_MAX_WIDTH_CH = 55;

export type LimitaryTextProps<TElementType extends React.ElementType = 'p'> =
    PolymorphicComponent<
        TElementType,
        {
            /**
             * For dynamic maxWidth we use inline styling.
             */
            maxCharacters?: Exclude<React.CSSProperties['maxWidth'], string>;
        } & TextProps<TElementType>
    >;

const LimitaryTextComponent = (
    {
        children,
        maxCharacters = DEFAULT_MAX_WIDTH_CH,
        ...props
    }: LimitaryTextProps<'p'>,
    ref?: PolymorphicRef<'p'>
) => (
    <Text
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        style={{
            maxWidth: `${maxCharacters}ch`
        }}
    >
        {children}
    </Text>
);

export const LimitaryText = React.forwardRef(LimitaryTextComponent) as <
    T extends React.ElementType = 'p'
>(
    props: LimitaryTextProps<T>
) => JSX.Element;

export default Text;
