import React, { useRef } from 'react';
import classnames from 'classnames';
import { type PolymorphicRef } from 'bb/ui/types';
import { assignRefs } from 'bb/utils/assignRefs';
import { makeA11yProps } from '../helpers';
import { InputController } from '../InputController';
import css from './input.module.scss';
import { type BaseInputProps, type InputElementType } from './Input.types';

/**
 * A base input component that doesn't rely on any form
 * library to be used. Is used either natively or with
 * a wrapper around it to provide functionality to be
 * used with react-hook-form or another lib.
 */
const BaseInputComponent = (
    props: BaseInputProps<'input'>,
    ref: PolymorphicRef<'input'>
) => {
    const {
        helperText: passedHelperText,
        as: Component = 'input',
        maxLength,
        value,
        type = 'text',
        inputClassName,
        className,
        fluid = false,
        disableFocusIndicator = false,
        disableMaxLengthCounter = false,
        startAdornment,
        endAdornment,
        disableBorder,
        disablePadding,
        borderColor,
        name,
        disabled,
        placeholder,
        label = placeholder,
        error,
        hidden,
        style,
        componentClassName,
        block,
        ...restProps
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [firstPassedHelperText, secondPassedHelperText] = Array.isArray(
        passedHelperText
    )
        ? passedHelperText
        : [passedHelperText];

    const firstHelperText =
        maxLength && typeof value === 'string' && !disableMaxLengthCounter
            ? `${value.length}/${maxLength}`
            : firstPassedHelperText;

    return (
        <InputController
            borderColor={borderColor}
            className={className}
            disableBorder={disableBorder}
            disablePadding={disablePadding}
            disabled={disabled}
            endAdornment={endAdornment}
            error={error}
            fluid={fluid}
            helperText={[firstHelperText, secondPassedHelperText]}
            inputProps={{
                className: classnames(
                    !disableFocusIndicator && css.wrapper,
                    inputClassName
                )
            }}
            label={label}
            name={name}
            startAdornment={startAdornment}
            hidden={hidden}
            style={style}
            block={block}
            inputRef={inputRef}
        >
            <Component
                name={name}
                ref={assignRefs(ref, inputRef)}
                type={type}
                value={value}
                maxLength={maxLength}
                className={classnames(
                    css.component,
                    fluid && css.fluid,
                    componentClassName
                )}
                disabled={disabled}
                placeholder={placeholder}
                {...restProps}
                {...makeA11yProps({ ...restProps, label })}
            />
        </InputController>
    );
};

export const BaseInput = React.forwardRef(BaseInputComponent) as <
    TInputElementType extends InputElementType = 'input'
>(
    props: BaseInputProps<TInputElementType>
) => JSX.Element;
