import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const BooksIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="m4.033 3.5 1.482.234-2.034 12.84L2 16.34zM6.007 5.537h1.5v11h-1.5zM9.007 7.537h1.5v9h-1.5zM12.007 3.537h1.5v13h-1.5zM14.25 5.695l1.485-.209 1.53 10.893-1.485.209z"
                fillRule="evenodd"
            />
        </svg>
    </Icon>
);
