import axios, {
    type AxiosResponse,
    type AxiosInstance,
    type AxiosRequestConfig,
    type InternalAxiosRequestConfig,
    type AxiosError
} from 'axios';
import { getTraceId } from '../utils/trace';
import { refresh } from './refresh';

const headers = {
    'bb-trace-id': getTraceId()
};

const createInstance = (baseURL?: string): AxiosInstance =>
    axios.create({
        baseURL,
        timeout: 10000,
        headers
    });

export const api = createInstance();

const NO_REFRESH_URLS = [
    '/api/login',
    '/api/login/refresh',
    '/api/users/password/*',
    '/api/accounts/changeemailrequests/*',
    '/api/login/external'
];

const isAllowedUrl = (req: InternalAxiosRequestConfig) =>
    NO_REFRESH_URLS.every((url) =>
        url.endsWith('/*')
            ? !req.url?.startsWith(url.replace('/*', ''))
            : req.url !== url
    );

/**
 * Refresh token Axios interceptor
 */
export const registerRefreshTokenInterceptor = () => {
    let failedRequests = 0;
    let timer = 0;
    let refreshPromise: Promise<AxiosResponse> | null = null;

    const interceptor = api.interceptors.response.use(
        (res) => res,
        async (err: AxiosError) => {
            const originalRequest = err.config;

            if (
                originalRequest &&
                err?.response?.status === 401 &&
                isAllowedUrl(originalRequest)
            ) {
                window.clearTimeout(timer);

                timer = window.setTimeout(() => {
                    failedRequests = 0;
                }, 3000);

                failedRequests += 1;

                if (failedRequests >= 5) throw err;

                refreshPromise =
                    refreshPromise ?? api.post('/api/login/refresh');

                return refreshPromise
                    .then(() => api.request(originalRequest))
                    .catch((_err) => {
                        refresh('loggedIn', false);
                        throw _err;
                    })
                    .finally(() => {
                        refreshPromise = null;
                    });
            }

            throw err;
        }
    );

    return () => {
        api.interceptors.response.eject(interceptor);
        window.clearTimeout(timer);
    };
};

export const get = <T, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig = {}
): Promise<R> =>
    api.get(url, {
        ...config
    });

export const post = <T, R = AxiosResponse<T>>(
    url: string,
    body: Record<string, unknown>
): Promise<R> => api.post(url, body);

export const put = <T, R = AxiosResponse<T>>(
    url: string,
    body: Record<string, unknown>
): Promise<R> => api.put(url, body);

export const del = <T, R = AxiosResponse<T>>(url: string): Promise<R> =>
    api.delete(url);
