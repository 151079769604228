import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const ProfilesIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path d="m7.638 17.805-1.11-.757a8.708 8.708 0 0 1 4.987-3.224 4.47 4.47 0 1 1 3.912 0c1.11.186 2.27.782 3.183 1.267l-.596 1.132c-.892-.503-2.273-1.28-4.543-1.28a7.361 7.361 0 0 0-5.833 2.862Zm5.833-4.873a3.129 3.129 0 1 0 0-6.257 3.129 3.129 0 0 0 0 6.257Z" />
                <path
                    d="M6.455-.65c-2.635 0-4.36 2.218-4.365 4.453-.002 1.095.36 2.149 1.101 2.943.69.738 1.72 1.194 2.96 1.332-2.729.626-5.07 1.823-6.67 3.945l1.039.784c1.718-2.279 4.133-3.57 7.406-3.914l.785-.082-.697-2.288-.618.18s-.49.128-.916.135c-1.096-.076-1.846-.45-2.34-.979-.496-.531-.751-1.24-.75-2.054C3.395 2.22 4.543.65 6.456.65c1.929 0 3.066 1.616 3.066 2.536h1.3c0-1.83-1.747-3.836-4.366-3.836z"
                    transform="translate(2 3)"
                />
            </g>
        </svg>
    </Icon>
);
