// @ts-strict-ignore
import React, { type FC, useRef } from 'react';
import classnames from 'classnames';
import { useOnScreen } from '../hooks';
import css from './reveal.module.scss';

type RevealProps = {
    children: React.ReactNode;
    className?: string;
};

export const Reveal: FC<RevealProps> = ({ children, className }) => {
    const ref = useRef<HTMLHeadingElement>(null);

    const [isIntersecting] = useOnScreen(ref, 0.2);

    return (
        <div ref={ref} className={classnames(css.base, className)}>
            <div
                className={classnames(
                    css.animationSlide,
                    {
                        [css.show]: isIntersecting
                    },
                    className
                )}
            >
                {children}
            </div>
        </div>
    );
};
