import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const BellIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="nonzero">
                <path d="M12.189 1.95a7.421 7.421 0 0 0-2.18-.344c-.706 0-1.405.115-2.096.344l-.001 1.904-.047.01c-1.669.391-2.181 1.268-2.39 3.219l-.08.817c-.136 1.276-.296 1.944-.727 2.86-.134.283-.29.527-.5.796l-.453.554c-.563.698-.815 1.285-.815 2.19v.65h14.216v-.65c0-.83-.214-1.394-.686-2.02l-.179-.224-.423-.513a4.118 4.118 0 0 1-.498-.788c-.418-.872-.593-1.516-.737-2.652l-.065-.562-.057-.535c-.21-1.812-.667-2.677-2.07-3.088l-.213-.056V1.95Zm-1.301 1.3v1.699l.548.087c1.157.185 1.493.467 1.677 1.61l.046.334.114 1.035c.167 1.43.36 2.209.884 3.302.18.374.386.693.648 1.028l.48.582.123.156c.112.151.198.288.261.428l.054.138H4.292l.054-.139c.063-.14.148-.277.26-.428l.176-.222.412-.504c.265-.34.471-.663.65-1.043.505-1.07.69-1.845.84-3.242l.102-1.018.02-.173.048-.313c.171-.976.502-1.289 1.489-1.477l.316-.053.554-.082-.001-1.705c.24-.123.506-.185.796-.185.29 0 .584.062.88.185Z" />
                <path d="M8.344 14.513c.254 1.353.791 1.92 1.681 1.92.842 0 1.367-.508 1.632-1.711l.042-.207 1.279.236c-.355 1.915-1.362 2.982-2.953 2.982-1.527 0-2.518-.982-2.913-2.754l-.046-.226 1.278-.24Z" />
            </g>
        </svg>
    </Icon>
);
