import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const AuthorIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.14179566,13.7710763 C7.07305617,14.6465258 9.02362305,15.6531771 9.974942,16.8477925 C11.1545141,15.366549 13.8703647,14.174288 18.0871233,13.1628028 C18.0871233,13.1628028 18.399884,14.4666639 18.399884,14.4666639 C13.3156742,15.686227 10.7583572,17.1376771 10.643184,18.5617456 C10.643184,18.5617456 9.30669999,18.5617456 9.30669999,18.5617456 C9.1915268,17.1376771 6.63420983,15.686227 1.55,14.4666639 C1.55,14.4666639 1.86276066,13.1628028 1.86276066,13.1628028 C2.17177796,13.2369276 2.47273476,13.3120233 2.76561714,13.3881324 C2.77395048,13.373903 2.78286591,13.3598743 2.79236891,13.3460761 C4.03661597,11.5394383 5.90160006,10.2754248 8.01914631,9.78948011 C6.53088602,9.0640635 5.5054492,7.53652995 5.5054492,5.7694928 C5.5054492,3.30106009 7.50650929,1.3 9.974942,1.3 C12.4433747,1.3 14.4444348,3.30106009 14.4444348,5.7694928 C14.4444348,7.53628747 13.4192794,9.06364427 11.9313503,9.78918143 C13.0936198,10.0557404 14.1857216,10.5585701 15.1475142,11.2688223 C15.1475142,11.2688223 14.350989,12.347442 14.350989,12.347442 C13.0928807,11.4183705 11.5732524,10.9094095 9.974942,10.9094095 C7.6588593,10.9094095 5.52517688,11.9843399 4.14179566,13.7710763 Z M9.974942,8.89813775 C11.7028449,8.89813775 13.103587,7.49739569 13.103587,5.7694928 C13.103587,4.0415899 11.7028449,2.64084784 9.974942,2.64084784 C8.2470391,2.64084784 6.84629704,4.0415899 6.84629704,5.7694928 C6.84629704,7.49739569 8.2470391,8.89813775 9.974942,8.89813775 Z"
                fillRule="nonzero"
            />
        </svg>
    </Icon>
);
