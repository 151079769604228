import { useEffect, useRef } from 'react';

/**
 * Helper hook that works like onMount
 */
export const useOnce = (callback: () => void): void => {
    const changed = useRef(false);

    useEffect(() => {
        if (!changed.current) {
            changed.current = true;
            callback();
        }
    }, [callback, changed]);
};
