import React, { type PropsWithChildren, createContext } from 'react';
import { type LinkProps } from './Link/Link';

export type LinkConfigContextType = Pick<
    LinkProps,
    'reload' | 'prefetch' | 'shallow' | 'scroll'
>;

export const LinkConfigContext = createContext({} as LinkConfigContextType);

export type LinkConfigProviderProps = LinkConfigContextType & PropsWithChildren;

/**
 * Provider for setting default link props for all `Link` components.
 *
 * Useful for when we need to override all links that may be nested
 * deeply in the component tree.
 */
export const LinkConfigProvider = ({
    children,
    ...linkProps
}: LinkConfigProviderProps) => {
    return (
        <LinkConfigContext.Provider value={linkProps}>
            {children}
        </LinkConfigContext.Provider>
    );
};

export const useLinkConfig = () => React.useContext(LinkConfigContext);
