import React, { type FC } from 'react';
import classNames from 'classnames';
import css from './deprecatedDivider.module.scss';

type DeprecatedDividerProps = {
    small?: boolean;
    color?: string;
    className?: string;
};

/**
 * @deprecated Use `Divider` instead
 */
export const DeprecatedDivider: FC<DeprecatedDividerProps> = ({
    color,
    small = false,
    className
}) => (
    <div
        className={classNames(
            css.base,
            small && css.small,
            color && css[color],
            className
        )}
    />
);
