import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export function useIsNavigating() {
    const router = useRouter();
    const [isNavigating, setIsNavigating] = useState(false);

    useEffect(() => {
        const handleRouteChangeStart = () => setIsNavigating(true);
        const handleRouteChangeComplete = () => setIsNavigating(false);

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router]);

    return isNavigating;
}
