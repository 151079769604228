import {
    useMutation,
    type UseMutationPassableOptions
} from 'bb/api/browser/useMutation';
import { type VerifyRecaptchaPayload } from 'bb/auth/types';

export const useVerifyRecaptcha = (
    options?: UseMutationPassableOptions<'post', '/api/verify-recaptcha'>
) => {
    const { trigger } = useMutation('post', '/api/verify-recaptcha', options);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Callback extends (...args: any[]) => void | Promise<any>>(
            callback: (
                // eslint-disable-next-line no-shadow
                ...args: Callback extends (...args: infer Args) => void
                    ? Args[0] extends Partial<VerifyRecaptchaPayload>
                        ? Args
                        : never
                    : never
            ) => void,
            { enabled = true }: { enabled?: boolean } = {}
        ) =>
        (...args: Parameters<typeof callback>) =>
            enabled
                ? trigger(args[0]).then(() => callback(...args))
                : callback(...args);
};
