import { type HalLink, type HalLinks } from 'bb/common/types';
// eslint-disable-next-line import/no-cycle
import { type Contributor } from './book/types';

export type AppViewsResponse = {
    _embedded: {
        views: {
            title: string;
            _links: HalLinks;
        }[];
    };
};

export enum AppViewItemType {
    BookList = 'BookList',
    BookTopList = 'BookTopList',
    BookPromo = 'BookPromo',
    SeriesPromo = 'SeriesPromo',
    CategoryList = 'CategoryList'
}

export type AppViewItemRenderType = 'BookList' | 'NumberedList';

export type AppViewItemContentResponse =
    | BookListResponse
    | BookPromoResponse
    | BookSeriesPromoResponse
    | CategoryLightListResponse;

export type AppViewResponse = {
    title: string;
    trackingid: string;
    _embedded: { items: AppViewItemResponse[] };
    _links: HalLinks;
};

export type AppViewItemResponse = {
    id: number;
    title: string;
    description: string;
    type: AppViewItemType;
    rendertype: AppViewItemRenderType | null;
    _links: HalLinks;
};

export type AppView = {
    title: string;
    items: AppViewItemResponse[];
    trackingId?: string;
    next?: HalLink;
};

export type SeriesLightResponse = {
    id: number;
    name: string;
    partnumber?: number;
    url: string;
};
export type ContributorLightResponse = {
    id: number;
    displayname: string;
    role: string; // TODO: Add enum?
    booksurl: string;
};

export type SingleSale = {
    streamingstartdate?: string | null;
    amount: number | null;
    currency: string | null;
};

export type Badge = {
    id: string;
    translationKey: string;
    icon?: string;
    type?: string;
};

export type BookLightResponse = {
    id: number;
    title: string;
    image: string;
    author: string;
    grade: number;
    ebook?: boolean;
    audio?: boolean;
    audiobooksinglesale?: SingleSale;
    audiobookisbn?: string | null;
    ebookisbn?: string | null;
    ebooksinglesale?: SingleSale;
    contenttypetags: string[];
    series?: SeriesLightResponse;
    topbadges: Badge[];
    _embedded?: {
        contributors?: Contributor[];
    };
    _links?: HalLinks<'self'>;
};

export type SortResponse = {
    sortby: 'relevance';
    sortorder: 'desc' | 'asc';
};

type FilterOptions = 'format' | 'language';

export type FilterOptionsResponse = FilterOptions[];

export type FormatOptions = 'ebook' | 'audiobook';

export type SortHalLink = HalLink & {
    title:
        | 'relevance|desc'
        | 'publishdate|desc'
        | 'publishdate|asc'
        | 'rating|desc'
        | 'title|asc'
        | 'author_lastname|asc';
};

export type BookListHalResponse = HalLinks & {
    sort: SortHalLink[];
};

export type BookListResponse = {
    id: number;
    type: AppViewItemType;
    rendertype: AppViewItemRenderType | null;
    title: string;
    count: number;
    sort: SortResponse;
    filter: {
        includeerotic: boolean;
        formats: FormatOptions[];
        languages: string[];
    };
    filteroptions: FilterOptionsResponse;
    description: string;
    _embedded: { books?: BookLightResponse[] };
    _links: BookListHalResponse;
};

export type BookPromoResponse = {
    id: number;
    type: AppViewItemType;
    title: string;
    description: string;
    _links: HalLinks;
    _embedded: { book: BookLightResponse };
};

export type BookSeriesPromoResponse = {
    id: number;
    type: AppViewItemType;
    title: string;
    imageUrl: string;
    description: string;
    _links: HalLinks;
    _embedded: {
        series: {
            id: number;
            authors: string[];
            name: string;
            links: HalLinks;
        };
    };
};

type SeriesPart = {
    _embedded: {
        partnumber: number;
        book: BookLightResponse;
    };
};

export type SeriesResponse = {
    name: string;
    _embedded: {
        parts: Array<SeriesPart>;
    };
};

export type ApiSeriesResponse = SeriesResponse & {
    id: string;
    image: string;
    description: string;
    count: number;
    offset: number;
    limit: number;
    _links: HalLinks;
};

export type CategoryLight = {
    id?: number;
    image?: string;
    title: string;
    description?: string;
    parentid?: number;
    preamble?: string;
    metaTitle?: string;
    metaDescription?: string;
};

type CategoryHalLinkResponse = HalLinks & {
    books: HalLink;
};

export type CategoryLightResponse = CategoryLight & {
    path: CategoryLight[];
    books?: BookLightResponse[];
    _embedded: {
        children: CategoryLightListResponse[];
        topbooks: BookLightResponse[];
    };
    _links: CategoryHalLinkResponse;
};

export type CategoryLightListResponse = {
    _embedded: {
        categories: CategoryLight[];
    };
    _links: {
        books: HalLink;
        dynamiccontent: HalLink;
        self: HalLink;
        topbooks: HalLink;
    };
    title: string;
    id: number;
    type: string;
    preamble: string;
    description: string;
    image: string;
    metaDescription: string;
    metaTitle: string;
    parentId: number | null;
};

export type CategoriesResponse = {
    _embedded: {
        categories: CategoryLightResponse[];
    };
};

export type MarketSettingsResponse = {
    country: string;
    blockedappfeatures: string[];
    languages: {
        language: string;
        languagecode: string;
        preselected: boolean;
        default: boolean;
        optional: boolean;
        displayname: string;
    }[];
    weblinks: Record<string, string>;
    translations: { key: string; translation: string }[];
};

export type DiscoveryResponse = {
    marketsettings: MarketSettingsResponse;
    _links: HalLinks;
};
