import { type PublicEnvVariable, type PublicEnvVariables } from './types';

/**
 * Helper for getting the value of an environment variable outside
 * of the React context. Uses window object in client and process.env
 * on the server.
 */
export const getPublicEnv = (variable: PublicEnvVariable) => {
    const publicEnvVariables = ((typeof window !== 'undefined' &&
    process.env.NODE_ENV !== 'test'
        ? window.__PUBLIC_ENV_VARIABLES__
        : process.env) ?? {}) as PublicEnvVariables;

    return publicEnvVariables[variable];
};
