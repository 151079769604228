import { useEffect, useRef } from 'react';
import { unRef } from 'bb/utils/unRef';
import { type MaybeRef } from '../types';

export function useEventListener<
    EventName extends keyof HTMLElementEventMap,
    THTMLElement extends HTMLElement | void = void
>(
    eventName: EventName,
    handler: (event: HTMLElementEventMap[EventName]) => void,
    maybeRef?: MaybeRef<THTMLElement>,
    options?: boolean | AddEventListenerOptions
) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler);
    /**
     * Cast window to unknown to avoid type error and then
     * to THTMLElement to avoid type error (which is not possible).
     */
    const targetElement =
        unRef(maybeRef) ??
        ((typeof window !== 'undefined'
            ? window
            : undefined) as unknown as THTMLElement);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        if (!(targetElement && targetElement.addEventListener)) return;

        // Create event listener that calls handler function stored in ref
        const listener: typeof handler = (event) => savedHandler.current(event);

        targetElement.addEventListener(eventName, listener, options);

        // Remove event listener on cleanup
        // eslint-disable-next-line consistent-return
        return () => {
            targetElement.removeEventListener(eventName, listener, options);
        };
    }, [eventName, options, targetElement]);
}
