// @ts-strict-ignore
import { type UrlObject } from 'url';
import { type NextRouter } from 'next/router';
import { DEFAULT_PAGE_SIZE } from 'bb/page/server/consts';
import { routes } from './config';
import { type Router, type Route, type RouterQuery } from './types';

export type Url = string | UrlObject;

export const getUrl = (pathname: string, query?: RouterQuery) => {
    let newPathName = pathname;
    const searchParams = new URLSearchParams();

    Object.keys(query).forEach((key) => {
        const keyPlaceHolder = `[${key}]`;
        const queryValue = query[key];
        const value = Array.isArray(queryValue) ? queryValue[0] : queryValue;
        if (pathname?.includes(keyPlaceHolder)) {
            newPathName = newPathName.replace(keyPlaceHolder, value);
        } else {
            searchParams.set(key, value);
        }
    });

    const querystring = searchParams.toString();

    return `${newPathName}${querystring && `?${querystring}`}`;
};

export const persistQueryParams = (
    keys: string[],
    query: NextRouter['query']
): NextRouter['query'] =>
    keys.reduce(
        (queryParams, key) => ({
            ...queryParams,
            ...(query[key] ? { [key]: query[key] } : {})
        }),
        {}
    );

export const findRouteByPath = (path: string | string[]): Route | undefined => {
    const pathname = Array.isArray(path) ? path[0] : path || '';
    return Object.values(routes).find((route) => route.href === pathname);
};

export const parsePageNumber = (
    rawPage: Router['router']['query'][keyof Router['router']['query']]
) => {
    const pageNumber = Number(Array.isArray(rawPage) ? rawPage[0] : rawPage);

    return Number.isFinite(pageNumber) ? pageNumber : 1;
};

/**
 * By default the backend doesn't support actual pagination.
 * This function is used to calculate the offset and limit for
 * the query that will be sent to the backend.
 */
export const makePaginationQuery = (
    query: RouterQuery,
    pageSize = DEFAULT_PAGE_SIZE
) => ({
    offset: pageSize * (parsePageNumber(query.page) - 1),
    limit: pageSize
});
