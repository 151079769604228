import {
    CLOSE_BUTTON_CLASSNAME,
    GO_BACK_BUTTON_CLASSNAME
} from 'bb/common/components/buttons/consts';

export const getFocusableElements = (element: HTMLElement) => {
    const focusableElements = element.querySelectorAll(
        'button:not(:disabled), [href]:not(:disabled), input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"])'
    );

    const firstPreferedElement = element.querySelectorAll(
        `button:not(.${GO_BACK_BUTTON_CLASSNAME}):not(.${CLOSE_BUTTON_CLASSNAME}):not(:disabled), [href]:not(:disabled), input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"]):not(:disabled)`
    )[0] as HTMLElement | undefined;

    const firstElement = focusableElements[0] as HTMLElement | undefined;
    const lastElement = focusableElements[focusableElements.length - 1] as
        | HTMLElement
        | undefined;

    return {
        focusableElements,
        firstElement,
        /**
         * Preferably we want to focus the first interactive element of the modal. In many cases
         * that is the close button or the go back button, though. We'd like to skip those so the
         * user can tab through the modal content first.
         */
        firstPreferedElement,
        lastElement
    };
};
