import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const StarIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
            <path
                fill="currentColor"
                d="M15.5 55.894c1.1.834 2.44.506 4.047-.655l12.117-8.9 12.146 8.9c1.577 1.161 2.917 1.489 4.018.655 1.072-.804 1.28-2.173.626-4.018l-4.793-14.26 12.235-8.782c1.608-1.101 2.292-2.322 1.846-3.632-.418-1.25-1.668-1.876-3.632-1.845l-15.005.118-4.554-14.348c-.596-1.876-1.518-2.858-2.886-2.858-1.34 0-2.263.982-2.888 2.858l-4.555 14.348-15.004-.118c-1.965-.03-3.185.595-3.632 1.845-.416 1.31.269 2.53 1.847 3.632l12.234 8.782-4.793 14.26c-.655 1.845-.446 3.214.626 4.018"
            />
        </svg>
    </Icon>
);
