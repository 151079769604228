import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const EyeCrossedIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path
                    d="M7.947 5.616 6.94 4.628c1.085-.41 2.077-.606 2.976-.59 4.685.084 9.267 4.168 9.267 5.746 0 .947-1.046 2.22-3.139 3.822l-.968-.905c.717-.527 2.32-1.925 2.758-2.917-.492-.848-3.565-4.351-7.918-4.432a6.858 6.858 0 0 0-1.969.264ZM12.136 14.533l1.007.988c-1.085.41-2.077.607-2.976.59C5.482 16.027.9 11.943.9 10.365c0-.947 1.046-2.22 3.139-3.822l.968.905c-.717.527-2.32 1.925-2.758 2.917.492.848 3.565 4.351 7.918 4.432a6.858 6.858 0 0 0 1.969-.264Z"
                    fillRule="nonzero"
                />
                <path d="M13.58 11.264a3.75 3.75 0 0 0-4.785-4.806l4.784 4.806ZM6.22 8.643a3.75 3.75 0 0 0 4.785 4.806L6.222 8.643ZM3.623 4.62l.919-.92 11.667 11.667-.919.92z" />
            </g>
        </svg>
    </Icon>
);
