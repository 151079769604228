// @ts-strict-ignore
import { NAV_BAR_ID } from 'bb/app/nav/Navbar/consts';
import { type MarketConfig } from 'bb/config/marketConfig';
import { getEnvironment, isProductionEnvironment } from 'bb/utils/environment';

export const MISSING_SLUG = 'MISSING_SLUG';

export const getIdFromSlug = (slug: string): string => {
    const matches = slug.match(/-(\d+)/g);
    if (!matches) {
        const cleanSlug = slug.replace(/([^\d]+)/gi, '');
        return cleanSlug;
    }
    return matches[matches.length - 1]?.replace('-', '');
};

export const getCanonicalPageUrl = (pagePath: string) =>
    `https://www.bookbeat.com${pagePath}`;

export const isShallowEqualObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    return keys1.every((key) => obj1[key] === obj2[key]);
};

export const isEqualObjects = (obj1, obj2): boolean => {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;
    if (obj1 === null || obj2 === null) return obj1 === obj2;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    return keys1.every((key) => {
        if (
            (Array.isArray(obj1[key]) || typeof obj1[key] === 'object') &&
            obj1[key] !== null
        )
            return isEqualObjects(obj1[key], obj2[key]);
        return obj1[key] === obj2[key];
    });
};

export const getDefaultStructuredData = () => {
    const bookBeatUrl = 'https://www.bookbeat.com';
    const bookBeatLogo = `${bookBeatUrl}/images/favicons/mstile-310x310.png`;

    return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'BookBeat',
        url: bookBeatUrl,
        logo: bookBeatLogo
    };
};

export const getMarketUrl = (market: MarketConfig) => {
    const baseUrl = isProductionEnvironment()
        ? 'https://www.bookbeat.com'
        : `https://${getEnvironment()}.bookbeat.com`;

    return `${baseUrl}/${market?.marketCode}`;
};

export const getValue = <T>(result: PromiseSettledResult<T>) =>
    (result?.status === 'fulfilled' && result.value) || null;

export const scrollToElement = (
    element: HTMLDivElement,
    offsetHeader = true
) => {
    const y = offsetHeader
        ? element.offsetTop - document.getElementById(NAV_BAR_ID).clientHeight
        : element.offsetTop;
    window.scrollTo({ top: y, behavior: 'smooth' });
    element.focus({
        preventScroll: true
    });
};
