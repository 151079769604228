import React, { useEffect, useRef } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { type FieldValues } from 'react-hook-form';
import { getPublicEnv } from 'bb/config/env';
import { type SubmitHandler, type UseFormReturn } from 'bb/ui';
import { useStableCallback } from './useStableCallback';

const getContainerElement = () => {
    const iframe = document?.querySelector(
        "iframe[src^='https://www.google.com/recaptcha/api2/bframe']"
    );
    return iframe?.parentNode?.parentNode as HTMLDivElement | undefined;
};

export type UseRecapcthaProps = {
    onOpen?: () => void;
    /**
     * onClose is required because we need to listen
     * for when a user potentially closes the captcha
     * challenge.
     */
    onClose: () => void;
    /**
     * Skip recaptcha script being loaded.
     *
     * @defaultValue `false`
     */
    skipRecaptcha?: boolean;
};

export const useRecaptcha = ({
    onOpen: passedOnOpen,
    onClose: passedOnClose,
    skipRecaptcha = false
}: UseRecapcthaProps) => {
    const ref = useRef<Recaptcha>(null);
    const observer = useRef<MutationObserver | null>(null);
    const hasSubmitted = useRef(false);

    const onOpen = useStableCallback(passedOnOpen);
    const onClose = useStableCallback(passedOnClose);

    useEffect(() => {
        /**
         * The recaptcha object doesn't have any built in support
         * for handling events happening in the iframe. To achieve
         * such an API we use mutation observer below and listen
         * for changes to the style of thte container element.
         */
        observer.current = new MutationObserver(([entry]) => {
            const container = entry?.target as HTMLDivElement;
            if (!container) return;

            switch (container.style.visibility) {
                case 'visible':
                    onOpen();
                    break;
                case 'hidden':
                    onClose();
                    observer.current?.disconnect();
                    break;
                default:
                    break;
            }
        });

        return () => {
            /**
             * Disconnect observer on unmount.
             */
            observer.current?.disconnect();
        };
    }, [onClose, onOpen]);

    const element = skipRecaptcha ? null : (
        <Recaptcha
            sitekey={getPublicEnv('NEXT_PUBLIC_RECAPTCHA_SITE_KEY') ?? ''}
            ref={ref}
            size="invisible"
        />
    );

    function onSubmit<T, A extends unknown[]>(
        cb: (args: A, recaptchaToken: string | null) => Promise<T> | T,
        skip = false
    ) {
        return async (...args: A) => {
            /**
             * Sometimes we wanna skip the recaptcha validation.
             * That is usually when we have verified an IP.
             */
            if (skip) {
                await cb(args, null);
                return;
            }

            /**
             * If we submitted before we need to reset the captcha
             * widget in order to get a new challenge.
             */
            if (hasSubmitted.current) {
                ref.current?.reset();
            }

            const container = getContainerElement();
            if (container) {
                observer.current?.observe(container, {
                    attributes: true,
                    attributeFilter: ['style']
                });
            }

            if (ref?.current) {
                hasSubmitted.current = true;
                const recaptchaToken = await ref.current.executeAsync();

                if (recaptchaToken) await cb(args, recaptchaToken);
            }
        };
    }

    return { element, onSubmit, ref };
};

export function getHookFormRecaptchaOptions<T extends FieldValues>(
    form: UseFormReturn<T>
) {
    return {
        onClose: () => {
            form.reset(undefined, {
                keepErrors: true,
                keepDirty: true,
                keepDirtyValues: true,
                keepValues: true,
                keepTouched: true,
                keepIsValid: true,
                keepSubmitCount: true,
                /**
                 * Keep all state but submit state.
                 *
                 * If the captcha is cancelled we want the
                 * form to be interactive again.
                 */
                keepIsSubmitted: false
            });
        }
    };
}

export function hookFormAdapter<T extends FieldValues>(cb: SubmitHandler<T>) {
    return (
        [values, form, event]: Parameters<typeof cb>,
        recaptchaToken: string | null
    ) => cb({ ...values, recaptchaToken }, form, event);
}
