/* eslint-disable @typescript-eslint/no-empty-object-type */

/**
 * Compare two objects for stricter equality.
 */
export const areObjectsEqual = <TObject extends {}>(a: TObject, b: TObject) => {
    if (a === b) {
        return true;
    }

    if (Object.keys(a).length !== Object.keys(b).length) {
        return false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in a) {
        if (a[key] !== b[key]) {
            return false;
        }
    }

    return true;
};
