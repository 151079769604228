import React from 'react';
import classNames from 'classnames';
import { useIsScreenSize } from 'bb/common/hooks';
import { TOAST_POSITIONS, Toast } from 'bb/ui/Toast';
import { type ToastStoreItem, useToastStore } from '../stores/toastStore';
import css from './toastContainer.module.scss';

export type ToastPositionProps = {
    toasts: ToastStoreItem[];
    position: NonNullable<ToastStoreItem['position']>;
};

export const ToastPosition = ({ toasts, position }: ToastPositionProps) => {
    const { remove } = useToastStore();

    if (!toasts.length) return null;

    return (
        <div className={classNames(css.root, css[position])}>
            <div className={css.inner}>
                {toasts.map(({ id, onClose: passedOnClose, ...restProps }) => (
                    <Toast
                        key={id}
                        onClose={() => {
                            remove(id);
                            passedOnClose?.();
                        }}
                        {...restProps}
                    />
                ))}
            </div>
        </div>
    );
};

export const ToastContainer = () => {
    const { state } = useToastStore();
    const isMdUp = useIsScreenSize('md');

    /**
     * On smaller devices we just render the toasts gathered
     * in the center.
     */
    if (!isMdUp) {
        /**
         * Position top-right works fine here since it doesn't
         * really matter in responsive.
         */
        return <ToastPosition position="top-right" toasts={state} />;
    }

    /**
     * Render by position in desktop.
     */
    return TOAST_POSITIONS.map((position) => (
        <ToastPosition
            key={position}
            position={position}
            toasts={state.filter((item) => item.position === position)}
        />
    ));
};
