import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const AccountIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path d="M11.5 5H18v1.3h-6.5zM11.5 8H18v1.3h-6.5zM11.5 11H18v1.3h-6.5z" />
                <path d="M8.529 7.834a2.5 2.5 0 0 1-2.5 2.5 2.5 2.5 0 0 1-2.5-2.5 2.5 2.5 0 0 1 2.5-2.5 2.5 2.5 0 0 1 2.5 2.5z" />
                <path d="M6.342 9.684c-1.383 0-2.778.799-4.26 2.212l.897.94c1.358-1.296 2.504-1.852 3.363-1.852.99 0 1.912.29 2.824.905l.727-1.078c-1.098-.74-2.3-1.127-3.551-1.127Z" />
                <path d="M11.5 14H18v1.3h-6.5z" />
            </g>
        </svg>
    </Icon>
);
