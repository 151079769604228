import { type ErrorResponse } from 'bb/api/browser/types';
import {
    type TranslationPrefix,
    useTranslation
} from 'bb/i18n/translationUtils';
import { type Namespace } from './types';

export type UseFormErrorMessageError = Error | ErrorResponse | null | undefined;

export type UseFormErrorMessageValues = Record<string, unknown>;

export type UseFormErrorMessageFallback = string | undefined;

export const useFormErrorMessageHandler = (namespaces: Namespace[]) => {
    const { t, i18n } = useTranslation([...namespaces, 'common']);

    return (
        error: UseFormErrorMessageError,
        fallback?: UseFormErrorMessageFallback,
        values?: UseFormErrorMessageValues
    ) => {
        if (!error) {
            return null; // No error, dont return anything
        }

        const errorMessage = (error as ErrorResponse)?.data?.message?.replace(
            /\s/g,
            ''
        );

        const namespace = namespaces.find((current) =>
            i18n.exists(`${current}:${errorMessage}`)
        );

        const errorKey = `${namespace}:${errorMessage}`;

        return t(
            errorKey as TranslationPrefix,
            t((fallback ?? '') as TranslationPrefix, t('common:unknown_error')),
            values
        );
    };
};

export const useFormErrorMessage = (
    error: UseFormErrorMessageError,
    namespaces: Namespace[],
    fallback?: UseFormErrorMessageFallback,
    values?: UseFormErrorMessageValues
) => {
    return useFormErrorMessageHandler(namespaces)(error, fallback, values);
};
