import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const SearchIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="m17.093 18.19 1.032-.963-4.49-4.53.188-.245a6.564 6.564 0 0 0 1.328-3.972A6.682 6.682 0 0 0 8.47 1.8C4.788 1.8 1.8 4.797 1.8 8.48c0 3.682 2.988 6.68 6.67 6.68a6.569 6.569 0 0 0 3.815-1.214l.33-.27c.962.975 2.454 2.48 4.478 4.515ZM8.45 13.77a5.301 5.301 0 1 1 .002-10.602A5.301 5.301 0 0 1 8.45 13.77Z"
                fillRule="nonzero"
            />
        </svg>
    </Icon>
);
