import React, { useEffect, useState } from 'react';
import { useMutation } from 'bb/api/browser';
import { useApp } from 'bb/app/AppContext';
import { useRouter } from 'bb/app/router';
import { getUrl } from 'bb/app/router/utils';
import { ExternalLink } from 'bb/common/ExternalLink';
import { Text, useTranslation } from 'bb/i18n';
import { Checkbox } from 'bb/ui';
import { Button } from 'bb/ui/Button';
import { Modal } from 'bb/ui/Modal';
import { SimpleNotification } from 'bb/ui/SimpleNotification';
import { useLogout } from '../hooks';
import { useAccount } from '../hooks/useAccount';

/*
 * Modal that forces a user without accepted T&C to accept them.
 * If they don't accept, they will be logged out.
 * Built without a form like SchemeForm because the checkbox state is not sent to the server.
 */
export const RequiredTermsModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const { routes } = useRouter();
    const { market } = useApp();

    const { t } = useTranslation(['common', 'consents']);

    const { logout } = useLogout();

    const {
        account,
        mutate: mutateAccount,
        isValidating: isValidatingAccount
    } = useAccount();

    const {
        trigger: triggerAcceptTerms,
        isMutating: isMutatingAcceptTerms,
        error
    } = useMutation('put', '/api/my/account/acceptterms', {
        onSuccess: () => {
            mutateAccount();
        }
    });

    useEffect(() => {
        if (account) {
            setShowModal(account.hasAcceptedTerms !== true);
        }
    }, [account]);

    const isLoading = isMutatingAcceptTerms || isValidatingAccount;

    return (
        <Modal
            isOpen={showModal}
            onClose={logout}
            showCloseButton={false}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            header={t('consents:consentDialogHeading')}
        >
            <Text t="consents:consentDialogText" />
            {error && (
                <SimpleNotification
                    variant="error"
                    message={t('common:unknown_error')}
                />
            )}
            <Checkbox
                onChange={async (e) => setAcceptedTerms(e.target.checked)}
                label={
                    <Text t="consents:termsAndConditions">
                        <ExternalLink
                            href={getUrl(routes.terms.href, { market })}
                        />
                        <ExternalLink
                            href={getUrl(routes.privacyNotice.href, { market })}
                        />
                    </Text>
                }
            />
            <Button
                onClick={logout}
                variant="outlined"
                color="primary-black"
                fluid
            >
                <Text t="common:logout" />
            </Button>
            <Button
                onClick={() => triggerAcceptTerms(null)}
                disabled={!acceptedTerms}
                isLoading={isLoading}
                fluid
            >
                <Text t="consents:dialogConsentButton" />
            </Button>
        </Modal>
    );
};
