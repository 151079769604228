import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const CalendarIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path d="M3.15 2.709c-.902 0-1.65.748-1.65 1.65v12.7c0 .902.748 1.65 1.65 1.65h13.7c.902 0 1.65-.748 1.65-1.65v-12.7c0-.902-.748-1.65-1.65-1.65zm0 1.299h13.7c.205 0 .35.146.35.351v12.7c0 .205-.145.35-.35.35H3.15a.335.335 0 0 1-.35-.35v-12.7c0-.205.145-.351.35-.351z" />
                <path d="M4.85 1.209v4h1.3v-4zM13.85 1.209v4h1.3v-4zM13.064 6.783l-3.732 5.324-2.402-2.105-.856.977 3.49 3.062 4.565-6.51Z" />
            </g>
        </svg>
    </Icon>
);
