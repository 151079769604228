import { usePublicEnvContext } from './context';
import { type PublicEnvVariable } from './types';

/**
 * Hook that returns a callback that can be used to check
 * the value of an env variable within the React context.
 */
export const usePublicEnv = () => {
    const publicEnvVariables = usePublicEnvContext();

    return (variable: PublicEnvVariable) => publicEnvVariables[variable];
};
