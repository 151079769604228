import {
    useEffect,
    useState,
    type RefObject,
    type DependencyList
} from 'react';

/**
 * Hook to determine if an element's content overflows its bounds.
 * @param {React.RefObject<HTMLElement>} ref The ref of the element to check for overflow.
 * @param {any} dependency Dependency to trigger re-check of the overflow.
 * @returns {boolean} Whether the element's content overflows.
 */

export const useOverflow = (
    ref: RefObject<HTMLElement>,
    dependency: DependencyList
): boolean => {
    const [hasOverflow, setHasOverflow] = useState(false);

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const isOverflowing = element.scrollHeight > element.clientHeight;
        setHasOverflow(isOverflowing);
    }, [ref, dependency]);

    return hasOverflow;
};
