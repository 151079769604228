import { useEffect } from 'react';
import { useAccountStore } from 'bb/account/hooks';
import { usePost } from 'bb/api/browser/usePost';
import { observer } from 'bb/app/stores';
import { useLoggerStore } from './useLoggerStore';

export const Logger = observer(() => {
    const loggerStore = useLoggerStore();
    const accountStore = useAccountStore();
    const { execute } = usePost('/api/log');
    useEffect(() => {
        if (loggerStore?.message) {
            const url = `${window.location?.hostname}${window.location?.pathname}`;
            execute({
                userId: accountStore?.user?.userId,
                ...loggerStore.message,
                loggedAtUrl: url,
                clientSide: true
            });
        }
    }, [accountStore?.user?.userId, execute, loggerStore?.message]);

    return null;
});
