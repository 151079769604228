// @ts-strict-ignore
import React, {
    type FunctionComponent,
    type CSSProperties,
    type ReactElement,
    type AriaAttributes,
    type HTMLAttributes
} from 'react';
import classnames from 'classnames';
import {
    alignItemsCss,
    justifyContentCss,
    type JustifyContentType,
    Row
} from '../Flex';
import flexWrapCss from '../Flex/flexWrap.module.scss';
import { type FlexWrapType } from '../Flex/types';
import { type MarginProps, getMargins } from '../Margin';
import { getPaddings } from '../Padding/getPaddings';
import { type PaddingProps } from '../Padding/types';
import { type AlignItems, type BorderDirection } from '../types';
import css from './list.module.scss';
import { type Direction } from './types';

export type ListProps = {
    direction?: Direction;
    style?: CSSProperties;
    listStyle?: 'disc' | 'decimal';
    alignItems?: AlignItems;
    justifyContent?: JustifyContentType;
    flexWrap?: FlexWrapType;
    as?: 'ul' | 'ol';
    className?: string;
    icon?: ReactElement;
    dataKey?: string;
    children?: React.ReactNode;
} & MarginProps &
    PaddingProps &
    AriaAttributes &
    HTMLAttributes<HTMLUListElement>;

export const List: FunctionComponent<ListProps> = ({
    padding,
    paddingTop,
    paddingRight,
    paddingLeft,
    paddingBottom,
    direction = 'column',
    alignItems,
    justifyContent = 'center',
    flexWrap = 'wrap',
    style,
    listStyle,
    className = '',
    as = 'ul',
    dataKey = '',
    icon,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    children,
    ...rest
}) => {
    const ListComponent = as;
    return (
        <ListComponent
            className={classnames(
                css.list,
                className,
                flexWrapCss[flexWrap],
                getMargins({
                    margin,
                    marginLeft,
                    marginRight,
                    marginBottom,
                    marginTop
                }),
                getPaddings({
                    padding,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    paddingTop
                }),
                {
                    [css[direction as string]]: Boolean(direction),
                    [css[listStyle as string]]: Boolean(listStyle),
                    [alignItemsCss[alignItems as string]]: Boolean(alignItems),
                    [justifyContentCss[justifyContent as string]]:
                        Boolean(justifyContent),
                    [css.icon]: Boolean(icon)
                }
            )}
            style={style}
            data-key={dataKey}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            {icon
                ? React.Children.map(children, (Child: ReactElement) =>
                      React.cloneElement(Child, { ...Child.props, icon })
                  )
                : children}
        </ListComponent>
    );
};

export type ListItemProps = {
    divider?: BorderDirection;
    icon?: ReactElement;
    dataKey?: string | number;
    fluid?: boolean;
} & MarginProps &
    PaddingProps &
    React.ComponentProps<'li'>;

export const ListItem = ({
    padding,
    paddingTop,
    paddingRight,
    paddingLeft,
    paddingBottom,
    divider,
    className = '',
    icon,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    dataKey = '',
    fluid,
    children,
    ...restProps
}: ListItemProps) => (
    <li
        className={classnames(
            css.listItem,
            className,
            getMargins({
                margin,
                marginLeft,
                marginRight,
                marginBottom,
                marginTop
            }),
            getPaddings({
                padding,
                paddingBottom,
                paddingLeft,
                paddingRight,
                paddingTop
            }),
            {
                [css[divider as string]]: Boolean(divider),
                [css.fluid]: Boolean(fluid)
            }
        )}
        data-key={dataKey}
        {...restProps}
    >
        {icon ? (
            <Row>
                <span className={css.icon}>{icon}</span>
                {children}
            </Row>
        ) : (
            children
        )}
    </li>
);
