// @ts-strict-ignore
import {
    EXECUTING,
    SUCCESS,
    ERROR,
    RESOLVED,
    REJECTED,
    PENDING,
    IDLE
} from './symbols';
import { type Action, type State, type StateWithRetry } from './types';

export const createReducer =
    <T>() =>
    (state: State<T>, { type, result, error }: Action<T>): State<T> => {
        switch (type) {
            case EXECUTING:
                return { ...state, error: null, status: PENDING };
            case SUCCESS:
                return {
                    error: null,
                    result,
                    status: RESOLVED
                };
            case ERROR:
                return {
                    error,
                    result: null,
                    status: REJECTED
                };
            default:
                return state;
        }
    };

export const createRetryReducer =
    <T>() =>
    (
        state: StateWithRetry<T>,
        { type, result }: Action<T>
    ): StateWithRetry<T> => {
        const { nrOfTries } = state;
        switch (type) {
            case EXECUTING:
                return {
                    ...state,
                    error: null,
                    result: null,
                    status: PENDING
                };
            case SUCCESS:
                return {
                    ...state,
                    error: null,
                    status: RESOLVED,
                    result
                };
            case IDLE:
                return {
                    ...state,
                    status: IDLE,
                    nrOfTries: nrOfTries + 1
                };
            case ERROR:
                return {
                    ...state,
                    error: null,
                    result: null,
                    status: REJECTED
                };
            default:
                return state;
        }
    };
