import {
    useWatch,
    type FieldPath,
    type FieldValues,
    type Control,
    type PathValue,
    type Path
} from 'react-hook-form';

export type WatchProps<
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
    /**
     * The name of the field to watch.
     */
    fieldName: TFieldName;
    /**
     * The children function that will receive the watched value.
     */
    children: (
        value: PathValue<TFieldValues, Path<TFieldValues>>
    ) => React.ReactNode;
    /**
     * The form control that comes from `useForm`.
     */
    control: Control<TFieldValues>;
};

/**
 * A component that watches a field and re-renders when the field changes.
 *
 * Usefor for when we need to render something depending on the state
 * of the field. For example, we can use this to show a message when
 * a field is invalid.
 *
 * Used like so:
 * ```tsx
 * <Watch fieldName={fieldName} control={control}>
 *   {(value) => <div>{value}</div>}
 * </Watch>
 * ```
 */
export const Watch = <
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    fieldName,
    children,
    control
}: WatchProps<TFieldValues, TFieldName>) => {
    const watch = useWatch<TFieldValues>({ name: fieldName, control });

    return children(watch);
};
