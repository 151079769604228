/* eslint-disable no-continue */
/* eslint-disable max-depth */
/* eslint-disable no-restricted-syntax */
import { type Resource, type i18n as I18n } from 'i18next';

/**
 * Adds resources to the i18next instance by iterating over the store object.
 */
export const addResourcesToI18Next = (instance: I18n, resources: Resource) => {
    if (resources && instance.isInitialized) {
        for (const locale of Object.keys(resources)) {
            const store = resources[locale];
            if (!store) continue;

            for (const ns of Object.keys(store)) {
                const resource = store[ns];
                if (!resource) continue;

                instance.addResourceBundle(locale, ns, resource, true, true);
            }
        }
    }
};
