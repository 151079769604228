import { useEffect, useMemo, useRef, useState } from 'react';
import { type BareFetcher, type PublicConfiguration } from 'swr/_internal';
import type { ErrorResponse } from 'bb/api/browser/types';
import { type UseApiOptions, useApi } from 'bb/api/browser/useApi';
import { useStableCallback } from 'bb/common/hooks/useStableCallback';

/**
 * Frontend only statuses that can be imported
 * from this file to keep constistency.
 */
export type UsePollingStatus = 'Timeout' | 'None';

export type UsePollingOptions<T> = Omit<
    UseApiOptions<T>,
    'refreshInterval' | 'dedupingInterval' | 'onSuccess'
> & {
    /**
     * Interval between requests in ms
     */
    interval?: number;
    /**
     * Time in seconds before bailing out
     */
    timeout?: number;
    /**
     * Time in seconds before polling is considered to be slow
     */
    slowAfter?: number;
    /**
     * Handler that is invoked upon a successful response.
     * It doesn't indicate that the polling is complete, but
     * only that we have a response. It's up to the callback
     * to return a boolean that determines if we are done.
     */
    onSuccess?: (
        data: T,
        key: string,
        config: Readonly<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            PublicConfiguration<T, ErrorResponse, BareFetcher<any>>
        >
    ) => boolean;
    /**
     * The maximum amount of errors before isDone becomes true.
     */
    maximumErrorCount?: number;
};

export type UsePollingReturnType<T> = ReturnType<typeof usePolling<T>>;

export const usePolling = <T>(
    link?: string | null,
    config: UsePollingOptions<T> = {}
) => {
    const {
        interval = 750,
        timeout = 20,
        slowAfter = 10,
        onSuccess: _onSuccess,
        maximumErrorCount = 3,
        enabled: _enabled = true,
        ...restConfig
    } = config;

    /**
     * Refs
     */
    const count = useRef(0);
    const errorCount = useRef(0);
    const timer = useRef(0);
    const timedOut = useRef(false);
    const error = useRef<ErrorResponse>();

    /**
     * State
     */
    const [isDone, setIsDone] = useState(false);
    const [isSlow, setIsSlow] = useState(false);

    const enabled = Boolean(link && _enabled);
    const onSuccess = useStableCallback(_onSuccess);

    if (enabled && !timer.current) timer.current = Date.now();

    const { data } = useApi<T, 'generic'>((!isDone && link) || null, {
        ...restConfig,
        fixed: true,
        keepPreviousData: true,
        refreshWhenHidden: true,
        refreshInterval: interval,
        dedupingInterval: interval,
        onSuccess: (...rest) => {
            errorCount.current = 0;
            count.current += 1;

            if (onSuccess(...rest)) setIsDone(true);
        },
        onError: (err, ...rest) => {
            errorCount.current += 1;

            if (errorCount.current >= maximumErrorCount) {
                error.current ??= err;
                setIsDone(true);
            }

            config.onError?.(err, ...rest);
        }
    });

    const duration = useMemo(
        () => (isDone ? Date.now() - timer.current : 0),
        [isDone]
    );

    useEffect(() => {
        const delay = isSlow
            ? timeout - slowAfter
            : Math.min(timeout, slowAfter);

        const timeoutTimer =
            enabled && !isDone
                ? window.setTimeout(() => {
                      if (timeout <= slowAfter || isSlow) {
                          timedOut.current = true;
                          setIsDone(true);
                      } else setIsSlow(true);
                  }, delay * 1000)
                : undefined;

        return () => window.clearTimeout(timeoutTimer);
    }, [enabled, isDone, slowAfter, isSlow, timeout]);

    return {
        data,
        duration,
        isDone,
        isSlow: !isDone && isSlow,
        isLoading: enabled && !isDone,
        error: isDone ? error.current : undefined,
        timedOut: timedOut.current,
        enabled
    };
};
