import { type WithRequired } from 'bb/common/types';
import { type FilteredPathKey, type MutationMethods } from '../../types';
import { useMutation } from './useMutation.hook';
import {
    type GetDetails,
    type UseMutationSchemeData,
    type UseMutationOptions
} from './useMutation.types';

/**
 * @param method 'post' | 'put' | 'delete'
 * @param pathname pathname to the endpoint
 * @param defaultApiOptions default options passed to useMutation
 */
export function createUseMutationHook<
    PayloadOverride,
    ResponseOverride,
    Method extends MutationMethods = MutationMethods,
    Path extends FilteredPathKey<Method> = FilteredPathKey<Method>,
    Scheme extends UseMutationSchemeData<Method, Path> = UseMutationSchemeData<
        Method,
        Path
    >,
    Payload = PayloadOverride extends object
        ? PayloadOverride
        : GetDetails<Method, Path, 'body'>,
    Response = ResponseOverride extends object
        ? ResponseOverride
        : GetDetails<Method, Path, 'response'>,
    PathQueryParams = Scheme extends { parameters: infer Params }
        ? Params extends { path: infer PathParams }
            ? PathParams
            : 'no-params'
        : 'no-params',
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    Params = PathQueryParams extends 'no-params' ? {} : PathQueryParams,
    Options extends UseMutationOptions<
        Payload,
        Response,
        Params
    > = UseMutationOptions<Payload, Response, Params>
>(method: Method, pathname: Path, defaultOptions?: Options) {
    return (
        ...args: PathQueryParams extends object
            ? [options: WithRequired<Options, 'query'>]
            : [options?: Options]
    ) =>
        useMutation<Payload, Response, Params, 'generic'>(method, pathname, {
            ...defaultOptions,
            ...args[0]
        });
}
