import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const PlayIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M17 10 4 17.5v-15z" fillRule="evenodd" />
        </svg>
    </Icon>
);
