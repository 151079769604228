// @ts-strict-ignore
import React, { useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Debug } from 'bb/app/debug';
import { type FCChildrenOnly } from 'bb/common/types';

const debug = Debug('idempotency');

type IdempotencyContextType = {
    getIdempotencyKey: () => string;
    clearIdempotencyKey: () => void;
};

export const IdempotencyContext =
    React.createContext<IdempotencyContextType>(null);

export const IdempotencyProvider: FCChildrenOnly = ({ children }) => {
    const key = useRef('');

    const getIdempotencyKey = (): string => {
        debug.info('getIdempotencyKey');
        const newKey = key.current || uuidv4();
        if (!key.current) {
            debug.info('getIdempotencyKey: create new key');
            key.current = newKey;
        }
        return newKey;
    };

    const clearIdempotencyKey = (): void => {
        debug.info('clearIdempotencyKey');
        key.current = '';
    };

    const idempotencyContext = useMemo(
        () => ({
            clearIdempotencyKey,
            getIdempotencyKey
        }),
        []
    );

    return (
        <IdempotencyContext.Provider value={idempotencyContext}>
            {children}
        </IdempotencyContext.Provider>
    );
};

export const useIdempotencyKey = (): IdempotencyContextType => {
    const idempotency = React.useContext(IdempotencyContext);
    if (typeof idempotency === 'undefined') {
        throw new Error(
            'useIdempotencyKey must be used within a IdempotencyProvider'
        );
    }
    return idempotency;
};
