import { useCallback } from 'react';
import { useRouter } from 'bb/app/router';
import { isServer } from 'bb/utils';

/**
 * Provides helpers for managing modal state
 * based on a given key/value couple in the
 * query. When using this hook the state
 * always resides in the query params.
 */
export const useModalQueryState = (
    key: string,
    value: string | string[],
    method: 'push' | 'replace' = 'replace'
): [boolean, () => void, () => void] => {
    const { router } = useRouter();

    const handleOpen = useCallback(() => {
        router[method](
            {
                pathname: router.pathname,
                query: { ...router.query, [key]: value }
            },
            undefined,
            { shallow: true }
        );
    }, [key, router, value, method]);

    const handleClose = useCallback(() => {
        const { [key]: _, ...query } = router.query;

        router[method](
            {
                pathname: router.pathname,
                query
            },
            undefined,
            { shallow: true }
        );
    }, [key, router, method]);

    const getOpenState = () => {
        if (isServer()) return false;

        const routerQueryValue = router.query[key];
        return Array.isArray(value)
            ? value.some((v) => v === routerQueryValue)
            : router.query[key] === value;
    };

    const open = getOpenState();

    return [open, handleClose, handleOpen];
};
