import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const FilterIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M12.884 6.933c.367 0 .697-.09.991-.267.295-.178.53-.415.709-.712l.096-.182.042-.106h3.325V4.283l-3.321.001-.06-.145a2.082 2.082 0 0 0-.79-.868A1.859 1.859 0 0 0 12.883 3c-.356 0-.683.09-.983.27-.3.181-.538.42-.713.717l-.094.182-.044.115H2v1.382h9.052l.042.106a1.99 1.99 0 0 0 .807.894c.3.178.627.267.983.267Zm0-1.017a.908.908 0 0 1-.667-.275.918.918 0 0 1-.275-.675c0-.266.092-.49.275-.67a.915.915 0 0 1 .667-.271c.272 0 .498.09.679.27.18.181.27.405.27.671 0 .267-.09.492-.27.675a.914.914 0 0 1-.68.275Zm-5.657 5.882c.355 0 .682-.087.979-.262a2 2 0 0 0 .712-.708L9 10.676l.06-.144h8.987V9.149H9.061L9 9.004a2.035 2.035 0 0 0-.794-.864 1.872 1.872 0 0 0-.98-.266 1.88 1.88 0 0 0-.99.267 2.082 2.082 0 0 0-.713.712l-.083.151-.063.145H2.006v1.383h3.371l.063.144a2.046 2.046 0 0 0 .795.86c.295.175.625.262.992.262Zm0-1.016a.918.918 0 0 1-.675-.275.908.908 0 0 1-.275-.667c0-.266.092-.491.275-.675a.918.918 0 0 1 .675-.275c.266 0 .49.092.67.275.18.184.271.409.271.675 0 .261-.09.484-.27.667a.904.904 0 0 1-.671.275Zm5.657 5.882c.367 0 .697-.088.991-.263a2 2 0 0 0 .709-.712l.096-.183.042-.108h3.326v-1.392h-3.322l-.044-.113a1.967 1.967 0 0 0-.803-.89 1.926 1.926 0 0 0-.995-.263 1.957 1.957 0 0 0-1.695.97l-.095.183-.044.114H2v1.39h9.05l.043.109a1.947 1.947 0 0 0 1.79 1.158Zm0-1.017a.908.908 0 0 1-.667-.274.908.908 0 0 1-.275-.667c0-.267.092-.492.275-.675a.908.908 0 0 1 .667-.275c.272 0 .498.092.679.275.18.183.27.408.27.675 0 .261-.09.483-.27.667a.914.914 0 0 1-.68.274Z"
                fillRule="nonzero"
            />
        </svg>
    </Icon>
);
