import React from 'react';
import classNames from 'classnames';
import { Typography, type TypographyProps } from 'bb/ui/Typography';
import css from './label.module.scss';

export type LabelProps = TypographyProps &
    React.ComponentPropsWithoutRef<'label'>;

export const Label = (props: LabelProps) => {
    const { children, className, ...restProps } = props;

    return (
        <Typography
            block
            as="label"
            variant="label"
            className={classNames(css.root, className)}
            {...restProps}
        >
            {children}
        </Typography>
    );
};
