import { useState, useEffect } from 'react';
import { type Breakpoint } from 'bb/ui';

const breakpoints: Record<Breakpoint, string> = {
    xxs: '0px',
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px'
};

export const useIsScreenSize = (breakpoint: Breakpoint) => {
    const [matches, setMatches] = useState<boolean | null>(null);

    useEffect(() => {
        const media = window?.matchMedia(
            `(min-width:${breakpoints[breakpoint]})`
        );

        if (Boolean(media?.matches) !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        if (media.addEventListener) {
            media.addEventListener('change', listener);
        }
        return () => {
            if (media.removeEventListener) {
                media.removeEventListener('change', listener);
            }
        };
    }, [matches, breakpoint]);

    return matches;
};
