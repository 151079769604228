/* eslint-disable max-depth */
import {
    useCallback,
    useEffect,
    useRef,
    useState,
    type SetStateAction
} from 'react';
import {
    useOutsideClick,
    useEventListener,
    getFocusableElements,
    useLockBodyScroll
} from 'bb/ui/hooks';
import { useFocusTrap } from 'bb/ui/hooks/useFocusTrap';
import { sleep } from 'bb/utils/sleep';
import { type ModalEntityName } from './store';
import 'wicg-inert';

export type UseHeadlessModalProps = {
    /**
     * Whether the modal is open or not.
     *
     * @default false
     */
    isOpen?: boolean;
    /**
     * Callback to close the modal.
     *
     * Will wait for `closeTimeoutMS` before
     * the modal is actually closed.
     */
    onRequestClose?: () => void;
    /**
     * Callback to be called after the modal
     * is actually closed.
     */
    onAfterClose?: () => void;
    /**
     * Milliseconds to wait before the modal
     * is actually closed.
     *
     * Useful for when an animation needs to be
     * run before unmount.
     *
     * @default 0
     */
    closeTimeoutMS?: number;
    /**
     * Function to get the scrollable element.
     * If not provided, the modal element will be
     * used.
     */
    getScrollableElement?: () => HTMLElement | null;

    /**
     * Whether the modal should close when the overlay is clicked.
     *
     * @default true
     */
    shouldCloseOnOverlayClick?: boolean;
    /**
     * Whether the modal should close when the escape key is pressed.
     * @default true
     */
    shouldCloseOnEsc?: boolean;
    /**
     * The name of the modal that is associated with a control
     * that is returned by `useModalStore`.
     */
    entityName?: ModalEntityName;
    /**
     * Keeps the children mounted when the modal is closed.
     *
     * @default false
     */
    persistChildrenWhenClosed?: boolean;
    /**
     * Places focus on the first interactive element.
     *
     * @default true
     */
    shouldFocusFirstElement?: boolean;
};

export type UseHeadlessModalReturnProps<
    THTMLElement extends HTMLElement = HTMLDivElement
> = {
    /**
     * Props to be spread on the modal element.
     */
    getModalProps: () => {
        role: string;
        'aria-haspopup': 'dialog';
        'aria-modal': boolean;
        ref: React.Dispatch<SetStateAction<THTMLElement | null>>;
    };
    /**
     * Callback to close the modal. Favor this over
     * calling `onRequestClose` directly. Doing the
     * latter will not wait for `closeTimeoutMS`.
     */
    handleClose: () => Promise<void>;
    /**
     * Whether the modal is active or not.
     *
     * This is set to `true` after the modal is opened
     * and set to `false` after the modal is closed.
     */
    isActive: boolean;
    /**
     * Whether the modal is closing or not.
     */
    isClosing: boolean;
    /**
     * Current isOpen state.
     */
    isOpen: boolean;
    /**
     * Reference to the modal element.
     *
     * Needs to be assigned for internal eventListeners
     * to work properly. This is done automatically by
     * the `getModalProps` function.
     *
     * @default null
     */
    modalElement: THTMLElement | null;
    /**
     * Setter for the modal element.
     */
    setModalElement: React.Dispatch<SetStateAction<THTMLElement | null>>;
};
export const useHeadlessModal = <
    THTMLElement extends HTMLElement = HTMLDivElement
>(
    props: UseHeadlessModalProps
) => {
    const {
        isOpen = false,
        onRequestClose,
        onAfterClose,
        closeTimeoutMS = 0,
        getScrollableElement,
        shouldCloseOnOverlayClick = true,
        shouldCloseOnEsc = true,
        entityName,
        persistChildrenWhenClosed,
        shouldFocusFirstElement = false
    } = props;
    /**
     * State
     */
    const [isClosing, setIsClosing] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [modalElement, setModalElement] = useState<THTMLElement | null>(null);

    const scrollableElement = getScrollableElement?.() ?? modalElement;

    /**
     * Refs
     */
    const lastFocusedElement = useRef<HTMLElement | null>(null);

    const isActiveActual =
        (persistChildrenWhenClosed ? isOpen : isActive) && !isClosing;

    const handleClose = useCallback(async () => {
        if (onRequestClose || onAfterClose) {
            /**
             * Run the initial onRequestClose callback if provided.
             */
            onRequestClose?.();

            /**
             * Wait for the closeTimeoutMS before actually closing.
             */
            if (closeTimeoutMS) {
                setIsClosing(true);
            }
            await sleep(closeTimeoutMS);
            setIsClosing(false);
            /**
             * Run the onAfterClose callback if provided.
             */
            onAfterClose?.();

            /**
             * Focus the last focused element if it exists.
             *
             * For some reason a small timeout is needed here to make
             * it work consistently. Might be due to inert being set
             * on everything but the modal element and a race condition
             * with the focus event.
             */

            setTimeout(() => {
                lastFocusedElement.current?.focus();
            }, 50);
        }
    }, [onRequestClose, onAfterClose, closeTimeoutMS]);

    useOutsideClick(
        modalElement,
        () => {
            handleClose();
        },
        shouldCloseOnOverlayClick && isOpen,
        'mouseup'
    );
    useLockBodyScroll(isOpen, scrollableElement);

    useEventListener('keydown', (event) => {
        if (event.key === 'Escape' && shouldCloseOnEsc && isOpen) {
            handleClose();
        }
    });

    useEffect(() => {
        if (!modalElement) return;

        if (isOpen) {
            /**
             * Save the last focused element so we can re-focus it
             * after the modal is closed.
             */
            lastFocusedElement.current = document.activeElement as HTMLElement;

            if (shouldFocusFirstElement) {
                /**
                 * Focus the first focusable element in the modal once opened.
                 */
                const { firstElement, firstPreferedElement } =
                    getFocusableElements(modalElement);

                (firstPreferedElement ?? firstElement)?.focus();
            }
        }
    }, [isOpen, modalElement, shouldFocusFirstElement]);

    useEffect(() => {
        if (!modalElement) return;

        if (isOpen) {
            /**
             * If the modal is open we remove the inert attribute so it can be interacted with.
             */
            modalElement.removeAttribute('inert');
        } else {
            /**
             * If the modal is closed we add the inert attribute to make sure
             * the modal is not interactable even if mounted.
             */
            modalElement.setAttribute('inert', 'true');
        }
    }, [isOpen, modalElement]);

    /**
     * This needs to run after we set `lastFocusedElement.current` because
     * otherwise the inert attribute will be set on the last focused element
     * and we will lose it.
     */
    useFocusTrap(modalElement, isOpen);

    useEffect(() => {
        /**
         * This is a workaround to make sure the modal isActive
         * boolean is set correctly when the modal is opened.
         *
         * This is needed so we can add animations. For that
         * to work the isActive boolean needs to be set after
         * the modal is opened.
         */
        setTimeout(() => setIsActive(isOpen), 0);
    }, [isOpen]);

    const getModalProps = () => ({
        role: 'dialog',
        'aria-haspopup': 'dialog',
        'aria-modal': true,
        'aria-hidden': !isActiveActual,
        'data-isopen': isActiveActual,
        ref: setModalElement,
        id: entityName
    });

    return {
        getModalProps,
        handleClose,
        isActive: isActiveActual,
        isClosing,
        isOpen,
        modalElement,
        setModalElement
    } as UseHeadlessModalReturnProps<THTMLElement>;
};
