import { useApi, type UseApiOptions } from 'bb/api/browser/useApi';
import { type NextApi } from 'bb/api/types';
import { useIsLoggedIn } from './useVerifyLoggedIn';

export const useAccount = (
    config?: UseApiOptions<NextApi<'/api/my/account', 'get'>['response']>
) => {
    const res = useApi('/api/my/account', {
        ...config,
        enabled: useIsLoggedIn() && (config?.enabled ?? true)
    });

    return Object.assign(res, { account: res.data });
};
