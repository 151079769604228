import React, { type CSSProperties } from 'react';
import classnames from 'classnames';
import { CloseButton } from 'bb/common/components/buttons/CloseButton';
import { Text } from 'bb/i18n/Text';
import { Gap, type GapProps } from '../Gap';
import { BaseModal, type BaseModalProps } from './BaseModal';
import css from './modal.module.scss';
import { createModalPortal } from './utils';

export const MODAL_ANIMATION_DURATION = 200; // ms

export type ModalProps = {
    header?: string;
    onClose?: () => void;
    closeLabel?: string;
    showCloseButton?: boolean;
    shouldCloseOnEsc?: boolean;
    shouldCloseOnOverlayClick?: boolean;
} & Pick<BaseModalProps, 'isOpen' | 'children' | 'className' | 'boxProps'> &
    Pick<GapProps, 'justifyContent'>;

export const Modal = (props: ModalProps) => {
    const {
        children,
        isOpen,
        onClose,
        header,
        boxProps = {},
        showCloseButton = true,
        justifyContent = 'spaceBetween',
        ...restProps
    } = props;

    const { padding = { xxs: [6, 4], sm: 6 } } = boxProps;

    return createModalPortal(
        <BaseModal
            {...restProps}
            closeTimeoutMS={MODAL_ANIMATION_DURATION}
            isOpen={isOpen}
            onAfterClose={onClose}
            boxProps={{
                ...boxProps,
                padding,
                className: classnames(css.box, boxProps.className),
                style: {
                    '--modal-animation-duration': `${MODAL_ANIMATION_DURATION}ms`
                } as CSSProperties
            }}
        >
            {(ctx) => {
                const { handleClose } = ctx;

                return (
                    <Gap spacing={3}>
                        <Gap
                            alignItems="center"
                            justifyContent={justifyContent}
                            direction="row"
                            spacing={4}
                        >
                            {header && (
                                <Text variant="h6Secondary">{header}</Text>
                            )}

                            {showCloseButton && onClose && (
                                <CloseButton onClick={handleClose} />
                            )}
                        </Gap>

                        {typeof children === 'function'
                            ? children(ctx)
                            : children}
                    </Gap>
                );
            }}
        </BaseModal>
    );
};
