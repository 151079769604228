import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef
} from 'react';
import { SWRConfig, mutate } from 'swr';
import { FIXED_OPTIONS } from './swr';

/**
 * Used to optionally keep track of queries that are rendered at the page.
 * Provides a more ergonomic way to revalidate all active queries due to
 * an update or similar.
 * Mainly useful for personal data that need to be refetched after some
 * mutation, i.e. on account pages.
 */

const liveQueries = new Set<string>();
const LiveQueriesContext = React.createContext<null | Set<string>>(null);

type RuleSet = {
    // don't run the operation for the keys in this list
    except?: (string | undefined | null)[];
    // only run the operation for keys in this list
    only?: (string | undefined | null)[];
};

type RevalidationOptions = RuleSet & {
    // if true it will clear the cache of all queries
    // can also be passed the RuleSet for further control
    clearCache?: boolean | RuleSet;
};

// compare key against rule
// 1. skip if in `except` list
// 2. passes if `only` list is empty OR `only` list has key in it
const passesRules = (key: string, { except = [], only = [] }: RuleSet) =>
    !except.includes(key) && (!only.length || only.includes(key));

const invalidate = (key: string, clear: boolean | RuleSet) => {
    if (typeof clear === 'boolean')
        return clear ? mutate(key, undefined) : mutate(key);

    return passesRules(key, clear) ? mutate(key, undefined) : mutate(key);
};

const invalidateQueries = ({
    clearCache = false,
    ...rules
}: RevalidationOptions) => {
    // use direct access to liveQueries here to be able to revalidate
    // queries from outside of LiveQueriesProvider
    liveQueries.forEach((key) => {
        if (passesRules(key, rules)) invalidate(key, clearCache);
    });
};

// queries happening below this provider can be saved by passing
// `addAsLiveQuery` to useApi options
export const LiveQueriesProvider: React.FC<
    React.PropsWithChildren<{
        // will invalidate saved queries when this provider unmounts if set to `true`.
        // can also be a rule set to conditionally invalidate some queries
        invalidateOnUnMount?: boolean | RevalidationOptions;
        // optionally provide an (extra) global SWR config for all SWR hooks rendered
        // in any children of this provider
        swrConfig?: React.ComponentProps<typeof SWRConfig>['value'] & {
            fixed?: boolean;
        };
    }>
> = ({
    children,
    invalidateOnUnMount = false,
    swrConfig: { fixed, ...config } = {}
}) => {
    const optionsRef = useRef(invalidateOnUnMount);

    optionsRef.current = invalidateOnUnMount;

    useEffect(
        () => () => {
            const options = optionsRef.current;
            if (options)
                invalidateQueries(typeof options === 'boolean' ? {} : options);
            liveQueries.clear();
        },
        []
    );

    return (
        <SWRConfig value={Object.assign(config, fixed ? FIXED_OPTIONS : {})}>
            <LiveQueriesContext.Provider value={liveQueries}>
                {children}
            </LiveQueriesContext.Provider>
        </SWRConfig>
    );
};

export const useLiveQueries = (key?: string | null) => {
    const queries = useContext(LiveQueriesContext);

    useEffect(() => {
        if (key) queries?.add(key);
    }, [key, queries]);

    // this method must be used instead of a provided `key` when the
    // consumer is rendered outside of the LiveQueriesProvider
    // - uses direct access to `liveQueries` instead of `queries`
    const addQuery = useCallback(
        (k?: string | null) => (k ? liveQueries.add(k) : undefined),
        []
    );

    const revalidateAll = useCallback(
        (options: RevalidationOptions = {}) => invalidateQueries(options),
        []
    );

    return useMemo(
        () => ({ addQuery, queries: liveQueries, revalidateAll }),
        [addQuery, revalidateAll]
    );
};
