import React from 'react';
import { RedeemGiftCardIcon } from 'bb/ui/Icons/svgs';
import { Input } from '../Input';
import { type InputProps } from '../Input.types';

export type GiftcardInputProps = Omit<InputProps, 'startAdornment'>;

export const GiftcardInput = React.forwardRef<
    HTMLInputElement,
    GiftcardInputProps
>((props, ref) => (
    <Input
        startAdornment={<RedeemGiftCardIcon size="small" />}
        ref={ref}
        {...props}
    />
));
