import { useTrackingStrategy } from './useTrackingStrategy';

type TrackSignupCompletePayload = {
    subscriptionType: string;
    value: string;
    currency: string;
    email?: string;
    campaignCode?: string;
};

type SelectSubscriptionPayload = {
    subscriptionType: string;
    value: string;
    currency: string;
};

export const usePartnerRegistrationTracker = () => {
    const { track } = useTrackingStrategy();

    return {
        setPartner: (partner: string) =>
            track({
                event: 'set_partner',
                partner
            }),
        setUid: (uid: string) =>
            track({
                event: 'uid_set',
                uid
            }),
        landingPageCtaClick: (button: string) =>
            track({
                event: 'cta_click',
                button
            }),
        verificationSubmit: (connectionType: string) =>
            track({
                event: 'verify_connection',
                connectionType
            }),
        verificationCompleted: () =>
            track({
                event: 'verification_completed'
            }),
        userDetailsFormInteraction: () =>
            track({
                event: 'start_filling_last_name'
            }),
        accountCreated: (uid: string) =>
            track({
                event: 'account_created',
                uid
            }),
        selectSubscription: (payload: SelectSubscriptionPayload) =>
            track({
                event: 'select_subscription_type',
                ...payload
            }),
        signupComplete: (payload: TrackSignupCompletePayload) =>
            track({
                event: 'signup_process_completed',
                ...payload
            })
    };
};

export type PartnerRegistrationTracker = ReturnType<
    typeof usePartnerRegistrationTracker
>;
