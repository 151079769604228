// @ts-strict-ignore
import { useCallback, useReducer } from 'react';
import { Debug } from 'bb/app/debug';
import { put } from './api';
import { createReducer } from './apiReducer';
import { handleError } from './handleError';
import { EXECUTING, SUCCESS, ERROR, IDLE } from './symbols';
import { type RequestReturnType, type ExecuteFunction } from './types';

const debug = Debug('usePut');

export function usePut<Response, Payload>(
    url: string
): RequestReturnType<Response, Payload> {
    const [state, dispatch] = useReducer(createReducer<Response>(), {
        result: null,
        error: null,
        status: IDLE
    });

    const putData: ExecuteFunction<Payload> = useCallback(
        async (payload: Payload): Promise<void> => {
            debug.info(`Putting ${url}`);
            dispatch({ type: EXECUTING });

            try {
                const { data } = await put<Response>(
                    url,
                    payload as Record<string, unknown>
                );

                debug.info(data);
                dispatch({ type: SUCCESS, result: data });
                return Promise.resolve();
            } catch (err) {
                const error = handleError(err);
                dispatch({ type: ERROR, error });
                return Promise.reject();
            }
        },
        [url]
    );

    return { ...state, execute: putData };
}
