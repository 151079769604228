/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { useStableCallback } from './useStableCallback';

/**
 * This custom hook lets you pass a callback that will be "spyed" on.
 * Its args and its return can be subscribed to with the interceptors.
 * Returns a new callback at the first index.
 *
 * Example usage:
 *
 * const sayHello = (greeting: string) => greeting;
 *
 * const [sayHelloWithInterceptor, sayHelloArgs, sayHelloReturn] = useCallbackInterceptor(
 *    sayHello,
 *    {
 *        onSuccess: (res) => {
 *            // Do something with response.
 *        },
 *        onTrigger: (...args) => {
 *            // Do something with args.
 *        }
 *    }
 * );
 */
export function useCallbackInterceptor<
    Callback extends (...args: any[]) => any,
    Params extends Parameters<Callback> = Parameters<Callback>,
    Return extends Awaited<ReturnType<Callback>> = Awaited<ReturnType<Callback>>
>(
    callback: Callback,
    interceptors?: {
        onTrigger?: (...args: Params) => any;
        onSuccess?: (res: Return) => any;
    }
) {
    const { onTrigger, onSuccess } = interceptors ?? {};

    const argsRef = useRef<Params>();
    const returnRef = useRef<Return>();

    return [
        useStableCallback((...args: Params) => {
            onTrigger?.(...args);
            argsRef.current = args;

            const cb = (res: Return) => {
                onSuccess?.(res);
                returnRef.current = res;

                return res;
            };

            if (callback.constructor.name === 'AsyncFunction') {
                return callback(...args).then(cb);
            }

            return cb(callback(...args));
        }),
        /**
         * If needed the args/return are also available without
         * the need of using the interceptors.
         */
        argsRef.current,
        returnRef.current
    ] as const;
}
