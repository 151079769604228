import React, {
    type HtmlHTMLAttributes,
    type CSSProperties,
    type FunctionComponent,
    type ReactElement
} from 'react';
import { Box } from '../Box';
import { Row } from '../Flex';
import { type PaddingSize } from '../Padding';

export type CardHeaderProps = {
    left?: ReactElement;
    right?: ReactElement;
    style?: CSSProperties;
    padding?: PaddingSize;
} & HtmlHTMLAttributes<HTMLDivElement>;

export const CardHeader: FunctionComponent<CardHeaderProps> = ({
    left,
    right,
    style,
    padding,
    ...rest
}) => (
    <Box {...rest} paddingBottom={padding} aria-hidden="true">
        <Row style={style} justifyContent="spaceBetween" alignItems="stretch">
            <div>{left}</div>
            <div>{right}</div>
        </Row>
    </Box>
);
