import React from 'react';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import { type Router } from 'next/router';
import { useApp } from 'bb/app/AppContext';
import { useRouter } from 'bb/app/router';
import { type Routes, type Route as TypedRoute } from 'bb/app/router/config';
import { type Route } from 'bb/app/router/types';
import { useGetRoutesFromRemappedRoutes } from 'bb/app/router/useGetRoutesFromRemappedRoutes';
import { usePersistQueryParams } from 'bb/app/router/usePersistQueryParams';
import { getUrl } from 'bb/app/router/utils';
import {
    DeprecatedButton,
    type ButtonProps,
    type PolymorphicComponent,
    type ButtonVariant
} from 'bb/ui';

/**
 * @deprecated use `LinkButtonProps` from `bb/common/LinkButton/LinkButton.tsx`
 * along with importing that component.
 */
export type LinkButtonProps<TButtonVariant extends ButtonVariant = 'primary'> =
    PolymorphicComponent<
        'a',
        {
            route: Route;
            query?: Router['query'];
            reload?: boolean;
            customMarket?: string;
        } & ButtonProps<TButtonVariant, 'a'> &
            Pick<NextLinkProps, 'prefetch'>
    >;

/**
 * @deprecated Use `LinkButton` instead.
 */
export const DeprecatedLinkButton = <TButtonVariant extends ButtonVariant>({
    route: originalRoute,
    query,
    variant,
    children,
    reload,
    customMarket,
    prefetch = false,
    ...restProps
}: Omit<LinkButtonProps<TButtonVariant>, 'type'>) => {
    const { routes } = useRouter();
    const getRouteFromRemappedRoutes = useGetRoutesFromRemappedRoutes();
    const { make } = usePersistQueryParams();
    const { market } = useApp();
    const route =
        routes[
            getRouteFromRemappedRoutes(originalRoute as Routes[TypedRoute])
        ] ?? originalRoute;

    return (
        <NextLink
            prefetch={prefetch}
            passHref
            href={{
                pathname: route.href,
                query: { ...query, ...make(), market: customMarket ?? market }
            }}
            locale={false}
            legacyBehavior
        >
            <DeprecatedButton
                as="a"
                variant={variant ?? 'primary'}
                onClick={
                    reload
                        ? () => {
                              window.location.href = `${window.location.origin}${getUrl(route.href, { ...query, market: customMarket ?? market })}`;
                          }
                        : undefined
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restProps}
            >
                {children}
            </DeprecatedButton>
        </NextLink>
    );
};
