import { createUseApiHook } from 'bb/api/browser/useApi/createUseApiHook';

type GiftCardDetailsResponse = {
    validmonths: number;
    validdays: number;
    code?: string;
    subscriptiontype?: string;
    productname?: string;
};

export const useGetGiftCardDetails = createUseApiHook<GiftCardDetailsResponse>(
    '/api/giftcards/{code}'
);
