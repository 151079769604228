import React, { type FunctionComponent } from 'react';
import { useTranslation } from 'bb/i18n/translationUtils';
import { isDef } from 'bb/utils/assert';
import { Amount } from './Amount';

export const AmountPerMonth: FunctionComponent<{
    amount?: number;
}> = ({ amount }) => {
    const { t } = useTranslation('common');

    if (!isDef(amount)) return null;

    return (
        <>
            <Amount amount={amount} />
            <span>{t('common:perMonth')}</span>
        </>
    );
};
