import { type ForwardedRef } from 'react';
import { assignRef } from 'bb/utils/assignRef';

/**
 * Assigns a value to multiple refs.
 *
 * @example
 * ```tsx
 * const ref1 = useRef<HTMLDivElement>(null);
 * const ref2 = useRef<HTMLDivElement>(null);
 *
 * const rendered = <div ref={assignRefs(ref1, ref2)} />;
 * ```
 */
export function assignRefs<TValue>(
    ...refs: (ForwardedRef<TValue> | undefined)[]
) {
    return (value: TValue) => {
        refs.forEach((ref) => assignRef(ref, value));
    };
}
