import paddingCss from './padding.module.scss';
import paddingBottomCss from './paddingBottom.module.scss';
import paddingLeftCss from './paddingLeft.module.scss';
import paddingRightCss from './paddingRight.module.scss';
import paddingTopCss from './paddingTop.module.scss';

export {
    paddingLeftCss,
    paddingRightCss,
    paddingTopCss,
    paddingBottomCss,
    paddingCss
};
