import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useStableCallback } from 'bb/common/hooks/useStableCallback';
import { type UnionOf } from 'bb/common/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { useEventListener } from '../hooks/useEventListener';
import {
    SimpleNotification,
    type SimpleNotificationProps,
    VARIANT_PROPS_MAP
} from '../SimpleNotification';
import css from './toast.module.scss';

export const TOAST_POSITIONS = [
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right'
] as const;

export const DEFAULT_TOAST_POSITION = 'top-right';

export type ToastPosition = UnionOf<typeof TOAST_POSITIONS>;

export type ToastProps = {
    isVisible?: boolean;
    /**
     * Duration in milliseconds.
     */
    closeAfterMs?: number;
    /**
     * Duration in milliseconds.
     */
    animationMs?: number;
    /**
     * The position on the screen to render the toast.
     */
    position?: ToastPosition;
} & SimpleNotificationProps;

/**
 * Simple leightweight Toast-component that composes <SimpleNotification />
 * and adds some CSS animations. Best used with the toast utility function
 * but can also be used with a regular useState.
 */
export const Toast = ({
    animationMs = 250,
    closeAfterMs,
    isVisible: passedIsVisible = false,
    onClose: passedOnClose,
    variant,
    position = DEFAULT_TOAST_POSITION,
    ...restProps
}: ToastProps) => {
    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const progressBarRef = useRef<HTMLDivElement | null>(null);

    const timeStartedRef = useRef<number>(Date.now());
    const timeElapsedRef = useRef<number>(0);
    const hasFocusRef = useRef(false);

    const [isVisible, setIsVisible] = useState(false);

    const stableOnClose = useStableCallback(passedOnClose);

    /**
     * Since the Toast (usually) unmounts on onClose
     * we need to wait for the animation to finish
     * before actually calling the passed function.
     */
    const onClose = useCallback(() => {
        setIsVisible(false);
        setTimeout(stableOnClose, animationMs);
        clearTimeout(timerRef.current);
    }, [stableOnClose, animationMs]);

    useEventListener('keydown', (event) => {
        /**
         * Close the Toast if the element has focus and 'Escape' is clicked.
         */
        if (hasFocusRef.current && (event as KeyboardEvent).key === 'Escape')
            onClose();
    });

    const pauseCloseAfterMs = () => {
        if (closeAfterMs == null) return;

        const msElapsed = Date.now() - timeStartedRef.current;
        timeElapsedRef.current += msElapsed;

        if (closeAfterMs >= timeElapsedRef.current) {
            progressBarRef.current?.classList.add(css.pauseAnimation ?? '');
            clearTimeout(timerRef.current);
        }
    };
    const resumeCloseAfterMs = () => {
        if (closeAfterMs == null) return;

        timeStartedRef.current = Date.now();
        timerRef.current = setTimeout(
            onClose,
            closeAfterMs - timeElapsedRef.current
        );
        progressBarRef.current?.classList.remove(css.pauseAnimation ?? '');
    };

    useEffect(() => {
        setIsVisible(passedIsVisible);
    }, [passedIsVisible]);

    useEffect(() => {
        if (closeAfterMs && isVisible) {
            timerRef.current = setTimeout(onClose, closeAfterMs);
        }

        return () => clearTimeout(timerRef.current);
    }, [closeAfterMs, isVisible, onClose]);

    const [, contrastBackgroundColor] = VARIANT_PROPS_MAP[variant];
    const isRenderedInTop = ['top-left', 'top-right'].includes(position);

    return (
        <SimpleNotification
            /**
             * Make element tabbable for a11y reasons.
             */
            tabIndex={0}
            /**
             * Pause animation when we hover the widget in order to
             * give the user time to read the message if needed.
             */
            onMouseEnter={pauseCloseAfterMs}
            onFocus={() => {
                hasFocusRef.current = true;
                pauseCloseAfterMs();
            }}
            /**
             * Resume when the mouse leaves the widget.
             */
            onMouseLeave={resumeCloseAfterMs}
            onBlur={() => {
                hasFocusRef.current = false;
                resumeCloseAfterMs();
            }}
            /**
             * Use CSS-variable in order to keep JS and CSS in sync
             * and to make the animationTime customizable.
             */
            style={
                {
                    '--animationTime': `${animationMs}ms`,
                    '--durationTime': `${closeAfterMs}ms`,
                    '--transformOrigin': isRenderedInTop
                        ? 'translateY(-100%)'
                        : 'translateY(100%)'

                    /**
                     * This has to be casted since the typing doesn't
                     * allow custom CSS variables.
                     */
                } as React.CSSProperties
            }
            className={classNames(css.root, isVisible && css.isVisible)}
            onClose={onClose}
            variant={variant}
            {...restProps}
        >
            {Boolean(closeAfterMs) && (
                <div
                    ref={progressBarRef}
                    className={css.progress}
                    style={makeCSSVariableFromColor(
                        '--progress-background-color',
                        contrastBackgroundColor
                    )}
                />
            )}
        </SimpleNotification>
    );
};
