import React from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { type MarginProps, getMargins } from 'bb/ui/Margin';
import { type PolymorphicRef, type PolymorphicComponent } from 'bb/ui/types';
import { Typography, type TypographyProps } from 'bb/ui/Typography';
import { capitalize } from 'bb/utils/conversion';
import { isProductionEnvironment } from 'bb/utils/environment';
import { type TranslationProps } from './types';

export type HeaderType = 'primary' | 'secondary';
export type HeaderSize = 'small' | 'medium' | 'large';

export type TypographyMapKey = `${HeaderSize}${Capitalize<HeaderType>}`;

export const TYPOGRAPHY_MAP: Record<
    TypographyMapKey,
    TypographyProps['variant']
> = {
    largePrimary: 'h2Primary',
    largeSecondary: 'h2Secondary',
    mediumPrimary: 'h3Primary',
    mediumSecondary: 'h3Secondary',
    smallPrimary: 'h5Primary',
    smallSecondary: 'h5Secondary'
};

export const getTypographyVariant = (type: HeaderType, size: HeaderSize) =>
    TYPOGRAPHY_MAP[`${size}${capitalize(type)}` as TypographyMapKey];

export type HeaderProps<TElementType extends React.ElementType = 'h1'> =
    PolymorphicComponent<
        TElementType,
        TranslationProps & {
            /**
             * @deprecated Use the variant prop instead
             */
            size?: HeaderSize;
            /**
             * @deprecated Use the variant prop instead
             */
            type?: HeaderType;
            ariaLevel?: number;
            testId?: string;
        } & TypographyProps<TElementType> &
            MarginProps
    >;

/**
 * Acts as a wrapper for Typography to provide translations
 *
 * @deprecated Use Text instead.
 */
const HeaderComponent = (
    {
        as = 'h1',
        size = 'large',
        type = 'primary',
        center = false,
        upperCase = false,
        margin,
        marginLeft,
        marginRight,
        marginBottom,
        marginTop,
        className,
        ariaLevel,
        testId,
        t,
        values,
        children,
        variant: passedVariant,
        ...restProps
    }: HeaderProps<'h1'>,
    ref?: PolymorphicRef<'h1'>
) => {
    const variant = passedVariant ?? getTypographyVariant(type, size);

    return (
        <Typography
            {...restProps}
            {...(!isProductionEnvironment()
                ? { 'data-translation-key': t }
                : {})}
            ref={ref}
            as={as}
            upperCase={upperCase}
            center={center}
            variant={variant}
            data-testid={testId}
            aria-level={ariaLevel}
            className={classNames(
                className,
                getMargins({
                    margin,
                    marginLeft,
                    marginRight,
                    marginBottom,
                    marginTop
                })
            )}
        >
            {t ? (
                <Trans i18nKey={t} values={values}>
                    {children}
                </Trans>
            ) : (
                children
            )}
        </Typography>
    );
};

/**
 * Acts as a wrapper for Typography to provide translations
 *
 * @deprecated Use Text instead.
 */
export const Header = React.forwardRef(HeaderComponent) as <
    T extends React.ElementType = 'h1'
>(
    props: HeaderProps<T>
) => JSX.Element;
