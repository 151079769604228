import { useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { debounce } from 'bb/utils';

export const widthAtom = atom(0);

export const useWindowWidth = () => {
    const [width, setWidth] = useAtom(widthAtom);

    useEffect(() => {
        const handleResize = debounce(() => {
            setWidth(window.innerWidth);
        }, 300);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [setWidth]);

    return width;
};
