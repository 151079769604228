import React from 'react';

export type TrackingContextType = {
    context: string;
};

export const TrackingContext = React.createContext<TrackingContextType>({
    context: ''
});

export const useTracking = () => React.useContext(TrackingContext);
