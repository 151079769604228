import React from 'react';
import { FeedbackIcon } from 'bb/ui/Icons/svgs';
import { Input } from '../Input';
import { type InputProps } from '../Input.types';

export type FeedbackInputProps = Omit<InputProps, 'startAdornment'>;

export const FeedbackInput = React.forwardRef<
    HTMLInputElement,
    FeedbackInputProps
>((props, ref) => (
    <Input
        startAdornment={<FeedbackIcon size="small" />}
        ref={ref}
        {...props}
    />
));
