/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { getFocusableElements } from 'bb/ui/hooks/useFocusTrap/utils';
import { unRef } from 'bb/utils/unRef';
import { type MaybeRef } from '../../types';
import { FocusTrap } from './focusTrap';

export const ONE_TRUST_COOKIE_CONSENT_MODAL_SELECTOR = '#onetrust-consent-sdk';

/**
 * When a stray element is present, it can be rendered on top of the modal
 * and we want to exclude it from being inert i.e. the 3DS verification modal.
 */
export const STRAY_ELEMENT_ROOT_SELECTOR = '#stray-element-root';

export const useFocusTrap = (
    target: MaybeRef<HTMLElement | null | undefined>,
    enabled = false
) => {
    useEffect(() => {
        const element = unRef(target);
        let instance: FocusTrap | null = null;

        if (element) {
            instance = new FocusTrap(element, {
                excludedElements: [
                    ONE_TRUST_COOKIE_CONSENT_MODAL_SELECTOR,
                    STRAY_ELEMENT_ROOT_SELECTOR
                ]
            });
        }

        if (enabled) {
            instance?.enable();
            if (element) {
                const { firstElement } = getFocusableElements(element);
                firstElement?.focus();
            }
        } else {
            instance?.disable();
        }

        return () => {
            instance?.disable();
        };
    }, [enabled, target]);

    return null;
};
