import React, { type FC } from 'react';
import { isDef } from 'bb/utils';

export type ExternalLinkProps = {
    label?: string | null;
} & React.ComponentProps<'a'>;

export const ExternalLink: FC<ExternalLinkProps> = ({
    label = '',
    children,
    target = '_blank',
    ...restProps
}) => (
    <a
        rel="noopener noreferrer"
        aria-label={isDef(label) ? label : ''}
        target={target}
        {...restProps}
    >
        {children}
    </a>
);
