import React, { useState } from 'react';
import { useTranslation } from 'bb/i18n';
import { EyeIcon, EyeCrossedIcon, PasswordIcon } from '../../../Icons';
import { Input } from '../Input';
import css from '../input.module.scss';
import { type InputProps } from '../Input.types';

export type PasswordInputProps = Omit<
    InputProps,
    'as' | 'endAdornment' | 'startAdornment'
>;

export const PasswordInput = React.forwardRef<
    HTMLInputElement,
    PasswordInputProps
>((props, ref) => {
    const { t } = useTranslation(['inputFields']);
    const {
        value,
        label = t('inputFields:labelPassword'),
        placeholder = t('inputFields:placeholderPassword'),
        type: initialType = 'password',
        ...restProps
    } = props;

    const [type, setType] = useState(initialType);

    return (
        <Input
            {...restProps}
            startAdornment={<PasswordIcon size="small" />}
            endAdornment={
                <button
                    className={css.toggleButton}
                    onClick={() =>
                        setType(type === 'password' ? 'text' : 'password')
                    }
                    type="button"
                    aria-label={
                        type === 'password'
                            ? t('inputFields:hidePassword')
                            : t('inputFields:showPassword')
                    }
                >
                    {type === 'password' ? (
                        <EyeIcon size="small" />
                    ) : (
                        <EyeCrossedIcon size="small" />
                    )}
                </button>
            }
            ref={ref}
            type={type}
            value={value}
            label={label}
            placeholder={placeholder}
            {...restProps}
        />
    );
});
