import { type AxiosError } from 'axios';
import { Debug } from 'bb/app/debug';
import { type ErrorResponse } from './types';

const debug = Debug('handleError');

export type AxiosErrorResponse = {
    message: string;
};
export const handleError = (
    err: AxiosError<AxiosErrorResponse, unknown> | undefined
): ErrorResponse | null => {
    if (!err) return null;
    const message =
        err?.message || err?.response?.data?.message || 'unknown_error';
    const status = err?.status || err?.response?.status || err?.code || 500;
    const data = err?.response?.data ? { ...err?.response?.data } : {};
    const error = {
        status,
        data: {
            message,
            ...data
        }
    };

    debug.warn(error.data.message);
    return error;
};

export const isErrorResponse = (
    err?: ErrorResponse | Error | null
): err is ErrorResponse => Boolean(err && 'status' in err);
