import { isAxiosError } from 'axios';
import { type ResourceKey } from 'i18next';
import HttpBackend from 'i18next-http-backend';
import queryString from 'query-string';
import { get } from 'bb/api/browser/api';

export const ClientHttpBackend = new HttpBackend(null, {
    async request(_options, filePath, _payload, callback) {
        try {
            const url = queryString.stringifyUrl({
                url: '/api/translations',
                /**
                 * The filePath matches the loadPath specified below.
                 */
                query: { filePath }
            });

            const res = await get<ResourceKey>(url);
            callback(undefined, res);
        } catch (error) {
            if (isAxiosError<ResourceKey>(error) && error.response) {
                /**
                 * Ideally we'd like to log the error here. For now it's a bit
                 * tricky to do so because we don't have access to the logger
                 * since it lives within the React app in a context.
                 */
            }
        }
    },
    loadPath: '{{lng}}/{{ns}}.json'
});
