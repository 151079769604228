import React, { type FC, type PropsWithChildren } from 'react';
import { type AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { AppLayout } from 'bb/app';
import { updateMarketCookie } from 'bb/app/updateMarketCookie';
import { useSetLang } from 'bb/app/useSetLang';
import { CustomErrorBoundary } from 'bb/common/ErrorBoundary';
import { PublicEnvProvider, type PublicEnvVariables } from 'bb/config/env';
import { getDefaultStructuredData } from 'bb/discovery/utils';
import { appWithTranslation } from 'bb/i18n/resources/appWithTranslation';
import { PageLayoutFallback } from 'bb/page/PageLayoutFallback';
import { type PageWithLayout } from 'bb/page/types';
import { TrackNavigation } from 'bb/tracker';

updateMarketCookie();

import 'bb/style/main.scss';
import 'bb/style/adyen.scss';
import 'bb/style/fonts.css';

const IGNORED_NEW_RELIC_ERRORS: RegExp[] = [
    /**
     * These errors are usually caused by React minified errors and
     * can happen quite frequently due to hydration mismatches. They
     * are somewhat expected due to our caching strategy and can be
     * ignored.
     */
    /Minified React error #(418|423|425|310)/g,
    /**
     * These errors are usually caused by third-party scripts that are
     * not properly handling promises. They are not critical and can be
     * ignored.
     */
    /Unhandled Promise Rejection: Timeout/g
];

const AppLayoutWrapper: FC<PropsWithChildren<Pick<AppProps, 'pageProps'>>> = ({
    pageProps,
    children
}) => {
    if (pageProps?.data?.disableAppLayout) {
        return <>{children}</>;
    }

    return (
        <AppLayout
            includedStoreProviders={pageProps.appProps?.includedStoreProviders}
            disableGlobalFetchComponents={
                pageProps.appProps?.disableGlobalFetchComponents
            }
            {...pageProps}
        >
            <TrackNavigation />
            {children}
        </AppLayout>
    );
};

const App = ({ Component, pageProps }: AppProps) => {
    const Page = Component as PageWithLayout;

    useSetLang(pageProps?.i18nSSRConfig?.locale);

    const { disableDefaultLayout = false } = pageProps?.appProps || {};

    const getLayout =
        Page.getLayout ||
        (disableDefaultLayout
            ? undefined
            : (page) => <PageLayoutFallback>{page}</PageLayoutFallback>);

    const pageElement = <Page {...pageProps} />;

    return (
        <>
            <Script
                src="https://js-agent.newrelic.com/nr-loader-spa-1.264.0.min.js"
                strategy="afterInteractive"
                onLoad={() => {
                    if (window.newrelic) {
                        window.newrelic.setErrorHandler((err) => {
                            const errorMessage = (err?.message as string) ?? '';

                            return IGNORED_NEW_RELIC_ERRORS.some(
                                (errorRegExp) => errorMessage.match(errorRegExp)
                            );
                        });
                    }
                }}
            />

            <PublicEnvProvider
                publicEnvVariables={
                    pageProps?.publicEnvVariables as PublicEnvVariables
                }
            >
                <Head>
                    <script
                        key="structured-data"
                        id="structured-data"
                        type="application/ld+json"
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(getDefaultStructuredData())
                        }}
                    />
                </Head>
                <CustomErrorBoundary>
                    <AppLayoutWrapper pageProps={pageProps}>
                        {getLayout
                            ? getLayout(pageElement, pageProps)
                            : pageElement}
                    </AppLayoutWrapper>
                    <div id="checkout-container" />
                </CustomErrorBoundary>
            </PublicEnvProvider>
        </>
    );
};

export default appWithTranslation(App);
