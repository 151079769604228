/* eslint-disable no-restricted-syntax */
/* eslint-disable max-depth */
import React, { useMemo, useRef } from 'react';
import i18nClient, { type i18n as I18n } from 'i18next';
import ICU from 'i18next-icu';
import { type NextConfig } from 'next';
import { I18nextProvider } from 'react-i18next';
import { useIsomorphicLayoutEffect } from 'bb/common/hooks/useIsomorphicLayoutEffect';
import { isBrowser as getIsBrowser } from 'bb/utils/environment';
import { addPluginsToI18Next } from './addPluginsToI18Next';
import { addResourcesToI18Next } from './addResourcesToI18Next';
import { ClientHttpBackend } from './browser/ClientHttpBackend';
import { createI18NextConfig } from './createI18NextConfig';
import { getOrCloneGlobalI18nInstance } from './server/createSSRI18nClient';
import { type I18NSSRConfig } from './types';
import type { AppProps } from 'next/app';

const isBrowser = getIsBrowser();

export const appWithTranslation = <TAppProps extends AppProps>(
    WrappedComponent: React.ComponentType<TAppProps>,
    options?: NextConfig['i18n']
) => {
    const AppWithTranslation = (props: {
        pageProps: TAppProps['pageProps'] & I18NSSRConfig;
    }) => {
        const { pageProps } = props;
        const i18nSSRConfig = (pageProps.i18nSSRConfig ??
            {}) as I18NSSRConfig['i18nSSRConfig'];
        const { locale, ns = [], store: resources = {} } = i18nSSRConfig;

        const instanceRef = useRef<I18n | null>(null);

        /**
         * The i18n instance is memoized to prevent re-creating the instance on every render
         * which causes an infinite loop on the client-side.
         */
        const i18n = useMemo(() => {
            let instance = instanceRef.current;

            const config = createI18NextConfig({
                defaultLocale: locale,
                ns,
                resources,
                locales: [locale],
                ...options
            });

            if (!instance) {
                /**
                 * If the app is running on the server, we use the global i18n instance
                 * that is created in the `createSSRI18nClient` function.
                 */
                if (!isBrowser) {
                    instanceRef.current = getOrCloneGlobalI18nInstance(config);
                    instance = instanceRef.current;
                } else {
                    /**
                     * If the app is running on the client, we create a new i18n instance.
                     */
                    instanceRef.current = i18nClient.createInstance(config);
                    instance = instanceRef.current;
                }
            }

            if (instance && !instance.isInitialized) {
                /**
                 * Add plugins to the i18n instance if it's not initialized yet.
                 */
                addPluginsToI18Next(instance, [
                    new ICU({ memoize: false }),
                    isBrowser ? ClientHttpBackend : null
                ]);

                /**
                 * Initialize the i18n instance with the provided configuration.
                 *
                 * The callback function is called when the i18n instance is initialized.
                 */
                instance.init(config, () => {
                    /**
                     * When the instance is ready we can add the resources to
                     * the i18n instance.
                     */
                    if (instance) {
                        addResourcesToI18Next(instance, resources);
                    }
                });
                /**
                 * In this case we are already initialized and we can add the resources to
                 * the i18n instance.
                 */
            } else if (instance) {
                addResourcesToI18Next(instance, resources);
            }

            return instance;
        }, [resources, locale, ns]);

        useIsomorphicLayoutEffect(() => {
            if (!i18n || !locale) return;

            i18n.changeLanguage(locale);
        }, [locale, i18n]);

        return i18n ? (
            <I18nextProvider i18n={i18n}>
                <WrappedComponent {...(props as TAppProps)} />
            </I18nextProvider>
        ) : (
            <WrappedComponent {...(props as TAppProps)} />
        );
    };

    return AppWithTranslation;
};
