import React from 'react';
import { OfferIcon } from 'bb/ui/Icons/svgs';
import { Input } from '../Input';
import { type InputProps } from '../Input.types';

export type OfferInputProps = Omit<InputProps, 'startAdornment'>;

export const OfferInput = React.forwardRef<HTMLInputElement, OfferInputProps>(
    (props, ref) => (
        <Input
            startAdornment={<OfferIcon size="small" />}
            ref={ref}
            {...props}
        />
    )
);
