// @ts-strict-ignore
import { useMemo, useContext } from 'react';
import { AppContext } from 'bb/app';
import { Debug } from 'bb/app/debug';
import { type TrackingStrategy } from 'bb/tracker/types';
import { TrackingContext } from '../contexts';

type PaymentTracker = {
    initPaymentRequest(): void;
    failedPaymentRequest(message: string): void;
    completePaymentRequest(): void;
    successfulPaymentRequest(): void;
};

const debug = Debug('usePaymentTracker');

const paymentTracker = (
    type: string,
    tracker: TrackingStrategy
): PaymentTracker => ({
    initPaymentRequest: (): void => {
        tracker.track({ event: 'initPaymentRequest', type });
        debug.warn(`${type} - init payment request`);
    },
    failedPaymentRequest: (message): void => {
        tracker.track({ event: 'failedPaymentRequest', type, message });
        debug.error(`${type} - failed payment request - ${message}`);
    },
    completePaymentRequest: (): void => {
        tracker.track({ event: 'completePaymentRequest', type });
        debug.warn(`${type} - complete payment request`);
    },
    successfulPaymentRequest: (): void => {
        tracker.track({ event: 'successfulPaymentRequest', type });
        debug.warn(`${type} - successful payment request`);
    }
});

/**
 *   @deprecated dataLayerStrategy is not handling these events, and seems not to be mapped to anything in GTM
 */
export const usePaymentTracker = (): PaymentTracker => {
    const { trackingStrategy } = useContext(AppContext);
    const { context } = useContext(TrackingContext);
    return useMemo(
        () => paymentTracker(context, trackingStrategy),
        [context, trackingStrategy]
    );
};
