// @ts-strict-ignore
export const parseData = <T>(data: string): T => {
    try {
        return JSON.parse(data) as T;
    } catch {
        return null;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringify = (data: any = null): string => {
    if (data === null) {
        return null;
    }
    try {
        return JSON.stringify(data);
    } catch {
        return null;
    }
};

export const downloadJson = (text, name, type) => {
    const file = new Blob([JSON.stringify(text)], { type });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = name;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
    }, 100);
};
