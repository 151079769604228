import HttpBackend from 'i18next-http-backend';
import ICU from 'i18next-icu';

export const icu = new ICU({ memoize: false });

export { HttpBackend };
export * from './translationUtils';
export { Amount } from './Amount';
export { AmountPerMonth } from './AmountPerMonth';
export { FormattedDate } from './FormattedDate';
export { Period } from './Period';
export * from './Text';
export { Header } from './Header';
export { HoursPerMonth } from './HoursPerMonth';
export { useFormErrorMessage } from './useFormErrorMessage';
export { usePeriod, useDaysPeriod, type PeriodReturn } from './usePeriod';
export { LimitaryText } from './LimitaryText';

export * from './types';
