/* eslint-disable max-depth */
/* eslint-disable complexity */
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import Fuse from 'fuse.js';
import { useStateWithOnChange } from 'bb/common/hooks';
import { useEventListener, useOutsideClick } from 'bb/ui/hooks';
import { assignRef } from 'bb/utils';
import {
    type UseSelectProps,
    type GetButtonProps,
    type GetItemProps,
    type GetLabelProps,
    type GetMenuProps,
    type HTMLElementPropsWithRef,
    type GetSearchInputProps
} from './useSelect.types';

const OPEN_MENU_KEYS = ['Enter', ' '];

export function useSelect<TItem>(props: UseSelectProps<TItem>) {
    const {
        onChange: passedOnChange,
        onIsOpenChange: passedOnIsOpenChange,
        defaultSelectedItem = null,
        defaultIsOpen = false,
        disabled = false,
        items,
        isMatchFn,
        searchableProps = {}
    } = props;

    const [selectedItem, setSelectedItem] = useStateWithOnChange(
        defaultSelectedItem,
        passedOnChange
    );
    const [isOpen, setIsOpen] = useStateWithOnChange(
        defaultIsOpen,
        passedOnIsOpenChange
    );
    const selectId = `select-${useId()}`;

    const labelId = `${selectId}-label`;
    const menuId = `${selectId}-menu`;
    const menuItemId = `${selectId}-item`;

    const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
    const [buttonElement, setButtonElement] = useState<HTMLElement | null>(
        null
    );

    const [searchInputElement, setSearchInputElement] =
        useState<HTMLElement | null>(null);
    const recordedKeysRef = useRef('');
    const recordedKeysTimeoutHandlerRef = useRef<NodeJS.Timeout>();

    const menuItemsRef = useRef<Record<number, HTMLElement | null>>({});
    const focusedItemRef = useRef<number>(0);

    const openMenu = useCallback(() => setIsOpen(true), [setIsOpen]);
    const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);

    const getButtonElement = () =>
        (buttonElement?.querySelector('[role="combobox"]') ??
            buttonElement) as HTMLElement | null;

    useEventListener('keydown', (event) => {
        if (disabled) return;

        const currentListElement = menuItemsRef.current[focusedItemRef.current];
        const { parentElement } = currentListElement ?? {};

        /**
         * Get the element that opens the menu. We try to get the descendant of
         * openButtonElement that has the appropriate role first. If there's no
         * match we go for the actual buttonElement. This is because we wanna
         * support having different root and button elements.
         */
        const openButtonElement = getButtonElement();

        /**
         * If we are on the first item in the dropdown and we tab backwards
         * we want to focus the openButtonElement and close the menu.
         */
        if (event.shiftKey && event.key === 'Tab') {
            const firstElement = searchInputElement ?? menuItemsRef.current[0];

            if (firstElement === document.activeElement) {
                event.preventDefault();
                openButtonElement?.focus();
                closeMenu();
            }
        } else if (
            currentListElement &&
            document.activeElement === currentListElement &&
            parentElement
        ) {
            let nextElement: HTMLElement | undefined;

            /**
             * If a list item has focus we use the ArrowDown/ArrowUp keys to
             * navigate.
             */
            switch (event.key) {
                case 'ArrowDown':
                    nextElement =
                        currentListElement.nextElementSibling as HTMLElement;
                    if (nextElement) {
                        nextElement.focus();
                    } else {
                        menuItemsRef.current[0]?.focus();
                    }
                    break;
                case 'ArrowUp':
                    nextElement =
                        currentListElement.previousElementSibling as HTMLElement;
                    if (nextElement) {
                        nextElement.focus();
                    } else {
                        menuItemsRef.current[
                            parentElement.childNodes.length - 1
                        ]?.focus();
                    }
                    break;
                case 'Escape':
                    closeMenu();
                    openButtonElement?.focus();
                    break;
                default:
                    break;
            }

            /**
             * We want to replicate the native behaviour of the select element
             * which enables the user to type a sequence of characters to search
             * the options. After a timeout of 500ms the sequence is reset.
             */
            if (event.key.length === 1) {
                recordedKeysRef.current += event.key;

                const newItems = new Fuse(items, searchableProps)
                    .search(recordedKeysRef.current)
                    .map(({ item }) => item);

                clearTimeout(recordedKeysTimeoutHandlerRef.current);
                recordedKeysTimeoutHandlerRef.current = setTimeout(() => {
                    recordedKeysRef.current = '';
                }, 500);

                /**
                 * Fuse will find the best match for us and place it at
                 * the first index. We then find the index of the best match
                 * using the provided isMatchFn. If found, we focus the item.
                 */
                const [bestMatch] = newItems;
                const indexOfBestMatch = bestMatch
                    ? items.findIndex((item) => isMatchFn(item, bestMatch))
                    : -1;
                menuItemsRef.current[indexOfBestMatch]?.focus();
            }
        }
    });

    useOutsideClick(menuElement, closeMenu, isOpen);

    /**
     * Initial focus state is set since we need to wait for elements to
     * be set in our state. It doesn't work to do it in the useEventListener
     * hook above.
     */
    useEffect(() => {
        if (isOpen && menuElement) {
            if (searchInputElement) {
                searchInputElement.focus();
            } else {
                menuItemsRef.current[0]?.focus();
            }
        }
    }, [isOpen, menuElement, searchInputElement]);

    const getSearchInputProps = <THTMLElement extends HTMLElement>(
        passedProps: GetSearchInputProps<THTMLElement> = {}
    ) => {
        const elementProps: HTMLElementPropsWithRef<THTMLElement> = {
            ...passedProps,
            ref: (element) => {
                setSearchInputElement(element);
                assignRef(passedProps.ref, element);
            }
        };

        return elementProps;
    };

    const getLabelProps = <THTMLElement extends HTMLElement>(
        passedProps: GetLabelProps<THTMLElement> = {}
    ) => {
        const elementProps: Omit<
            HTMLElementPropsWithRef<THTMLElement>,
            'color'
        > = {
            ...passedProps,
            id: labelId
        };

        return elementProps;
    };

    const getItemProps = <THTMLElement extends HTMLElement>({
        item,
        index,
        isSelected = false,
        ...restProps
    }: GetItemProps<TItem, THTMLElement>) => {
        const onSelect = () => {
            if (disabled) return;

            setSelectedItem(item);
            closeMenu();
        };

        const elementProps: HTMLElementPropsWithRef<THTMLElement> = {
            ...restProps,
            'aria-selected': isSelected,
            role: 'option',
            id: `${menuItemId}-${index}`,
            // eslint-disable-next-line no-nested-ternary
            tabIndex: disabled ? -1 : index === 0 ? 0 : -1,
            onClick: (event) => {
                onSelect();
                restProps.onClick?.(event);
            },
            onKeyDown: (event) => {
                if (OPEN_MENU_KEYS.includes(event.key)) {
                    onSelect();
                }
                focusedItemRef.current = index;
                restProps.onKeyDown?.(event);
            },
            ref: (element) => {
                menuItemsRef.current[index] = element;
                assignRef(restProps.ref, element);
            }
        };

        return elementProps;
    };

    const getButtonProps = <THTMLElement extends HTMLElement>(
        passedProps: GetButtonProps<THTMLElement> = {}
    ) => {
        const elementProps: HTMLElementPropsWithRef<THTMLElement> = {
            ...passedProps,
            'aria-controls': menuId,
            'aria-expanded': isOpen,
            'aria-haspopup': 'listbox',
            'aria-labelledby': labelId,
            role: 'combobox',
            tabIndex: disabled ? -1 : 0,
            onClick: (event) => {
                if (disabled) return;

                if (
                    /**
                     * This event seems to trigger when a user clicks something
                     * inside the menu. This is weird snce this handler should be
                     * scoped to the button element. Therefore we need to check
                     * if the target is inside a combobox element.
                     */
                    (event.target as HTMLElement).closest('[role="combobox"]')
                ) {
                    setIsOpen(!isOpen);
                }
                passedProps.onClick?.(event);
            },
            onKeyDown: (event) => {
                if (disabled) return;

                if (
                    OPEN_MENU_KEYS.includes(event.key) &&
                    (event.target as HTMLElement).closest('[role="combobox"]')
                ) {
                    setIsOpen(!isOpen);
                }
                passedProps.onKeyDown?.(event);
            },
            ref: (element) => {
                setButtonElement(element);
                assignRef(passedProps.ref, element);
            }
        };

        return elementProps;
    };

    const getMenuProps = <THTMLElement extends HTMLElement>(
        passedProps: GetMenuProps<THTMLElement> = {}
    ) => {
        const elementProps: HTMLElementPropsWithRef<THTMLElement> = {
            ...passedProps,
            'aria-labelledby': labelId,
            id: menuId,
            role: 'listbox',
            tabIndex: -1,
            ref: (element) => {
                setMenuElement(element);
                assignRef(passedProps.ref, element);
            }
        };

        return elementProps;
    };

    return {
        closeMenu,
        getButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getSearchInputProps,
        isOpen,
        openMenu,
        selectedItem
    };
}
