import { makeToastItem, toastStore } from 'bb/app/stores/toastStore';
import { SIMPLE_NOTIFICATION_VARIANTS } from 'bb/ui/SimpleNotification/SimpleNotification';
import { type ToastProps } from 'bb/ui/Toast';

export type ToastObject = Record<
    ToastProps['variant'],
    (
        message: ToastProps['message'],
        options?: Omit<ToastProps, 'onClose' | 'variant' | 'message'>
    ) => void
>;

/**
 * Defaults for when to close toast variants.
 *
 * Currently only success self-closes.
 */
const VARIANT_TO_CLOSE_AFTER_MS: Record<
    ToastProps['variant'],
    ToastProps['closeAfterMs']
> = {
    success: 6000,
    information: undefined,
    error: undefined,
    warning: undefined
};

/**
 * The toast object contains all variants as methods for a nicer API.
 *
 * Called on like so:
 *
 * toast.error(message, options);
 * toast.information(message, options);
 * toast.success(message, options);
 * toast.warning(message, options);
 */
export const toast = SIMPLE_NOTIFICATION_VARIANTS.reduce<ToastObject>(
    (obj, variant) => {
        // eslint-disable-next-line no-param-reassign
        obj[variant] = (
            message,
            {
                isVisible = true,
                closeAfterMs = VARIANT_TO_CLOSE_AFTER_MS[variant],
                ...restOptions
            } = {}
        ) =>
            toastStore.set((oldState) => [
                ...oldState,
                makeToastItem({
                    message,
                    variant,
                    isVisible,
                    closeAfterMs,
                    ...restOptions
                })
            ]);

        return obj;
    },
    {} as ToastObject
);
