import { useEffect, useRef } from 'react';
import { useStableCallback } from 'bb/common/hooks/useStableCallback';
import { unRef } from 'bb/utils';
import { type MaybeRef } from '../types';

export function useMutationObserver(
    target: MaybeRef<Element | null | undefined>,
    passedCallback: MutationCallback,
    options: MutationObserverInit = {}
) {
    const optionsRef = useRef<MutationObserverInit>(options);
    const observer = useRef<MutationObserver | null>(null);
    const callback = useStableCallback(passedCallback);

    optionsRef.current = options;

    useEffect(() => {
        const element = unRef(target);

        if (!window.MutationObserver) return;

        observer.current = new MutationObserver(callback);

        if (element) {
            observer.current.observe(element, optionsRef.current);
        }

        // eslint-disable-next-line consistent-return
        return () => {
            observer.current?.disconnect();
        };
    }, [callback, target]);

    return observer.current;
}
