/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, type LinkProps } from 'bb/app/nav/Link';
import { useFeature } from 'bb/config/features';
import { useTranslationsFromDiscovery } from '../hooks/useTranslationsFromDiscovery';
import { Badge } from './BaseBadge';
import { BookBadge, type BookBadgeProps } from './BookBadge';

export type BadgeLinkProps = Omit<LinkProps, 'ref'> & BookBadgeProps;

export const BadgeLink = (props: BadgeLinkProps) => {
    const profileCampaignsEnabled = useFeature('profile-campaigns');
    const { translationKey, icon, ...linkProps } = props;
    const text = useTranslationsFromDiscovery(translationKey);
    const { t } = useTranslation(['badge']);
    const allyText = `${t(`book:accessibility_badge`)} ${t(`badge:accessibility_${icon}`, '')} ${text}`;

    const BadgeToRender = () => {
        if (profileCampaignsEnabled) {
            return <BookBadge translationKey={translationKey} icon={icon} />;
        }
        return <Badge translationKey={translationKey} icon={icon} />;
    };

    return (
        <Link
            {...linkProps}
            style={{ textDecoration: 'none' }}
            aria-label={allyText}
        >
            <BadgeToRender />
        </Link>
    );
};
