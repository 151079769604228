import { useCallback } from 'react';
import { type Route, routes, type Routes } from 'bb/app/router/config';
import { useRemappedRoutes } from 'bb/app/router/RemappedRoutesContext';
import { getKey } from 'bb/utils/getKey';

export const useGetRoutesFromRemappedRoutes = () => {
    const remappedRoutes = useRemappedRoutes();

    /**
     * Helper function for mapping an existing route to a new one.
     *
     * Useful for when we need to change legacy routes to new ones without
     * breaking old functionality.
     */
    const getRouteFromRemappedRoutes = useCallback(
        (route: Route | Routes[Route]) => {
            if (typeof route === 'string') {
                return remappedRoutes[route as Route] ?? route;
            }

            const key = getKey(routes, (r) => r.href === route.href) as Route;

            return remappedRoutes[key] ?? key;
        },
        [remappedRoutes]
    );

    return getRouteFromRemappedRoutes;
};
