import { useMemo } from 'react';
import { isMonths, isWeeks } from 'bb/campaigns/utils';
import { useTranslation } from 'bb/i18n/translationUtils';
import { isDef } from 'bb/utils/assert';
import { match } from 'bb/utils/match';

export type PeriodReturn = {
    period: string;
    periodType: string;
    periodValue: number;
    periodUnit: string;
};

type PeriodType = {
    days?: number | null | undefined;
    months?: number;
};

export function usePeriod({ days, months }: PeriodType): PeriodReturn {
    const { t } = useTranslation('common');

    if (!days && days !== 0 && months && months >= 0) {
        return {
            period: '',
            periodType: '',
            periodValue: months,
            periodUnit: t('common:months', { periodLength: months })
        };
    }

    if (months) {
        return {
            period: `${months} ${t('common:months', { periodLength: months })}`,
            periodType: t('common:months', { periodLength: months }),
            periodValue: months,
            periodUnit: t('common:months', { periodLength: months })
        };
    }

    if (!isDef(days)) {
        return {
            period: '',
            periodType: '',
            periodValue: 0,
            periodUnit: ''
        };
    }

    const periodValue = match({ days })
        .on(isWeeks, () => days / 7)
        .on(isMonths, () => days / 30)
        .otherwise(() => days);

    const periodUnit = match({ days })
        .on(isWeeks, () => t('common:weeks', { periodLength: periodValue }))
        .on(isMonths, () => t('common:months', { periodLength: periodValue }))
        .otherwise(() => t('common:days', { periodLength: periodValue }));

    return {
        period: `${periodValue} ${periodUnit}`,
        periodType: periodUnit,
        periodValue,
        periodUnit
    };
}

export function useDaysPeriod(days?: number | null): PeriodReturn {
    const { t } = useTranslation('common');
    return useMemo(() => {
        const def = isDef(days);

        const periodValue = def ? (days ?? 0) : 0;
        const periodUnit = def
            ? t('common:days', { periodLength: periodValue })
            : '';

        return {
            period: def ? `${periodValue} ${periodUnit}` : '',
            periodType: periodUnit,
            periodValue,
            periodUnit
        };
    }, [days, t]);
}
