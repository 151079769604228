/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { Text, Header } from 'bb/i18n';
import { Stack } from '../Flex';
import { useCountDown } from '../hooks/useCountDown';
import { Spinner, type SpinnerProps } from '../Spinner';
import css from './messageSpinner.module.scss';

export type MessageSpinnerProps = {
    body?: string;
    countDownFrom?: number;
    heading?: string;
} & Omit<SpinnerProps, 'text' | 'size'>;

export const MessageSpinner = ({
    countDownFrom = 0,
    heading,
    body,
    ghost,
    inline,
    nested,
    className,
    ...restProps
}: MessageSpinnerProps) => {
    const count = useCountDown(countDownFrom);
    const canShowText = !ghost && !nested && (heading || body);

    return (
        <Spinner
            className={classNames(inline && css.inline, className)}
            ghost={ghost}
            inline={inline}
            nested={nested}
            ghostNode={
                countDownFrom > 0 && (
                    <Text
                        center
                        variant="h5Primary"
                        color="primary-purple"
                        className={css.countdown}
                    >
                        {count}
                    </Text>
                )
            }
            text={
                canShowText && (
                    <Stack className={css.text}>
                        {heading && (
                            <Header
                                center
                                as="h3"
                                variant="h6Secondary"
                                marginTop="medium"
                            >
                                {heading}
                            </Header>
                        )}
                        {body && (
                            <Text
                                center
                                variant="body2"
                                marginTop={heading ? 'extraSmall' : 'medium'}
                            >
                                {body}
                            </Text>
                        )}
                    </Stack>
                )
            }
            size="large"
            {...restProps}
        />
    );
};
