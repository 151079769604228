import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useIsLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { useRouter } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { useMarket } from 'bb/app/useMarket';
import { EuMarketFlag } from 'bb/common/EuMarketFlag';
import Logo from 'bb/common/Logo';
import { getMarketConfig } from 'bb/config/marketConfig';
import { useTranslation } from 'bb/i18n';
import { Row } from 'bb/ui';
import { Link } from '../Link';
import { CenterMenu } from './CenterMenu';
import { NAV_BAR_ID } from './consts';
import css from './navbar.module.scss';
import { SideMenu } from './SideMenu';

export type NavBarProps = React.ComponentPropsWithoutRef<'nav'>;

export const Navbar = observer(
    forwardRef<HTMLElement, NavBarProps>((props, ref) => {
        const { className, ...restProps } = props;
        const { routes, navigate } = useRouter();
        const { t } = useTranslation(['nav', 'common']);
        const { market } = useMarket();
        const isLoggedIn = useIsLoggedIn();

        const { studentOffer: showStudent, giftCard: showGiftCard } =
            getMarketConfig(market) || {};

        const onClick = () => {
            if (!isLoggedIn) {
                // do a refresh and load start page to avoid cache missmatch after release
                navigate(routes.start);
            }
        };
        return (
            <nav
                {...restProps}
                className={classNames(
                    css.normal,
                    css.fixed,
                    isLoggedIn && css.black,
                    className
                )}
                ref={ref}
                role="navigation"
                aria-label={t('nav:desktopNavBar')}
                id={NAV_BAR_ID}
            >
                <Row justifyContent="spaceBetween" alignItems="center">
                    <Row alignItems="center" className={css.logoWrapper}>
                        <Link
                            route={isLoggedIn ? routes.welcome : routes.start}
                            aria-label={t('common:startPageLinkLabel')}
                            onClick={onClick}
                        >
                            <span>
                                <Logo
                                    variant={isLoggedIn ? 'white' : 'black'}
                                    className={classNames(
                                        css.logo,
                                        isLoggedIn && css.whiteLogo
                                    )}
                                />
                            </span>
                        </Link>
                        {!isLoggedIn && <EuMarketFlag currentMarket={market} />}
                    </Row>

                    <CenterMenu
                        showGiftCard={showGiftCard}
                        showStudent={showStudent}
                        inverted={isLoggedIn}
                    />
                    <SideMenu
                        showGiftCard={showGiftCard}
                        showStudent={showStudent}
                        inverted={isLoggedIn}
                    />
                </Row>
            </nav>
        );
    })
);
