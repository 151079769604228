import React, { type FunctionComponent } from 'react';
import { MenuItem } from './MenuItem';
import { type MenuItemsProps } from './types';

export const MenuItems: FunctionComponent<MenuItemsProps> = ({
    links,
    sub,
    inverted,
    currentPath
}) => (
    <ul>
        {links
            ?.filter((link) => !link.hidden)
            .map((link) => (
                <li key={link.label}>
                    <MenuItem
                        inverted={inverted}
                        sub={sub}
                        currentPath={currentPath}
                        link={link}
                        menuItemsComponent={MenuItems}
                    />
                </li>
            ))}
    </ul>
);
