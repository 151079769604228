// @ts-strict-ignore
import React from 'react';
import { useRouter } from 'bb/app/router';
import { DeprecatedLinkButton } from 'bb/common/DeprecatedLinkButton';
import { type LinkButtonProps } from 'bb/common/DeprecatedLinkButton/LinkButton';
import { useCtaTracker } from 'bb/tracker/hooks/useCtaTracker';
import { type ButtonVariant } from 'bb/ui';
import { type SignupCtaProps } from './types';

export type SignupButtonProps<Variant extends ButtonVariant> = Omit<
    LinkButtonProps<Variant> & Omit<SignupCtaProps, 'variant'>,
    'route'
>;

export const SignupButton = <Variant extends ButtonVariant>({
    name,
    variant,
    children,
    ...restProps
}: SignupButtonProps<Variant>) => {
    const { routes } = useRouter();
    const tracker = useCtaTracker();

    return (
        <DeprecatedLinkButton
            variant={variant ?? 'primary'}
            route={routes.registration}
            onClick={() => tracker.signupCta(name)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        >
            {children}
        </DeprecatedLinkButton>
    );
};
