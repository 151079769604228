/* eslint-disable react-hooks/exhaustive-deps */
import { type RefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
    freezeOnceVisible?: boolean;
    route?: boolean;
}

/**
 * Copied from https://usehooks-ts.com/react-hook/use-intersection-observer
 */
export function useIntersectionObserver(
    elementRef: RefObject<Element>,
    {
        threshold = 0,
        root = null,
        rootMargin = '0%',
        freezeOnceVisible = false
    }: Args = {}
): IntersectionObserverEntry | undefined {
    const [entry, setEntry] = useState<IntersectionObserverEntry>();

    const frozen = entry?.isIntersecting && freezeOnceVisible;

    const updateEntry = ([e]: IntersectionObserverEntry[]): void => {
        setEntry(e);
    };

    useEffect(() => {
        const node = elementRef?.current; // DOM Ref
        const hasIOSupport = !!window.IntersectionObserver;

        if (!hasIOSupport || frozen || !node) return;

        const observerParams = { threshold, root, rootMargin };
        const observer = new IntersectionObserver(updateEntry, observerParams);

        observer.observe(node);

        // eslint-disable-next-line consistent-return
        return () => observer.disconnect();
    }, [
        elementRef?.current,
        JSON.stringify(threshold),
        root,
        rootMargin,
        frozen
    ]);

    return entry;
}
