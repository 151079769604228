import React, { type FunctionComponent } from 'react';
import { useTranslation } from 'bb/i18n/translationUtils';

export const FormattedDate: FunctionComponent<{
    daysFromNow: number;
}> = ({ daysFromNow }) => {
    const { t } = useTranslation('common');
    const date = new Date();
    date.setDate(date.getDate() + daysFromNow);

    return <>{t('common:date', { date })}</>;
};
