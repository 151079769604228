import { type ValuesOf } from '../common/types';

export const DELETE_COOKIE = -1;
export const THIRTY_MINUTES = 1800; // 30min (60*30 seconds)
export const EIGHT_HOURS = 28800; // 8h (60*60*8 seconds)
export const SIX_MONTHS = 15552000; // 6 months (60*60*24*30*6 seconds)

export const SAME_SITE_DEFAULT = 'Lax';

/**
 * Used as enum but without the needless
 * complexity.
 */
export const Cookies = {
    AFFILIATE_COOKIE: 'bb_affiliate',
    AFFILIATE_DATA_COOKIE: 'bb_affiliate_data',
    CAMPAIGN_COOKIE: 'bb_campaign',
    EMAIL_COOKIE: 'bb_email',
    MARKET_COOKIE: 'bb_market',
    REDIRECT_DATA: 'bb_re_data',
    REFRESH_TOKEN_COOKIE: 'bb_refreshtoken',
    RESET_TOKEN_COOKIE: 'bb_resettoken',
    TOKEN_COOKIE: 'bb_token',
    UTM_CAMPAIGN_COOKIE: 'bb_utm_campaign',
    ONETRUST_COOKIE: 'OptanonConsent'
} as const;

export type CookiesValue = ValuesOf<typeof Cookies>;
