import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const BookIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M2 16.522V2.3h5.6c.981 0 1.853.524 2.4 1.333.547-.81 1.419-1.333 2.4-1.333H18v14.222h-6.4c-.4 0-.4 0-.8.445-.214.237-.199.602-.261.89H9.462c-.06-.286-.05-.656-.262-.89-.4-.445-.4-.445-.8-.445H2ZM9.35 15.2V5.608c0-1.11-.78-2.008-1.742-2.008H3.35v11.6h6Zm1.35-9.592V15.2h6V3.6h-4.258c-.962 0-1.742.899-1.742 2.008Z"
                fillRule="evenodd"
            />
        </svg>
    </Icon>
);
