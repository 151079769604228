import { useHALGet } from 'bb/api/browser';
import type { HalLink } from 'bb/common/types';
import { type Book } from 'bb/discovery/book/types';

export const useBook = (link?: HalLink | string) => {
    const href = typeof link === 'string' ? link : link?.href;

    return useHALGet<Book>(
        href
            ? {
                  href,
                  method: 'GET'
              }
            : undefined,
        {
            fixed: true,
            errorRetryCount: 0,
            // this will override the global configuration set in AccountAppView
            // by the LiveQueriesProvider
            revalidateOnMount: undefined,
            addAsLiveQuery: false
        }
    );
};
