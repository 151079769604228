import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const EyeIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="nonzero">
                <path d="M9.944 4.1C5.764 4.1.9 8.019.9 9.849c0 1.89 4.87 6.102 9.044 6.102S19.2 11.702 19.2 9.849c0-1.796-5.076-5.749-9.256-5.749Zm0 1.3c2.003 0 3.98.824 5.734 2.19a11.606 11.606 0 0 1 1.716 1.63l.165.204.258.344.054.067-.051.09c-.1.17-.251.387-.439.624a12.232 12.232 0 0 1-1.72 1.75c-1.756 1.468-3.726 2.352-5.717 2.352-1.987 0-3.912-.879-5.602-2.341a11.568 11.568 0 0 1-1.647-1.745l-.15-.208-.128-.189-.198-.302-.013-.016.013-.01.3-.422.164-.214a10.93 10.93 0 0 1 1.642-1.625C6.012 6.219 7.944 5.4 9.945 5.4Z" />
                <path d="M10.064 6.291a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5Zm0 1.3a2.45 2.45 0 1 0 0 4.9 2.45 2.45 0 0 0 0-4.9Z" />
            </g>
        </svg>
    </Icon>
);
