import { isDef } from './assert';

// checks if a and b are numbers and not equal
export const isNotEqualNum = (a: number, b: number) =>
    Boolean((a || a === 0) && (b || b === 0) && a !== b);

// Converts anything to string
export const str = (input: unknown): string => {
    if (input === undefined || input === null) return '';

    return input.toString();
};

// map2Obj(foo, bar) -> { foo: "foo", bar: "bar" }
export const map2Obj = <T extends string>(...keys: T[]) =>
    keys.reduce((acc, k) => ({ ...acc, [k]: k }), {} as { [K in T]: K });

export const stripNullish = <
    T extends Record<string, unknown>,
    K extends keyof T
>(
    input: T
) =>
    Object.entries(input).reduce(
        (acc, [key, val]) => (isDef(val) ? { ...acc, [key]: val } : acc),
        {} as { [Key in K]: T[Key] }
    );

export const ms2s = (ms?: number | null) => ms && Math.round(ms / 1000);

export const capitalize = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);
