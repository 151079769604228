// @ts-strict-ignore
import { useCallback, useReducer } from 'react';
import { type AxiosError } from 'axios';
import { Debug } from 'bb/app/debug';
import { post } from './api';
import { createReducer } from './apiReducer';
import { type AxiosErrorResponse, handleError } from './handleError';
import { EXECUTING, SUCCESS, ERROR, IDLE } from './symbols';
import { type ExecuteFunction, type RequestReturnType } from './types';

const debug = Debug('usePost');
export function usePost<Response, Payload>(
    initUrl: string
): RequestReturnType<Response, Payload> {
    const [state, dispatch] = useReducer(createReducer<Response>(), {
        result: null,
        error: null,
        status: IDLE
    });

    const postData: ExecuteFunction<Payload> = useCallback(
        async (payload: Payload): Promise<void> => {
            debug.info(`Posting ${initUrl}`);
            dispatch({ type: EXECUTING });
            await post<Response>(initUrl, payload as Record<string, unknown>)
                .then(({ data }) => {
                    debug.info(data);
                    dispatch({ type: SUCCESS, result: data });
                })
                .catch((err: AxiosError<AxiosErrorResponse, unknown>) => {
                    const error = handleError(err);
                    /*  logger.error(
                        `POST ${initUrl} ${error?.status} ${error?.data?.message}`
                    ); */
                    dispatch({ type: ERROR, error });
                });
        },
        [initUrl]
    );

    return { ...state, execute: postData };
}
