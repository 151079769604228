import { extractQueryParams } from './extractQueryParams';
import { useRouter } from './useRouter';

/**
 * Extracts specified keys from the query params and parses
 * the values with a given function (if specified).
 *
 * Example usage:
 * const { foo } = useExtractQueryParams(['foo'], decodeURI);
 */
export const useExtractQueryParams = <T extends string>(
    keys: T[] | readonly T[],
    fn: (str: string) => string = String
) => extractQueryParams(keys as T[], useRouter().router, fn);
