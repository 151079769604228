import { type InputCommonProps } from './types';

export function getLabelId(name: string): string {
    return `bb-${name}-label`;
}

export function getFeedbackId(name: string): string {
    return `bb-${name}-feedback`;
}

export const makeA11yProps = ({
    label,
    error,
    name = '',
    required
}: React.ComponentProps<'input'> & InputCommonProps) => ({
    'aria-label': typeof label === 'string' ? label : undefined,
    'aria-invalid': Boolean(error),
    'aria-describedby': `${getLabelId(name)} ${getFeedbackId(name)}`,
    'aria-labelledby': getLabelId(name),
    'aria-required': required
});
