import { type CSSProperties } from 'react';
import { type Spacing, type Color } from './types';

export const SPACING_UNIT_SMALL_MAX = 4;

/**
 * Used like so:
 *
 * <Component style={{ ...makeCSSVariableFromColor('--variable-name', 'primary-purple') }} \/>
 */
export const makeCSSVariableFromColor = (
    variableName: `--${string}`,
    color?: Color
): CSSProperties => (color ? { [variableName]: `var(--color-${color})` } : {});

export const makeSpacingValue = (spacing: Spacing | Spacing[]) => {
    const spacingArray = Array.isArray(spacing) ? spacing : [spacing];

    return spacingArray
        .map((unit) =>
            unit > SPACING_UNIT_SMALL_MAX
                ? `calc((var(--base-spacing-unit) * ${unit} * 2) - (var(--base-spacing-unit) * ${SPACING_UNIT_SMALL_MAX}))`
                : `calc(var(--base-spacing-unit) * ${unit})`
        )
        .join(' ');
};

export const CONTRAST_COLOR_MAP: Record<Color, Color> = {
    'primary-black': 'primary-white',
    'primary-purple': 'primary-black',
    'primary-white': 'primary-black',
    'secondary-green': 'primary-black',
    'secondary-red': 'primary-black',
    'secondary-yellow': 'primary-black',
    'tertiary-green': 'primary-black',
    'tertiary-red': 'primary-black',
    'tertiary-purple': 'primary-black',
    'tertiary-yellow': 'primary-black',
    'gray-00': 'primary-black',
    'gray-01': 'primary-black',
    'gray-02': 'primary-black',
    'gray-03': 'primary-black',
    'gray-04': 'primary-white',
    'gray-05': 'primary-white',
    'gray-06': 'primary-white',
    transparent: 'primary-black',
    focus: 'primary-white'
};

export const ALL_COLORS = Object.keys(CONTRAST_COLOR_MAP) as Color[];

export const getContrastColor = (color: Color) => CONTRAST_COLOR_MAP[color];
