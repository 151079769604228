import React from 'react';
import { BaseInput } from './BaseInput';
import { type InputProps } from './Input.types';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ disableMaxLengthCounter = true, ...restProps }, ref) => (
        <BaseInput
            disableMaxLengthCounter={disableMaxLengthCounter}
            as="input"
            ref={ref}
            {...restProps}
        />
    )
);
