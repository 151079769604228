import React from 'react';
import { Text, type TextProps } from 'bb/i18n';
import commonCss from './button.common.module.scss';
import { type ButtonCommonProps } from './Button.types';

export type ButtonContentProps = {
    textProps?: TextProps;
} & Pick<ButtonCommonProps, 'startAdornment' | 'endAdornment'> &
    React.PropsWithChildren;

/**
 * Used internally by `Button` and `TextButton` to render the content of the button.
 */
export const ButtonContent = (props: ButtonContentProps) => {
    const { startAdornment, endAdornment, children, textProps = {} } = props;
    const { bold = true, t, ...restTextProps } = textProps;

    return (
        <span className={commonCss.content}>
            {startAdornment}
            {(children || t) && (
                <Text {...restTextProps} t={t} bold={bold} as="span">
                    {children}
                </Text>
            )}
            {endAdornment}
        </span>
    );
};
