import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const RelevanceIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M11.8 7.35H9.854a.6.6 0 1 1 0-1.2h3.394a.6.6 0 0 1 .6.6v3.395a.6.6 0 1 1-1.2 0V8.2l-3.823 3.824a.6.6 0 0 1-.693.112l-1.196-.6L4.56 14.08A6.8 6.8 0 1 0 3.2 10 .6.6 0 1 1 2 10a8 8 0 1 1 8 8 7.992 7.992 0 0 1-6.713-3.646.6.6 0 0 1 .064-.736l3.014-3.228a.6.6 0 0 1 .707-.127l1.21.607z" />
        </svg>
    </Icon>
);
