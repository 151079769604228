import { useMemo } from 'react';
import { useApi, type UseApiOptions } from 'bb/api/browser/useApi';
import { type PaymentMethodsResponse } from 'bb/config/types';
import { getSupportedPaymentMethods } from './utils/supportedPaymentMethods';

export const usePaymentMethods = (
    config?: UseApiOptions<PaymentMethodsResponse>
) => {
    const swr = useApi('/api/paymentmethods', { fixed: true, ...config });

    const paymentMethods = useMemo(
        () => getSupportedPaymentMethods(swr.data),
        [swr.data]
    );

    return Object.assign(swr, { paymentMethods });
};
