import { type PublicEnvVariables } from './types';

/**
 * Create an object from process.env with keys that start with
 * NEXT_PUBLIC_ and pass into new object.
 *
 * Should only be run server-side.
 */
export const createPublicEnvVariables = (): PublicEnvVariables =>
    Object.entries(process.env).reduce(
        (object, [key, value]) =>
            key.startsWith('NEXT_PUBLIC_')
                ? { ...object, [key]: value }
                : object,
        {}
    );
