import React from 'react';
import classNames from 'classnames';
import { Gap } from 'bb/ui/Gap';
import { ErrorIconFilled } from 'bb/ui/Icons';
import { Typography } from 'bb/ui/Typography';
import { type InputCommonProps } from '../types';
import css from './helperText.module.scss';

export type HelperTextProps = {
    helperText?: Extract<InputCommonProps['helperText'], Array<unknown>>;
    error?: React.ReactNode;
} & React.ComponentProps<'div'>;

export const HelperText = ({
    error,
    helperText,
    className,
    ...restProps
}: HelperTextProps) => {
    const [firstHelperText, secondHelperText] = helperText ?? [];

    if (!firstHelperText && !secondHelperText && !error) return null;

    return (
        <div className={classNames(css.root, className)} {...restProps}>
            {error && (
                <Gap
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    role="alert"
                    aria-live="assertive"
                >
                    <ErrorIconFilled size="small" aria-hidden />
                    <Typography
                        variant="body3"
                        color="secondary-red"
                        data-testid="error-message"
                    >
                        {error}
                    </Typography>
                </Gap>
            )}
            {firstHelperText && !error && (
                <Typography variant="body3">{firstHelperText}</Typography>
            )}
            {secondHelperText && (
                <Typography variant="body3">{secondHelperText}</Typography>
            )}
        </div>
    );
};
