import React from 'react';
import { CloseButton } from 'bb/common/components/buttons/CloseButton';
import { Text, type TextProps } from 'bb/i18n';
import { Box, type BoxProps } from '../Box';
import { type UseHeadlessModalReturnProps } from '../HeadlessModal';
import css from './drawer.module.scss';

export type DrawerHeaderProps = Pick<BoxProps, 'padding'> &
    Omit<TextProps<'h2'>, 'as' | 'margin' | 'variant'> &
    Pick<UseHeadlessModalReturnProps, 'handleClose'>;

export const DrawerHeader = (props: DrawerHeaderProps) => {
    const {
        handleClose,
        className,
        padding = [4, 0, 6, 0],
        ...textProps
    } = props;

    return (
        <Box padding={padding} className={className}>
            <div className={css.drawerHeader}>
                <Text
                    {...textProps}
                    variant="h6Secondary"
                    as="h2"
                    margin="none"
                />

                <CloseButton onClick={handleClose} className={css.close} />
            </div>
        </Box>
    );
};
