/* eslint-disable @typescript-eslint/no-empty-object-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFeature } from 'bb/config/features';
import { type Feature } from 'bb/config/features/types';

/**
 * Helper higher order function that takes two components
 * and renders the feature flagged component if the feature
 * is toggled on. Preferably both components should use
 * next/dynamic to avoid large bundles. They currently
 * need to accept the same props.
 *
 * Example usage:
 *
 * const CurrentComponent = dynamic(() => import('./CurrentComponent'))
 * const FeatureFlaggedComponent = dynamic(() => import('./FeatureFlaggedComponent'))
 *
 * const Component = withFeatureFlaggedComponent(CurrentComponent, FeatureFlaggedComponent, 'foo');
 */
export const withFeatureFlaggedComponent =
    <Props extends {}>(
        CurrentComponent: React.ComponentType<Props>,
        FeatureFlaggedComponent: React.ComponentType<Props>,
        feature: Feature
    ) =>
    (props: Props) =>
        useFeature(feature) ? (
            <FeatureFlaggedComponent {...props} />
        ) : (
            <CurrentComponent {...props} />
        );
