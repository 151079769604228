import React, { useState } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useLockBodyScroll, useResizeObserver } from 'bb/ui/hooks';
import { getModalRoot } from 'bb/ui/Modal/utils';
import { assignRef, makeSpacing } from 'bb/utils';
import css from './select.module.scss';
import {
    type SelectDropdownModalProps,
    type SelectDropdownProps
} from './Select.types';

/**
 * Get the style for the dropdown to attach to the anchor element.
 */
const getAttachToAnchorStyle = (
    rect: DOMRect,
    dropdownElement: HTMLSpanElement | null
): React.CSSProperties => {
    if (!dropdownElement) return {};

    const base = {
        width: rect.width,
        left: rect.x
    };

    /**
     * If there is enough space below the anchor element we want
     * to render the dropdown there.
     */
    const below = {
        ...base,
        top: rect.y + rect.height + makeSpacing(1)
    };

    /**
     * If there is not enough space below the anchor element we
     * want to render the dropdown above it as a fallback.
     */
    const above = {
        ...base,
        top: rect.y - dropdownElement.clientHeight - makeSpacing(1)
    };

    if (below.top + dropdownElement.clientHeight < window.innerHeight) {
        return below;
    }

    return above;
};

export const SelectDropdown = React.forwardRef<
    HTMLSpanElement,
    SelectDropdownProps<HTMLSpanElement>
>(({ children, className, widget, ...restProps }, ref) => (
    <span
        className={classNames(css.dropdown, className)}
        ref={ref}
        {...restProps}
    >
        {widget}
        <ul>{children}</ul>
    </span>
));

/**
 * The Select dropdown is rendered in a modal by default.
 * This is because we can run into z-index issues if we
 * render it relative to its parent due to stacking context.
 */
export const SelectDropdownModal = React.forwardRef<
    HTMLSpanElement,
    SelectDropdownModalProps<HTMLSpanElement>
>(({ anchorElementRef, className, style, ...restProps }, passedRef) => {
    const [anchorRect, setAnchorRect] = useState<DOMRect>();
    const [dropdownElement, setDropdownElement] =
        useState<HTMLSpanElement | null>(null);

    const ref: typeof passedRef = (element) => {
        setDropdownElement(element);
        assignRef(passedRef, element);
    };

    const resizeObseverCallback = useDebouncedCallback<ResizeObserverCallback>(
        ([entry]) => {
            if (entry) {
                setAnchorRect(entry.target.getBoundingClientRect());
            }
        },
        1000,
        { leading: true }
    );
    /**
     * Lock scroll so the user doesn't scroll the dropdown away.
     */
    useLockBodyScroll(true, dropdownElement);
    /**
     * If the parent element changes its size we need to do
     * the same with the dropdown.
     */
    useResizeObserver(anchorElementRef, resizeObseverCallback);

    if (!anchorRect) return null;

    return ReactDOM.createPortal(
        <SelectDropdown
            {...restProps}
            className={classNames(css.dropdownModal, className)}
            ref={ref}
            style={{
                ...style,
                ...getAttachToAnchorStyle(anchorRect, dropdownElement)
            }}
        />,
        getModalRoot()
    );
});
