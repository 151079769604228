import { useEffect, useState } from 'react';
import { isMobileDevice, isUserAgent } from 'bb/utils';
import { ANDROID_AGENTS, IOS_AGENTS } from '../consts';

export type OS = {
    iOS: boolean;
    android: boolean;
};

export type IsMobile = boolean | null;

const getOS = () => ({
    iOS: isUserAgent(IOS_AGENTS),
    android: isUserAgent(ANDROID_AGENTS)
});

export const useDeviceInfo = (): { os: OS; isMobile: IsMobile } => {
    /**
     * Default value is null on server
     * so we can distinguish between
     * initial state and state of result.
     */
    const [isMobile, setIsMobile] = useState(false);
    const [os, setOS] = useState<OS>({ iOS: false, android: false });

    useEffect(() => {
        // Only run in client since isMobileDevice
        // relies on navigator.
        setIsMobile(isMobileDevice());
        setOS(getOS());
    }, []);

    return { isMobile, os };
};
