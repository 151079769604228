import React from 'react';
import classnames from 'classnames';
import { SlimNavbar } from 'bb/app/nav';
import { type PageLayout } from 'bb/page/types';
import css from './PageLayoutFallback.module.scss';

export const PageLayoutFallback: PageLayout = ({
    classNames = [],
    children
}) => (
    <div className={css.base}>
        <SlimNavbar />
        <div className={classnames(classNames)}>{children}</div>
    </div>
);
