import debug from 'debug';

export const BASE = 'next-web';

const COLORS = {
    trace: 'lightblue',
    info: 'lightgreen',
    warn: 'orange',
    error: 'red'
};

export function Debug(source: string) {
    const debugClient = debug(`${BASE}:${source}`);

    return {
        trace(data: unknown) {
            debugClient.color = COLORS.trace;
            debugClient(data);
        },

        info(data: unknown) {
            debugClient.color = COLORS.info;
            debugClient(data);
        },

        warn(data: unknown) {
            debugClient.color = COLORS.warn;
            debugClient(data);
        },

        error(data: unknown) {
            debugClient.color = COLORS.error;
            debugClient(data);
        }
    };
}
