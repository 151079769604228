import { useEffect } from 'react';
import { usePost } from 'bb/api/browser';
import {
    type ErrorResponse,
    type ExecuteFunction,
    type Status
} from 'bb/api/browser/types';
import { type HalLink } from '../types';

export function useFollow<Response>(href?: HalLink): {
    result: Response;
    error: ErrorResponse;
    status: Status;
    execute: ExecuteFunction<HalLink>;
} {
    const { result, error, status, execute } = usePost<Response, HalLink>(
        '/api/follow'
    );
    useEffect(() => {
        if (href) {
            execute(href);
        }
    }, [href, execute]);
    return { result, error, status, execute };
}
