// @ts-strict-ignore
import { isBrowser } from 'bb/utils/environment';

export const getHydratedState = (name: string): Record<string, unknown> => {
    if (isBrowser()) {
        if (window.__BBSTATE) {
            return window.__BBSTATE[name] || null;
        }
    }
    return null;
};
