import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const UpcomingIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path d="M1.5 2.709v12c0 1.1.449 2.105 1.172 2.828A3.996 3.996 0 0 0 5.5 18.71h13v-16h-.65Zm1.3 1.299h14.4v13.4H5.5c-.75 0-1.42-.301-1.91-.79a2.68 2.68 0 0 1-.79-1.909z" />
                <path d="M5.5 5.208v-4M14.5 5.208v-4" />
                <path
                    d="m10.847 14.8 3.994-4.006L10.858 6.8c-.011-.01-.898.858-.898.858l1.205 1.216 1.482 1.359-1.227-.07H5v1.268h6.42l1.237-.073-1.492 1.362-1.205 1.216.887.864Z"
                    fillRule="nonzero"
                />
                <path d="M2.05 13.75v1.3h3.052v3.05H6.4v-4.35Z" />
            </g>
        </svg>
    </Icon>
);
