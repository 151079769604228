// @ts-strict-ignore
import { useEffect, useCallback, useReducer, type Dispatch } from 'react';
import { createRetryReducer } from './apiReducer';
import { IDLE, ERROR, RESOLVED, PENDING } from './symbols';
import { type RetryStrategy, type StateWithRetry, type Action } from './types';

export function useRetryStrategy<Response>(
    strategy: RetryStrategy<Response>
): [StateWithRetry<Response>, Dispatch<Action<Response>>] {
    const [state, dispatch] = useReducer(createRetryReducer<Response>(), {
        result: null,
        status: PENDING,
        error: null,
        nrOfTries: 0
    });

    const haveAttemptsLeft = useCallback(
        () => state.nrOfTries < strategy.maxTries,
        [strategy.maxTries, state.nrOfTries]
    );

    useEffect(() => {
        const { retryCondition } = strategy;
        if (
            retryCondition?.(state.result) &&
            haveAttemptsLeft() &&
            state.status === RESOLVED
        ) {
            dispatch({ type: IDLE });
        }
    }, [strategy, state, haveAttemptsLeft]);

    useEffect(() => {
        if (!haveAttemptsLeft() && state.status === RESOLVED) {
            dispatch({ type: ERROR });
        }
    }, [state.status, haveAttemptsLeft]);

    return [state, dispatch];
}
