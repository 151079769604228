import { type StringifiableRecord } from 'query-string';

export function interpolateUrlString(
    pathname: string,
    query: StringifiableRecord,
    regExp: RegExp
) {
    const copy = { ...query };
    const segments = pathname.split('/');
    const newUrl = segments
        .map((segment) => {
            /**
             * Check if the segment is dynamic. If so it needs
             * to be interpolated with the matching key from
             * the query object.
             */
            const matches = segment.match(regExp);

            /**
             * If there's no match we can just return the segment
             * without touching it.
             */
            if (!matches) return segment;
            /**
             * Get the key from the matched group.
             */
            const replaceKey = matches[1];

            /**
             * If it's defined (which it always should be) at this
             * point we get the value of the key in the query object
             * and we return that instead of the placeholder.
             */
            if (replaceKey) {
                const objectValue = copy[replaceKey];

                if (objectValue != null) {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete copy[replaceKey];
                    return objectValue.toString();
                }
            }

            throw new Error(
                `The dynamic key ${segment} doesn't have a match in the passed object`
            );
        })
        .join('/');

    /**
     * The remaining key/value-pairs in the query are returned
     * and used for query params. Basically works just like
     * next/link.
     */
    return { result: newUrl, rest: copy };
}
