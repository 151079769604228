export const uniqueByKey = <
    T extends Record<string, unknown>,
    K extends keyof T
>(
    list: T[],
    ...key: K[]
) => [
    ...new Map(
        list.map((item) => [key.map((k) => item[k]).join(), item])
    ).values()
];
