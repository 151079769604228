export const COLOR_GRAY_01 = '#f2f2f2';
export const COLOR_GRAY_04 = '#666';

export const COLOR_GRAY_05 = '#333';
export const COLOR_GRAY_06 = '#1a1a1a';

export const COLOR_PRIMARY_PURPLE_01 = '#cd96ff';

export const COLOR_BLACK = '#000';

export const COLOR_PRIMARY_03 = '#fff';

export const COLOR_PRIMARY_PURPLE_02 = '#d9afff';

export const COLOR_GRAY_03 = '#999';

export const COLOR_SECONDARY_01 = '#ff5064';

export const COLOR_SECONDARY_GREEN_01 = '#50d764';
