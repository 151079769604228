// @ts-strict-ignore
import { type ErrorResponse } from 'bb/api/browser/types';
import { Debug } from 'bb/app/debug';
import {
    type UserRegisteredResponse,
    type GiftcardDetails
} from 'bb/registration/types';
import {
    type AccountStore,
    type AccountSettings,
    type User,
    type PaymentHistoryResponse,
    type MyPaymentMethodResponse
} from './types';

const debug = Debug('accountStore');

const initialState = {
    email: '',
    isLoading: true,
    error: null,
    isLoggedIn: null,
    hasOngoingPeriod: false,
    isActivated: false,
    isAbandoned: false,
    initialLoad: false,
    paymentHistory: null,
    user: null,
    pendingGiftcard: null,
    pendingCampaignCode: '',
    myPaymentMethodResponse: null,
    consents: [],
    canRedeemCampaign: false,
    canRedeemGiftCard: false
};

export function accountStore(): AccountStore {
    return {
        ...initialState,

        setAccountSettings(accountSettings: AccountSettings) {
            debug.info('setAccountSettings');
            this.email = accountSettings.email;
            this.isLoading = false;
            this.isActivated = accountSettings.isActivated;
            this.isAbandoned = accountSettings.isAbandoned;
            this.initialLoad = true;
            this.consents = accountSettings.consents;
            this.canRedeemCampaign = accountSettings.canRedeemCampaign;
            this.canRedeemGiftCard = accountSettings.canRedeemGiftCard;
        },

        setIsLoggedIn(isLoggedIn: boolean) {
            debug.info('setIsLoggedIn');
            this.error = null;
            if (!isLoggedIn) {
                this.isActivated = false;
                this.isAbandoned = false;
            }
            this.isLoggedIn = isLoggedIn;
        },

        setAccountCreated(user: UserRegisteredResponse) {
            debug.info('setAccountCreated');
            this.error = null;
            this.email = user.email;
            this.isLoggedIn = true;
        },

        setAccountSettingsError(error: ErrorResponse) {
            debug.info('setAccountSettingsError');
            this.error = error;
            this.isLoading = false;
            this.initialLoad = true;
        },
        setAccountActivated(isActivated = true) {
            debug.info('setAccountActivated');
            this.isActivated = isActivated;
            this.isLoggedIn = true;
            this.pendingGiftcard = null;
        },
        setPaymentHistory(paymentHistory: PaymentHistoryResponse) {
            debug.info('setPaymentHistory');
            this.paymentHistory = paymentHistory;
        },
        setUser(user: User) {
            this.user = user;
        },
        setPendingGiftcard(giftcardDetails: GiftcardDetails | null) {
            this.pendingGiftcard = giftcardDetails;
        },
        setPendingCampaignCode(campaignCode: string) {
            this.pendingCampaignCode = campaignCode;
        },
        setMyPaymentMethodResponse(
            myPaymentMethodResponse: MyPaymentMethodResponse
        ) {
            this.myPaymentMethodResponse = myPaymentMethodResponse;
        }
    };
}
