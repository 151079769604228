import { useEffect } from 'react';
import { Debug } from 'bb/app/debug';
import { type Route } from 'bb/app/router/types';
import { useIsNavigating } from 'bb/app/router/useIsNavigating';
import { useRouter } from 'bb/app/router/useRouter';

const debug = Debug('useOnPopState');

export function useOnPopState(
    handlePopState: CallableFunction,
    route: Route
): void {
    const { router } = useRouter();
    const isNavigating = useIsNavigating();

    useEffect(() => {
        router.beforePopState(({ url, as }) => {
            if (url === route.href && !isNavigating) {
                debug.info(`router pop state matched ${as}`);
                handlePopState();
            }
            return false;
        });
    }, [handlePopState, route, router, isNavigating]);
}
