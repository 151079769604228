import { type GetByKey, type WithRequired } from 'bb/common/types';
import { type FilteredPathKey, type NextApiPaths } from '../../types';
import { type Merge, type UseApiOptions } from './useApi.types';
import { useApi } from './useApiHook';

/**
 *
 * @param pathname pathname to the endpoint
 * @param defaultApiOptions default options passed to useApi
 */
export const createUseApiHook =
    <
        ResponseOverride,
        Path extends FilteredPathKey<'get'> = FilteredPathKey<'get'>,
        Scheme extends GetByKey<NextApiPaths[Path], 'get'> = GetByKey<
            NextApiPaths[Path],
            'get'
        >,
        Response = ResponseOverride extends object
            ? ResponseOverride
            : Scheme extends { response: infer SchemeResponse }
              ? SchemeResponse
              : unknown,
        PathQueryParams = Scheme extends { parameters: infer Params }
            ? Params extends { path: infer PathParams }
                ? PathParams
                : 'no-params'
            : 'no-params',
        OptionalQueryParams = Scheme extends { parameters: infer Params }
            ? Params extends { query?: infer Query }
                ? Query
                : 'no-params'
            : 'no-params',
        RequiredQueryParams = Scheme extends { parameters: infer Params }
            ? Params extends { query: infer Query }
                ? Query
                : 'no-params'
            : 'no-params',
        AllParams = Merge<
            PathQueryParams,
            OptionalQueryParams,
            RequiredQueryParams
        >,
        DefaultOptions extends UseApiOptions<
            Response,
            AllParams
        > = UseApiOptions<Response, AllParams>,
        Options extends UseApiOptions<Response, AllParams> = UseApiOptions<
            Response,
            AllParams
        >
    >(
        pathname: Path,
        defaultOptions?: DefaultOptions
    ) =>
    (
        ...args: PathQueryParams & RequiredQueryParams extends object
            ? [options: WithRequired<Options, 'query'>]
            : [options?: Options]
    ) =>
        useApi<Response, 'generic'>(pathname, {
            ...defaultOptions,
            ...args[0]
        });
