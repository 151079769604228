import { getCookieValue } from 'bb/cookies/utils';
import pkg from '../../package.json';

const { version } = pkg;

type DeviceInfo = {
    device: string;
    deviceplatform: string;
    deviceos: string;
    deviceid: string;
    appversion: string;
    timestamp: string;
};

export const getDeviceInfo = (): DeviceInfo => {
    let device = 'unknown';
    let deviceos = 'unknown';
    if (typeof window !== 'undefined') {
        device = window.navigator.userAgent || 'unknown';
        deviceos = window.navigator.platform || 'unknown';
    }
    return {
        device,
        deviceplatform: 'web',
        deviceos,
        deviceid: 'unknown',
        appversion: version,
        timestamp: new Date().toISOString()
    };
};

export const getConsentCookieValue = () =>
    new URLSearchParams(getCookieValue('OptanonConsent')).get('groups') ?? '';
