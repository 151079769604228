import { type TFunction } from 'bb/i18n';
import { isWithinRange } from 'bb/utils';

export const getStrengthBorderColor = (strength: number | null) => {
    if (strength == null) return undefined;

    if (isWithinRange(strength, { max: 0 })) {
        return 'secondary-red';
    }

    if (isWithinRange(strength, { min: 1, max: 2 })) {
        return 'secondary-yellow';
    }

    return 'secondary-green';
};

export const getDefaultMessages = (t: TFunction<'inputFields'>) =>
    [
        t('inputFields:infoTooShortCreatePassword'),
        t('inputFields:infoWeakCreatePassword'),
        t('inputFields:infoOkCreatePassword'),
        t('inputFields:infoStrongCreatePassword'),
        t('inputFields:infoVeryStrongCreatePassword')
    ] as const;
