import { useMemo, useContext } from 'react';
import { AppContext } from 'bb/app/AppContext';
import { Debug } from 'bb/app/debug';
import { TrackingContext } from 'bb/tracker/contexts';
import type { TrackingStrategy } from 'bb/tracker/types';
import { ms2s } from 'bb/utils';

type RegistrationTracker = {
    userRegistrationComplete(uid: number, email: string): void;
    downloadApp(platform: string): void;
    userActivationComplete(email: string): void;
    userTrialRefused(): void;
    userGeoBlocked(): void;
    userActivationDuration(duration: number, activationType: string): void;
    userDetails(): void;
    selectSubscription(): void;
    paymentDetails(): void;
    subscriptionProductSelected(product: string): void;
    trackLead(): void;
    scrollToCta(button: string, location: string): void;
    activatedCta(buttonType: string): void;
    triggeredRecaptcha(location: string): void;
};

const debug = Debug('useRegistrationTracker');

const registrationTracker = (
    type: string,
    tracker?: TrackingStrategy
): RegistrationTracker => ({
    userRegistrationComplete: (uid, email): void => {
        tracker?.track({ event: 'uid_set', uid });
        tracker?.track({
            event: 'account_created',
            email
        });
        debug.info(`${type} - user registration complete`);
    },
    userActivationComplete: (email): void => {
        tracker?.track({
            event: 'activationComplete',
            category: type,
            email
        });
        debug.info(`${type} - user activation complete`);
    },
    userTrialRefused: (): void => {
        tracker?.track({
            event: 'trialRefused',
            category: type
        });
        debug.info(`${type} - user was refused period`);
    },
    userGeoBlocked: (): void => {
        tracker?.track({
            event: 'geoBlocked',
            category: type
        });
        debug.info(`${type} - user was geo blocked`);
    },
    userActivationDuration: (ms, activationType) => {
        const duration = ms2s(ms);
        tracker?.track({
            event: 'activationDuration',
            category: type,
            duration,
            activationType
        });
        debug.info(`${type} - activation duration was ${duration}`);
    },
    downloadApp: (platform): void => {
        tracker?.track({
            event: 'downloadApp',
            action: 'downloadApp',
            label: platform,
            category: type
        });
        debug.info(`${platform} - download app`);
    },
    userDetails: (): void => {
        tracker?.track({
            event: 'userDetails',
            action: 'user details',
            category: type
        });
    },
    selectSubscription: (): void => {
        tracker?.track({
            event: 'chooseSubscriptionType',
            action: 'choose subscription type',
            category: type
        });
    },
    paymentDetails: (): void => {
        tracker?.track({
            event: 'paymentDetails',
            action: 'payment details',
            category: type
        });
    },
    subscriptionProductSelected: (product): void => {
        tracker?.track({
            event: 'subscription_type_selected',
            subscription_type: product
        });
    },
    trackLead: (): void => {
        tracker?.track({
            event: 'lead',
            action: 'lead',
            category: type
        });
    },
    scrollToCta: (button, location) => {
        tracker?.track({
            event: 'cta_click',
            button,
            location
        });
    },
    activatedCta: (buttonType) => {
        tracker?.track({
            event: 'button',
            button_type: buttonType
        });
    },
    triggeredRecaptcha: (location) => {
        tracker?.track({
            event: 'triggered_recaptcha',
            location
        });
    }
});

export const useRegistrationTracker = (): RegistrationTracker => {
    const { trackingStrategy } = useContext(AppContext);
    const { context } = useContext(TrackingContext);
    return useMemo(
        () => registrationTracker(context, trackingStrategy),
        [context, trackingStrategy]
    );
};
