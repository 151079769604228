import {
    type TFunctionReturnOptionalDetails,
    type KeyPrefix,
    type TFunctionReturn,
    type TOptions
} from 'i18next';
/**
 * For some reason this import cannot be resolved by ESLint, but it works
 * fine importing it.
 */
// eslint-disable-next-line import/no-unresolved
import { type $Dictionary } from 'i18next/typescript/helpers';
import { useTranslation as importedUseTranslation } from 'react-i18next';
import {
    type UseTranslationResponse,
    type UseTranslationOptions
} from 'react-i18next';
import { type UnionOf } from 'bb/common/types';
import { type Namespace } from './types';

type Tuple<T> = readonly T[];

export type TranslationPrefix<TNamespace extends Namespace = Namespace> =
    `${TNamespace}:${string}`;

export { Trans } from 'react-i18next';

export type TFunction<
    TNamespace extends
        | Namespace
        | Tuple<Namespace>
        | string
        | Tuple<string> = string,
    TKeyPrefix extends KeyPrefix<TNamespace> = KeyPrefix<TNamespace>,
    TOpt extends TOptions = TOptions,
    Ret extends TFunctionReturn<TNamespace, TKeyPrefix, TOpt> = TFunctionReturn<
        TNamespace,
        TKeyPrefix,
        TOpt
    >,
    ActualOptions extends TOpt = TOpt,
    NamespaceUnion = TNamespace extends readonly unknown[]
        ? UnionOf<TNamespace>
        : TNamespace,
    Key = NamespaceUnion extends Namespace
        ? TranslationPrefix<NamespaceUnion>
        : NamespaceUnion
> = (
    ...args:
        | [key: Key, options?: ActualOptions]
        | [key: Key, options: TOpt & $Dictionary & { defaultValue: string }]
        | [key: Key, defaultValue: string, options?: TOpt & $Dictionary]
) => TFunctionReturnOptionalDetails<Ret, TOpt>;

export const useTranslation = <
    TNamespace extends Namespace | Tuple<Namespace>,
    TKeyPrefix extends KeyPrefix<TNamespace>
>(
    ns?: TNamespace,
    options?: UseTranslationOptions<TKeyPrefix>
): Omit<UseTranslationResponse<TNamespace, TKeyPrefix>, 't'> & {
    t: TFunction<TNamespace, TKeyPrefix, TOptions>;
} => importedUseTranslation<TNamespace, TKeyPrefix>(ns, options);
