/* eslint-disable no-shadow */
/*
https://codeburst.io/alternative-to-javascripts-switch-statement-with-a-functional-twist-3f572787ba1c
The match function solves all of the issues using switch in a declarative code:
- It acts as a case-sensitive mapping between the input and output values
- It enforces the default case, providing total coverage
- It is an expression, so it can be assigned, passed around, and so on
- It does not introduce syntax bloat
- Each case has its own scope (because it’s a function)
*/

const matched = <T>(x: T) => ({
    on: () => matched(x),
    otherwise: () => x
});

export const match = <T>(x: T) => ({
    on: <P, V>(pred: (x: T) => P, fn: (x: T) => V) =>
        pred(x) ? matched(fn(x)) : match(x),
    otherwise: <V>(fn: (x: T) => V) => fn(x)
});

/**
 * check if any or all of the values are equal to an expression
 */
export const is = <T, A extends T[]>(
    expr: T,
    type: 'anyOf' | 'allOf',
    ...values: A
): expr is A[number] =>
    values.length
        ? values[type === 'anyOf' ? 'some' : 'every']((x) => x === expr)
        : false;
