import React, { type FC } from 'react';
import { type Store } from './types';

const createHtml = (
    name: string,
    state: Record<string, unknown>
) => `(function(w){
    const state = w.__BBSTATE || {};
    state['${name}'] = ${JSON.stringify(state)};
    w.__BBSTATE = state;
})(window);`;

export const Dehydrate: FC<{ store: Store }> = React.memo(({ store }) => {
    if (!store?.dehydrate) return null;

    const [name, state] = store.dehydrate();

    return (
        <script
            key={name}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: createHtml(name, state) }}
        />
    );
});

/* Should probably not be used :) */
export const DehydrateLocalState: FC<{
    name: string;
    state: Record<string, unknown>;
}> = React.memo(({ name, state }) => {
    if (!(name && state)) return null;

    return (
        <script
            key={name}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: createHtml(name, state) }}
        />
    );
});
