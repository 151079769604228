import { useMutation } from 'bb/api/browser';
import { getCookieValue } from 'bb/cookies/utils';
import { type AnalyticsEventSource } from '../types';
import { getDeviceInfo, getConsentCookieValue } from '../utils';

export const CancellationOfferActions = {
    SEEN: 'Seen cancellation offer',
    DECLINE: 'Decline cancellation offer'
} as const;

type CancellationOfferAction =
    (typeof CancellationOfferActions)[keyof typeof CancellationOfferActions];

export const useAnalyticsEvent = (source: AnalyticsEventSource) => {
    const { trigger } = useMutation('post', '/api/analytics/event');

    return {
        /*
         * Track a user's cookie consents after creating account
         */
        trackUsersCookieConsent() {
            trigger({
                source,
                category: 'signup',
                action: 'user cookie consent',
                label: getConsentCookieValue(),
                ...getDeviceInfo()
            });
        },
        /*
         * Track a user's GA client ID and whether they signed up through an affiliate
         */
        trackSignupComplete() {
            const affiliateCookie = getCookieValue('bb_affiliate');
            const affiliateDataCookie = getCookieValue('bb_affiliate_data');
            const gaCookie = getCookieValue('_ga');

            if (gaCookie) {
                trigger({
                    source,
                    category: 'signup',
                    action: 'GA client ID',
                    label: gaCookie,
                    ...getDeviceInfo()
                });
            }
            if (affiliateCookie) {
                trigger({
                    source,
                    category: 'signup',
                    action: 'affiliate',
                    label: affiliateDataCookie
                        ? `${affiliateCookie}-${affiliateDataCookie}`
                        : affiliateCookie,
                    ...getDeviceInfo()
                });
            }
        },
        /*
         * Track if a user has seen a cancellation offer and whether they decline the offer
         */
        trackCancellationOffer(
            action: CancellationOfferAction,
            campaignId: number
        ) {
            trigger({
                source,
                category: 'Cancel Account',
                action,
                label: campaignId.toString(),
                ...getDeviceInfo()
            });
        }
    };
};
