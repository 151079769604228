// @ts-strict-ignore
import React, { useState } from 'react';
import classnames from 'classnames';
import { Row } from '../Flex';
import { CrossIcon } from '../Icons';
import { type MarginProps, getMargins } from '../Margin';
import { type PaddingProps } from '../Padding';
import { getPaddings } from '../Padding/getPaddings';
import css from './notification.module.scss';

export type NotificationProps = {
    absolute?: boolean;
    type: 'alert' | 'warning' | 'info';
    onClose?: () => void;
    closeIcon?: boolean;
    children?: React.ReactNode;
} & PaddingProps &
    MarginProps;
export const Notification: React.FC<NotificationProps> = ({
    absolute,
    type,
    children,
    onClose,
    closeIcon,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    padding,
    paddingTop,
    paddingRight,
    paddingLeft,
    paddingBottom
}) => {
    const [show, setShow] = useState(true);
    const showIcon = closeIcon || onClose;
    if (!show) {
        return null;
    }
    const onClick = (): void => {
        onClose?.();
        setShow(false);
    };
    return (
        <div
            data-testid="notification"
            className={classnames(
                css.base,
                css[type],
                getMargins({
                    margin,
                    marginLeft,
                    marginRight,
                    marginBottom,
                    marginTop
                }),
                getPaddings({
                    padding,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    paddingTop
                }),
                {
                    [css.absolute]: absolute
                }
            )}
        >
            <Row justifyContent="spaceBetween">
                {children}
                {showIcon && (
                    <div className={css.icon}>
                        <CrossIcon
                            size="small"
                            color={
                                type !== 'warning'
                                    ? 'primary-white'
                                    : 'primary-black'
                            }
                            onClick={onClick}
                        />
                    </div>
                )}
            </Row>
        </div>
    );
};
