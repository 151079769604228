import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const ShareIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#a)">
                <path d="M10.537 11.84V3.622l-.09-1.114 1.833 1.886.761-.772L9.911.5 6.728 3.622l.812.772 1.822-1.886-.096 1.116v8.217l1.271-.001Zm6.284 6.127V5.837h-4.607l.02 1.24 3.285-.001v9.57H4.265V7.1h3.318l.032-1.261H3v12.13h13.82l.001-.002Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path transform="translate(0 .5)" d="M0 0h20v20H0z" />
                </clipPath>
            </defs>
        </svg>
    </Icon>
);
