export const staticPagesRoutes = {
    cookies: {
        name: 'cookies',
        href: '/[market]/cookies'
    },
    about: {
        name: 'about',
        href: '/[market]/about'
    },
    contact: {
        name: 'contact',
        href: '/[market]/contact'
    },
    school: {
        name: 'school',
        href: '/[market]/contact/school'
    },
    sitemap: {
        name: 'sitemap',
        href: '/[market]/sitemap'
    },
    cancel: {
        name: 'cancel',
        href: '/[market]/account/cancel'
    },
    privacyNotice: {
        name: 'privacy-notice',
        href: '/[market]/privacy-notice'
    },
    terms: {
        name: 'terms',
        href: '/[market]/terms'
    },
    giftcardTerms: {
        name: 'giftcardTerms',
        href: '/[market]/giftcard-terms'
    },
    forgottenPassword: {
        name: 'forgottenPassword',
        href: '/[market]/forgotten-password'
    },
    student: {
        name: 'student',
        href: '/[market]/student'
    },
    welcome: {
        name: 'welcome',
        href: '/[market]/welcome'
    },
    reactivate: {
        name: 'reactivate',
        href: '/[market]/reactivate'
    },
    campaignTerms: {
        name: 'campaignTerms',
        href: '/[market]/campaign/terms'
    },
    loginAndCompletePayment: {
        name: 'loginAndCompletePayment',
        href: '/[market]/adyen/login'
    },
    summaryPrivacyPolicy: {
        name: 'summaryPrivacyPolicy',
        href: '/[market]/your-privacy'
    },
    userlab: {
        name: 'userlab',
        href: '/[market]/userlab'
    },
    topUpSuccess: {
        name: 'topUpSuccess',
        href: '/[market]/account/subscription/top-up/success'
    },
    partners: {
        name: 'partners',
        href: '/[market]/partners'
    },
    contest: {
        name: 'contest',
        href: '/[market]/emailraffle'
    },
    environmentalImpact: {
        name: 'environmentalImpact',
        href: '/[market]/environmental-impact'
    },
    notFound: {
        name: 'notFound',
        href: '/404'
    },
    selectMarket: {
        name: 'selectMarket',
        href: '/market'
    },
    magicLinkCannotBeOpened: {
        name: 'magicLinkCannotBeOpened',
        href: '/magiclink-cannot-be-opened'
    },
    subcontractors: {
        name: 'subcontractors',
        href: '/[market]/subcontractors'
    },
    familyaccount: {
        name: 'familyaccount',
        href: '/[market]/familyaccount'
    }
} as const;

export const routes = {
    start: {
        name: 'start',
        href: '/[market]'
    },
    login: {
        name: 'login',
        href: '/[market]/login'
    },
    promocode: {
        name: 'promocode',
        href: '/[market]/promocode'
    },
    recruitLanding: {
        name: 'recruitAFriend',
        href: '/[market]/recruit-a-friend'
    },
    redeemGiftcard: {
        name: 'redeemGiftcard',
        href: '/[market]/giftcards/redeem'
    },
    buyGiftcards: {
        name: 'buyGiftcards',
        href: '/[market]/giftcards'
    },
    selectGiftcardPeriod: {
        name: 'selectGiftcardPeriod',
        href: '/[market]/giftcards/select-period'
    },
    giftcardPayment: {
        name: 'giftcardPayment',
        href: '/[market]/giftcards/payment'
    },
    giftcardAwaiting: {
        name: 'giftcardAwaiting',
        href: '/[market]/giftcards/payment/awaiting'
    },
    giftcardComplete: {
        name: 'giftcardAwaiting',
        href: '/[market]/giftcards/payment/complete/[merchantReference]'
    },
    cancelSubscription: {
        name: 'cancelSubscription',
        href: '/[market]/cancel-subscription'
    },
    // Account
    overview: {
        name: 'overview',
        href: '/[market]/account'
    },
    paymenthistory: {
        name: 'paymenthistory',
        href: '/[market]/account/payment-history'
    },
    settings: {
        name: 'settings',
        href: '/[market]/account/settings'
    },
    receipt: {
        name: 'receipt',
        href: '/[market]/account/receipt/[paymentReference]'
    },
    recruitAFriend: {
        name: 'recruit',
        href: '/[market]/account/recruit'
    },
    devices: {
        name: 'devices',
        href: '/[market]/account/devices'
    },
    accountDetails: {
        name: 'accountDetails',
        href: '/[market]/account/details'
    },
    campaigns: {
        name: 'campaigns',
        href: '/[market]/account/redeem-campaign'
    },
    press: {
        name: 'press',
        href: '/[market]/press'
    },
    profiles: {
        name: 'profiles',
        href: '/[market]/account/profiles'
    },
    giftcards: {
        name: 'giftcards',
        href: '/[market]/account/redeem-giftcard'
    },
    myhours: {
        name: 'myhours',
        href: '/[market]/account/consumption'
    },
    subscription: {
        name: 'subscription',
        href: '/[market]/account/subscription'
    },
    myhistory: {
        name: 'history',
        href: '/[market]/account/my-data'
    },
    inbox: {
        name: 'inbox',
        href: '/[market]/account/inbox'
    },
    recruitafriend: {
        name: 'recruitafriend',
        href: '/[market]/account/recruit-a-friend'
    },
    myaccount: {
        name: 'myaccount',
        href: '/[market]/account/my-account'
    },
    activateaccount: {
        name: 'activateaccount',
        href: '/[market]/account/activate-account'
    },
    // Discovery
    books: {
        name: 'books',
        href: '/[market]/audiobooks'
    },
    categories: {
        name: 'categories',
        href: '/[market]/categories'
    },
    category: {
        name: 'category',
        href: '/[market]/category/[id]'
    },
    search: {
        name: 'search',
        href: '/[market]/search'
    },
    book: {
        name: 'book',
        href: '/[market]/book/[bookId]'
    },
    bookBeta: {
        name: 'book',
        href: '/[market]/beta/book/[bookId]'
    },
    bookList: {
        name: 'booklist',
        href: '/[market]/booklist/[id]'
    },
    series: {
        name: 'series',
        href: '/[market]/series/[seriesId]'
    },
    authorPage: {
        name: 'author',
        href: '/[market]/authors/[id]'
    },
    narratorPage: {
        name: 'narrator',
        href: '/[market]/narrators/[id]'
    },
    author: {
        name: 'author',
        href: '/[market]/search/author/[author]'
    },
    narrator: {
        name: 'narrator',
        href: '/[market]/search/narrator/[narrator]'
    },
    badge: {
        name: 'badge',
        href: '/[market]/badge/[id]'
    },
    // bbLink
    partner: {
        name: 'partner',
        href: '/[market]/partner/[partner]'
    },
    verifyPartner: {
        name: 'verifyPartner',
        href: '/[market]/partner/[partner]/verify'
    },
    partnerSubscription: {
        name: 'partnerSubscription',
        href: '/[market]/partner/[partner]/subscription'
    },
    partnerWelcome: {
        name: 'partnerWelcome',
        href: '/[market]/partner/[partner]/welcome'
    },
    bookCheckout: {
        name: 'bookCheckout',
        href: '/[market]/book/[bookId]/checkout'
    },
    bookAwaiting: {
        name: 'bookAwaiting',
        href: '/[market]/book/[bookId]/awaiting'
    },
    bookComplete: {
        name: 'bookComplete',
        href: '/[market]/book/[bookId]/complete/[merchantReference]'
    },
    landingPage: {
        name: 'landingPage',
        href: '/[market]/[landingpage]'
    },
    strawberry: {
        name: 'strawberry',
        href: '/[market]/strawberry'
    },
    'e-books': {
        name: 'e-books',
        href: '/[market]/e-books'
    },
    registration: {
        name: 'registration',
        href: '/[market]/registration'
    },

    // Static pages
    ...staticPagesRoutes
} as const;

export type StaticPagesRoutes = typeof staticPagesRoutes;

export type Routes = typeof routes;

export type Route = keyof Routes;

export type RouteHref = Routes[keyof Routes]['href'];
