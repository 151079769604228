import slugify from 'slugify';

export const getDiscoveryUrlSlug = ({
    title = '',
    id = ''
}: {
    title?: string;
    id?: string | number;
}) => {
    // make sure slugify doesn't translate certain characters.
    // List can be found on https://github.com/simov/slugify/blob/master/config/locales.json
    const titleRemovedAndPercentage = title.replace(/&|%|£|\$|>|<|€/g, '');
    return slugify(`${titleRemovedAndPercentage}-${id.toString()}`, {
        lower: true,
        strict: true
    }).replace('--', '-');
};
