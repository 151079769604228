// @ts-strict-ignore
import classnames from 'classnames';
import {
    marginCss,
    marginBottomCss,
    marginTopCss,
    marginLeftCss,
    marginRightCss
} from './marginCss';
import { type MarginProps } from './types';

export const getMargins = (margins: MarginProps): string =>
    classnames({
        [marginTopCss[margins.marginTop as string]]: Boolean(margins.marginTop),
        [marginLeftCss[margins.marginLeft as string]]: Boolean(
            margins.marginLeft
        ),
        [marginRightCss[margins.marginRight as string]]: Boolean(
            margins.marginRight
        ),
        [marginBottomCss[margins.marginBottom as string]]: Boolean(
            margins.marginBottom
        ),
        [marginCss[margins.margin as string]]: Boolean(margins.margin)
    });
