import React, { type FC } from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const SmileyIcon: FC<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6852 12.4866L14.9499 12.043L14.0629 11.5137L13.7982 11.9572C13.0428 13.2228 11.8158 13.8799 9.99993 13.8799C8.18411 13.8799 6.95705 13.2228 6.20171 11.9572L5.93701 11.5137L5.04998 12.043L5.31468 12.4866C6.28972 14.1203 7.89046 14.9129 9.99993 14.9129C12.1094 14.9129 13.7101 14.1203 14.6852 12.4866Z"
                fill="black"
            />
            <path
                d="M6.53307 7.19797C6.42668 7.26889 6.32011 7.38807 6.22762 7.5896L6.01219 8.05903L5.07334 7.62817L5.28877 7.15874C5.44347 6.82164 5.66248 6.53684 5.96007 6.33846C6.25947 6.13886 6.604 6.04973 6.97011 6.04973C7.33623 6.04973 7.68077 6.13886 7.98017 6.33846C8.27776 6.53685 8.49676 6.82165 8.65145 7.15875L8.86688 7.62818L7.92802 8.05902L7.71259 7.58959C7.62011 7.38806 7.51354 7.26888 7.40716 7.19796C7.30259 7.12825 7.16476 7.08273 6.97011 7.08273C6.77547 7.08273 6.63765 7.12825 6.53307 7.19797Z"
                fill="black"
            />
            <path
                d="M12.2872 7.58959C12.3796 7.38806 12.4862 7.26888 12.5926 7.19796C12.6971 7.12825 12.835 7.08273 13.0296 7.08273C13.2243 7.08273 13.3621 7.12825 13.4667 7.19797C13.5731 7.26889 13.6796 7.38807 13.7721 7.58959L13.9875 8.05902L14.9264 7.62818L14.711 7.15875C14.5563 6.82163 14.3373 6.53684 14.0397 6.33845C13.7403 6.13886 13.3957 6.04973 13.0296 6.04973C12.6635 6.04973 12.319 6.13886 12.0196 6.33846C11.722 6.53685 11.503 6.82165 11.3483 7.15875L11.1329 7.62818L12.0717 8.05902L12.2872 7.58959Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1C5.02943 1 1 5.02943 1 10C1 14.9705 5.02943 19 10 19C14.9705 19 19 14.9705 19 10C19 5.02943 14.9705 1 10 1ZM2.033 10C2.033 5.59994 5.59994 2.033 10 2.033C14.4 2.033 17.967 5.59994 17.967 10C17.967 14.4 14.4 17.967 10 17.967C5.59994 17.967 2.033 14.4 2.033 10Z"
                fill="black"
            />
        </svg>
    </Icon>
);
