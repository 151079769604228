import React from 'react';
import { Text } from 'bb/i18n';
import { useTranslationsFromDiscovery } from '../hooks/useTranslationsFromDiscovery';
import { BadgeV2 } from './BaseBadge';
import { type BadgeProps } from './BaseBadge/BadgeV2';
import css from './BaseBadge/badgeV2.module.scss';

export type BookBadgeProps = {
    translationKey: string;
} & Pick<BadgeProps, 'icon'>;

export const BookBadge = (props: BookBadgeProps) => {
    const { translationKey, icon } = props;
    const text = useTranslationsFromDiscovery(translationKey);

    return (
        <BadgeV2 icon={icon}>
            <Text as="span" variant="body3" className={css.text} aria-hidden>
                {text}
            </Text>
        </BadgeV2>
    );
};
