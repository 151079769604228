import { createUseApiHook } from 'bb/api/browser/useApi';
import { useTrackingStrategy } from 'bb/tracker/hooks/useTrackingStrategy';
import { useAccountStore } from './useAccountStore';
import { useIsLoggedIn } from './useVerifyLoggedIn';

export const useUser = () => {
    const accountStore = useAccountStore();
    const { track } = useTrackingStrategy();

    const hook = createUseApiHook('/api/users', {
        onData: (result) => {
            track({ event: 'uid_set', uid: result.userid });
            accountStore.setUser({
                userId: result.userid as number,
                firstName: result.firstname,
                lastName: result.lastname,
                email: result.email,
                displayName: result.displayname
            });
        }
    })({ enabled: useIsLoggedIn() });

    return Object.assign(hook, { user: hook.data });
};
