import marginCss from './margin.module.scss';
import marginBottomCss from './marginBottom.module.scss';
import marginLeftCss from './marginLeft.module.scss';
import marginRightCss from './marginRight.module.scss';
import marginTopCss from './marginTop.module.scss';

export {
    marginCss,
    marginLeftCss,
    marginRightCss,
    marginTopCss,
    marginBottomCss
};
