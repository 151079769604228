import { isValidMarket } from 'bb/config/marketConfig';
import { Cookies, SIX_MONTHS } from 'bb/cookies/config';
import { getCookieValue, setCookieValue } from 'bb/cookies/utils';
import { isBrowser } from 'bb/utils/environment';

/*
 * If the market cookie differs from the market the user is visiting,
 * for example when changing market and getting a cached page (not getting bb_market cookie refreshed on the server),
 * then we try setting the new market in cookies, if it does not work we reload the page with refreshmarket=true,
 * which will bypass cache and hit our servers.
 */
export const updateMarketCookie = (trySetCookie = true) => {
    if (!isBrowser()) return;

    const pathname = window?.location?.pathname;
    const marketFromPath = pathname?.split('/')[1];

    /*
     * Return early if no valid market in path, for example on /market
     */
    if (!isValidMarket(marketFromPath)) return;

    /*
     * Get the market cookie and compare it to the market in path.
     * If they differ, set the market in cookies.
     */
    let marketCookie = getCookieValue(Cookies.MARKET_COOKIE);

    if (marketCookie !== marketFromPath) {
        trySetCookie &&
            setCookieValue({
                name: Cookies.MARKET_COOKIE,
                value: marketFromPath,
                maxAge: SIX_MONTHS
            });
    }

    /*
     * Get the market cookie again and compare it to the market in path.
     * If they still differ, reload the page with refreshmarket=true.
     */
    marketCookie = getCookieValue(Cookies.MARKET_COOKIE);

    if (marketCookie !== marketFromPath) {
        const url = new URL(window.location.href);
        url.searchParams.append('refreshmarket', 'true');
        window.location.href = url.href;
    }
};
