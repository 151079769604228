export type { MarginSize, MarginProps } from './types';

export {
    marginCss,
    marginLeftCss,
    marginRightCss,
    marginTopCss,
    marginBottomCss
} from './marginCss';

export { getMargins } from './getMargins';
