import React from 'react';
import css from './formCols.module.scss';

type FormColsProps = {
    children?: React.ReactNode;
};
export const FormCols: React.FC<FormColsProps> = ({ children }) => (
    <div className={css.base}>
        {React.Children.map(children, (child) => (
            <div style={{ width: `${100 / React.Children.count(children)}%` }}>
                {child}
            </div>
        ))}
    </div>
);
