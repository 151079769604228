import { useApi } from 'bb/api/browser/useApi';
import { useUser } from './useUser';

export const useIsPrimaryProfile = () => {
    const userResponse = useUser();
    const profilesResponse = useApi('/api/my/account/profiles/all', {
        enabled: Boolean(userResponse.user)
    });

    const primaryProfile = profilesResponse.data?._embedded.profiles.find(
        (profile) => profile.isprimary
    );

    return {
        data: Boolean(
            primaryProfile &&
                userResponse.user &&
                primaryProfile.id === userResponse.user.userid
        ),
        isLoading: userResponse.isLoading || profilesResponse.isLoading
    };
};
