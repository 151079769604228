import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const BackIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M12.095 17.268c1.174 0 2.172-.21 2.997-.631.824-.42 1.454-1.015 1.888-1.785.435-.769.652-1.665.652-2.69 0-1.017-.223-1.915-.668-2.693-.446-.777-1.09-1.385-1.934-1.822-.844-.437-1.861-.655-3.05-.655H6.881l-1.825.077 1.427-1.215 2.133-2.083L7.497 2.7 2.4 7.772l5.097 5.063 1.133-1.02L6.484 9.69 5.052 8.478l1.83.082h5.18c.869 0 1.6.15 2.196.449.595.298 1.044.713 1.349 1.245.304.531.456 1.142.456 1.834 0 .697-.152 1.319-.456 1.864-.305.545-.754.974-1.35 1.286-.594.313-1.326.47-2.195.47h-1.818v1.56h1.851Z"
                fillRule="nonzero"
            />
        </svg>
    </Icon>
);
