import dynamic from 'next/dynamic';
import { withFeatureFlaggedComponent } from 'bb/page/withFeatureFlaggedComponent';

export const SideMenu = withFeatureFlaggedComponent(
    dynamic(() => import('./SideMenu').then((mod) => mod.SideMenu), {
        ssr: false
    }),
    dynamic(() => import('./SideMenuV2').then((mod) => mod.SideMenu), {
        ssr: false
    }),
    'my-account-nav-drawer'
);
