import React, { type FunctionComponent } from 'react';
import { useAccountStore } from 'bb/account/hooks/useAccountStore';
import { useRouter } from 'bb/app/router';
import { findRouteByPath } from 'bb/app/router/utils';
import { observer } from 'bb/app/stores';
import { SignupButton } from 'bb/common/components/buttons/SignupButton';
import { useTranslation } from 'bb/i18n';
import { Row } from 'bb/ui';
import { MenuItems } from './MenuItems';
import css from './navbar.module.scss';
import { type Link, type MenuProps } from './types';

export const CenterMenu: FunctionComponent<MenuProps> = observer(
    ({ showGiftCard, showStudent, inverted = false }) => {
        const { t } = useTranslation(['nav']);
        const { routes, router } = useRouter();
        const { isLoggedIn } = useAccountStore();

        const centerLinks: Link[] = [
            {
                label: t('nav:books'),
                route: routes.books,
                matchSub: true
            },
            {
                label: t('nav:categories'),
                route: routes.categories,
                matchSub: true
            },
            {
                label: t('nav:search'),
                route: routes.search,
                matchSub: true
            }
        ];

        if (showGiftCard) {
            centerLinks.push({
                label: t('nav:buyGiftCards'),
                route: routes.buyGiftcards,
                matchSub: true
            });
        }

        if (showStudent) {
            centerLinks.push({
                label: t('nav:student'),
                route: routes.student
            });
        }

        return (
            <>
                {isLoggedIn || router.query?.wb ? null : (
                    <SignupButton
                        name="mobile-center-menu-signup-button"
                        className={css.mobileCta}
                        data-testid="center-menu-cta"
                    >
                        {t('nav:registration')}
                    </SignupButton>
                )}

                <Row
                    justifyContent="center"
                    alignItems="center"
                    className={css.desktopNavbar}
                >
                    <div
                        className={css.horizontal}
                        data-testid="center-menu-list"
                    >
                        <MenuItems
                            links={centerLinks}
                            currentPath={findRouteByPath(router.pathname)?.href}
                            inverted={inverted}
                            sub
                        />
                    </div>
                </Row>
            </>
        );
    }
);
