import { useEffect, useRef } from 'react';
import { Debug } from 'bb/app/debug';
import { useMarket } from '../useMarket';
import { type Route } from './types';
import { useIsNavigating } from './useIsNavigating';
import { useRouter } from './useRouter';
import { getUrl, type Url } from './utils';

const debug = Debug('useShouldRedirect');

export function useShouldRedirect(
    shouldRedirectPredicate: () => boolean,
    route: Route,
    query = {}
): void {
    const { navigate } = useRouter();
    const isNavigating = useIsNavigating();
    const { market } = useMarket();

    /**
     * Prevent infinite loops which can cause memory
     * leaks due to setState being used inside
     * useEffect.
     */
    const nextUrl = getUrl(route.href, { ...query, market });
    const lastUrl = useRef<Url | null>(null);

    useEffect(() => {
        if (route?.href && !isNavigating && lastUrl.current !== nextUrl) {
            if (shouldRedirectPredicate()) {
                lastUrl.current = getUrl(route.href, { ...query, market });
                debug.info(`redirecting to ${route.href}`);
                navigate(route, query);
            }
        }
    }, [
        route,
        navigate,
        shouldRedirectPredicate,
        isNavigating,
        query,
        market,
        nextUrl
    ]);
}
