import { useApp } from 'bb/app/AppContext';
import { getCurrencyByMarket } from 'bb/config';
import { isDef } from 'bb/utils/assert';
import { shortSwitch } from 'bb/utils/shortSwitch';

export const msg = (
    passedCurrency: string,
    passedLocale: string,
    amount: number
) => {
    let minimumFractionDigits = 0;

    // Chrome not supporting nn-NO as a locale (where
    // currencuy is after amount) is forcing us to do
    // swedish formatting here
    const locale =
        shortSwitch(passedCurrency, ['CZK', 'cs-CZ'], ['NOK', 'sv-SE']) ??
        passedLocale;
    const currency =
        shortSwitch(passedCurrency, ['NOK', 'SEK']) ?? passedCurrency;

    if (amount?.toFixed(0) !== amount?.toString()) {
        minimumFractionDigits = 2;
    }

    return new Intl.NumberFormat(locale.replace('_', '-'), {
        minimumFractionDigits,
        currency,
        style: 'currency'
    });
};

export const useFormatHandler = () => {
    const { locale, market } = useApp();
    const currency = getCurrencyByMarket(market);

    return (amount: number) => msg(currency, locale, amount).format(amount);
};

export const useFormatAmount = (amount?: number) => {
    const formatter = useFormatHandler();
    if (!isDef(amount)) return null;
    return formatter(amount);
};
