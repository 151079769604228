import { useRef } from 'react';
import { getFullLocaleByLocale } from 'bb/config/marketConfigUtils';
import { useMutationObserver } from 'bb/ui/hooks/useMutationObserver';
import { isBrowser } from 'bb/utils';

/*
 * This hook sets the lang attribute of the html element to the initial locale from the server side props.
 * This is necessary because NextJS changes this to default locale (en) on client side navigation.
 */
export const useSetLang = (initialLocale = 'en') => {
    const initialFullLocale = useRef(
        getFullLocaleByLocale(initialLocale, {
            divider: '-'
        })
    );

    const htmlElement = useRef(
        isBrowser() ? document.querySelector('html') : null
    );

    const mutationCallback: MutationCallback = (mutationsList) => {
        mutationsList.forEach((mutation) => {
            if (
                mutation.type === 'attributes' &&
                mutation.attributeName === 'lang'
            ) {
                const currentLang = htmlElement.current?.getAttribute('lang');
                if (currentLang === initialFullLocale.current) return;

                htmlElement.current?.setAttribute(
                    'lang',
                    initialFullLocale.current
                );
            }
        });
    };

    useMutationObserver(htmlElement, mutationCallback, { attributes: true });
};
