import { type NextRouter } from 'next/router';
import { findRouteByPath } from './utils';

export const useRedirectTo = (route: NextRouter['route']) => {
    const existingRoute = findRouteByPath(route);
    if (existingRoute) {
        const redirectTo = existingRoute.href;
        return { redirectTo };
    }
    // return empty object which results in no broken query in url
    return {};
};
