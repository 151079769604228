// @ts-strict-ignore
import classnames from 'classnames';
import {
    paddingBottomCss,
    paddingCss,
    paddingLeftCss,
    paddingRightCss,
    paddingTopCss
} from './paddingCss';
import { type PaddingProps } from './types';

export const getPaddings = (paddings: PaddingProps): string =>
    classnames({
        [paddingTopCss[paddings.paddingTop as string]]: Boolean(
            paddings.paddingTop
        ),
        [paddingLeftCss[paddings.paddingLeft as string]]: Boolean(
            paddings.paddingLeft
        ),
        [paddingRightCss[paddings.paddingRight as string]]: Boolean(
            paddings.paddingRight
        ),
        [paddingBottomCss[paddings.paddingBottom as string]]: Boolean(
            paddings.paddingBottom
        ),
        [paddingCss[paddings.padding as string]]: Boolean(paddings.padding)
    });
