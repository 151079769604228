/* eslint-disable react/jsx-props-no-spreading */
import React, { type HTMLInputTypeAttribute } from 'react';
import classnames from 'classnames';
import css from './dummyInput.module.scss';

export type DummyInputInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type'
> & { type?: Extract<HTMLInputTypeAttribute, 'radio' | 'checkbox'> };

export type DummyInputProps = {
    render: (inputProps: DummyInputInputProps) => JSX.Element;
    labelProps?: React.HTMLProps<HTMLLabelElement> & {
        /**
         * Allow for passing data attributes which for some reason
         * aren't supported through React.InputHTMLAttributes.
         */
        [key: `data-${string}`]: string;
    };
} & DummyInputInputProps;

export const DummyInput = React.forwardRef<HTMLInputElement, DummyInputProps>(
    (
        {
            render,
            className,
            labelProps: { className: labelClassName, ...restLabelProps } = {},
            type = 'radio',
            hidden,
            ...restProps
        },
        ref
    ) => (
        // Wrap Dummy Radio in label since the label element also triggers
        // events ont the input.
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
            {...restLabelProps}
            className={classnames(css.root, labelClassName)}
            hidden={hidden}
        >
            <input
                ref={ref}
                {...restProps}
                className={classnames(css.input, className)}
                type={type}
                tabIndex={0}
            />
            {render({ ...restProps, type })}
        </label>
    )
);
