// @ts-strict-ignore
import { useMemo } from 'react';
import { simpleFormatter } from './formatters';
import { type Logger } from './types';
import { useLoggerStore } from './useLoggerStore';

export const useSimpleLogger = (): Logger => {
    const store = useLoggerStore();

    const logger = useMemo(
        () => ({
            info: (message: string, data: Record<string, unknown> = null) =>
                store.setMessage(
                    simpleFormatter({ message, ...data, level: 'info' })
                ),
            error: (message: string, data: Record<string, unknown> = null) =>
                store.setMessage(
                    simpleFormatter({ message, ...data, level: 'error' })
                )
        }),
        [store]
    );
    return logger;
};
