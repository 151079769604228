import { type SignupFlow } from 'bb/registration';
import { shortSwitch } from 'bb/utils';
import { useTrackingStrategy } from './useTrackingStrategy';

const signupFlowToTrackingMapper = (userType: SignupFlow): string =>
    shortSwitch(userType, ['EditionSingleSalesWeb', 'single_sales']) ??
    userType;

type Event =
    | 'view_create_account'
    | 'start_filling_last_name'
    | 'terms_and_conditions'
    | 'account_created'
    | 'uid_set';

type Payload<T extends Event> = T extends 'uid_set'
    ? {
          uid: string;
      }
    : never;

export const useSimpleSignupTracker = (signupFlow: SignupFlow) => {
    const { track } = useTrackingStrategy();
    const userType = signupFlowToTrackingMapper(signupFlow);

    return function tracker<T extends Event>(
        ...args: Payload<T> extends never
            ? [event: T]
            : [event: T, payload: Payload<T>]
    ) {
        const [event, payload = {}] = args;
        return track({
            event,
            ...(event !== 'uid_set' ? { user_type: userType } : undefined),
            ...payload
        });
    };
};

export type SimpleSignupTracker = ReturnType<typeof useSimpleSignupTracker>;
