import React from 'react';
import classnames from 'classnames';
import { type Either } from 'bb/common/types';
import { type Color } from 'bb/style/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { type IconSize } from '../types';
import css from './icons.module.scss';

export type BaseIconProps = {
    /**
     * If true, the icon will be displayed inline and
     * with margin 2px to the left and right.
     *
     * @defaultValue `false`
     * @deprecated Use a wrapper component to set spacing.
     */
    inline?: boolean;
    /**
     * Sets the color of the icon.
     *
     * @defaultValue `primary-black`
     */
    color?: Color;
    /**
     * Sets the size of the icon. If not set, the default size of the
     * svg will be used. If the icon is displayed inline, the default
     * size will be `small`.
     */
    size?: IconSize;
    /**
     * If true, the icon color will be inverted.
     *
     * @defautValue `false`
     * @deprecated Use the color prop to set the color.
     */
    invert?: boolean;
} & Omit<React.ComponentProps<'svg'>, 'children'>;

export type IconProps = Either<
    {
        /**
         * @deprecated Use the `icon` prop instead which allows
         * us to avoid React.cloneElement which is only used
         * for legacy purposes.
         */
        children: React.ReactElement;
    },
    {
        /**
         * A custom icon component.
         *
         * For the color to have effect we may need to
         * modify the fill property of the .svg file
         * to use `currentColor` as value. This makes sure
         * that the color is dynamic and inherited from
         * the color prop we set through css.
         *
         * @example
         * ```tsx
         * import CustomIcon from './custom-icon.svg';
         *
         * const iconComponent = <Icon icon={CustomIcon} />;
         * ```
         */
        icon: React.ComponentType;
    }
> &
    BaseIconProps;

export const Icon = (props: IconProps) => {
    const {
        children,
        icon: PassedIcon,
        inline,
        size = inline ? 'small' : undefined,
        className,
        color = 'primary-black',
        invert,
        style: passedStyle,
        ...restProps
    } = props;

    const iconProps: React.ComponentProps<'svg'> = {
        ...restProps,
        style: {
            ...passedStyle,
            ...makeCSSVariableFromColor('--icon-color', color)
        },
        className: classnames(
            css.base,
            size && css[size],
            color && css.color,
            inline && css.inline,
            invert && css.invert,
            className
        )
    } as const;

    if (PassedIcon) {
        return <PassedIcon {...iconProps} />;
    }

    /**
     * Old icons used to be wrapped in a span element where
     * the icon was passed as children. To keep compatibility
     * with old implementations we need to keep allowing children
     * to be passed.
     */
    return React.cloneElement(children, iconProps);
};
