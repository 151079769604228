import { useCallback } from 'react';
import useSWR from 'swr';
import { useRouter, useShouldRedirect } from 'bb/app/router';
import { type ReactivationOffer } from 'bb/campaigns/types';
import { useAccount } from './useAccount';

export const useRedirectReactivationOfferV2 = (
    isLoggedIn?: boolean,
    redirectTo?: string
) => {
    const { routes } = useRouter();
    const { data } = useSWR<ReactivationOffer>(
        isLoggedIn && !redirectTo
            ? '/api/accounts/offers/reactivation/active'
            : null
    );
    const { account } = useAccount({
        enabled: Boolean(isLoggedIn && !redirectTo)
    });

    const shouldRedirectPredicate = useCallback(
        () => Boolean(data && account && !redirectTo),
        [data, account, redirectTo]
    );

    useShouldRedirect(
        shouldRedirectPredicate,
        data?.offer?.isValid
            ? routes.subscription
            : routes[account?.isActivated ? 'welcome' : 'activateaccount']
    );
};
