import dynamic from 'next/dynamic';

export const BadgeV2 = dynamic(
    () => import('bb/ui/Badge/BaseBadge/BadgeV2').then((m) => m.BadgeV2),
    { ssr: true }
);

export const Badge = dynamic(
    () => import('bb/ui/Badge/BaseBadge/Badge').then((m) => m.Badge),
    { ssr: true }
);
