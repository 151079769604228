// @ts-strict-ignore
import React, { useContext } from 'react';
import { useLocalObservable } from 'bb/app/stores';
import { type FCChildrenOnly } from 'bb/common/types';
import { loggerStore } from './loggerStore';
import { type LoggerStore } from './types';

export const LoggerContext = React.createContext<LoggerStore | null>(null);

export const LoggerStoreProvider: FCChildrenOnly = ({ children }) => {
    const store = useLocalObservable(loggerStore);
    return (
        <LoggerContext.Provider value={store}>
            {children}
        </LoggerContext.Provider>
    );
};

export const useLoggerStore = (): LoggerStore => {
    const store = useContext<LoggerStore>(LoggerContext);

    if (!store) {
        throw new Error('useLoggerStore must be used within StoreProvider');
    }
    return store;
};
