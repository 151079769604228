import React from 'react';
import { type Locale } from 'bb/config/marketConfigUtils';
import { type Market } from 'bb/config/types';
import {
    type TrackingStrategy,
    type AnalyticsEventsStrategy
} from 'bb/tracker/types';

export type AppContextType = {
    trackingStrategy: TrackingStrategy;
    analyticsEventTrackingStrategy: AnalyticsEventsStrategy;
    market: Market;
    locale: Locale;
};

export const appContextDefaultValues: AppContextType = {
    trackingStrategy: {
        track: () => null
    },
    analyticsEventTrackingStrategy: {
        track: () => null
    },
    market: '' as Market,
    locale: 'en' as Locale
};

export const AppContext = React.createContext<AppContextType>(
    appContextDefaultValues
);

export const useApp = () => React.useContext(AppContext);
