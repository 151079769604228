import { type Router } from './types';
import { useRouter } from './useRouter';

export const NEXT_DYNAMIC_SEGMENT_REGEX = /\[([^/]+)\]/g;

/**
 * Extracts the query params from the current page pathname.
 */
export const usePageQueryParams = () => {
    const { router } = useRouter();
    const { pathname, query } = router;

    return pathname
        .split('/')
        .filter((segment) => segment.match(NEXT_DYNAMIC_SEGMENT_REGEX))
        .reduce<Router['router']['query']>((obj, segment) => {
            const key = segment.replace(NEXT_DYNAMIC_SEGMENT_REGEX, '$1');

            return { ...obj, [key]: query[key] };
        }, {});
};
