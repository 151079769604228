import type React from 'react';
import { withHookFormCheckbox } from '../hookForm';
import { Checkbox, type CheckboxProps } from './Checkbox';

/**
 * Hook form checkbox
 */
export const HookFormCheckbox = withHookFormCheckbox(Checkbox);

export type HookFormCheckboxProps = React.ComponentProps<
    typeof HookFormCheckbox
>;

/**
 * Actual Checkbox
 */
export { Checkbox, type CheckboxProps };
