import React, { type CSSProperties, forwardRef } from 'react';
import classnames from 'classnames';
import css from './col.module.scss';

export type ColProps = {
    className?: string;
    style?: CSSProperties;
    span?: 12 | 6 | 4 | 3 | 2 | 1;
    children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const Col = forwardRef<HTMLDivElement, ColProps>(
    ({ className, style, span, children, ...restProps }, ref) => (
        <div
            ref={ref}
            className={classnames(css.base, css[`span-${span}`], className)}
            style={style}
            {...restProps}
        >
            {children}
        </div>
    )
);
