/**
 * Takes a forwarded ref and assigns it to an element provided.
 */
export const assignRef = <T>(
    ref: React.ForwardedRef<T> | undefined | null,
    element: T | null
) => {
    if (!ref) return;

    if (typeof ref === 'function') {
        ref(element);
    } else {
        /* eslint-disable no-param-reassign */
        ref.current = element;
    }
};
