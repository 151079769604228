// @ts-strict-ignore
import { useMemo, useContext } from 'react';
import { AppContext } from 'bb/app';
import { Debug } from 'bb/app/debug';
import { type TrackingStrategy } from 'bb/tracker/types';

type ShareTracker = {
    trackShare(category: string, name: string): void;
};

const debug = Debug('useShareTracker');

const shareTracker = (tracker: TrackingStrategy): ShareTracker => ({
    trackShare: (action, status): void => {
        tracker.track({
            event: 'share',
            data: {
                action,
                status
            }
        });
        debug.warn(`share - ${action} - ${status}`);
    }
});

export const useShareTracker = (): ShareTracker => {
    const { trackingStrategy } = useContext(AppContext);
    return useMemo(() => shareTracker(trackingStrategy), [trackingStrategy]);
};
