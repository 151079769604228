// @ts-strict-ignore
import { useMemo, useContext } from 'react';
import { AppContext } from 'bb/app/AppContext';
import { Debug } from 'bb/app/debug';
import { type TrackingStrategy } from 'bb/tracker/types';

type CtaTracker = {
    accountCta(name): void;
    signupCta(name): void;
    winbackCta(name): void;
};

const debug = Debug('useCtaTracker');

const ctaTracker = (tracker: TrackingStrategy): CtaTracker => ({
    accountCta: (name): void => {
        tracker.track({ event: 'accountCta', name });
        debug.info(`track account cta click from: ${name}`);
    },
    signupCta: (name): void => {
        tracker.track({ event: 'signupCta', name });
        debug.info(`track signup cta click from: ${name}`);
    },
    winbackCta: (name): void => {
        tracker.track({ event: 'winbackCta', name });
        debug.info(`track winback cta click from: ${name}`);
    }
});

export const makeCtaTrackerName = (path: string) => `${path}-with-campaign`;

export const useCtaTracker = (): CtaTracker => {
    const { trackingStrategy } = useContext(AppContext);
    return useMemo(() => ctaTracker(trackingStrategy), [trackingStrategy]);
};
