import React, { forwardRef } from 'react';
import { DeprecatedButton, type ButtonVariant } from '../../DeprecatedButton';
import { Row, type JustifyContentType } from '../../Flex';
import { type MarginProps } from '../../Margin';

export type SubmitButtonProps = {
    text: string;
    textOnSubmit?: string;
    isSubmitting?: boolean;
    isValid?: boolean;
    disabled?: boolean;
    describedBy?: string;
    className?: string;
    justifyContent?: JustifyContentType;
    dataKey?: string;
    fluid?: boolean;
    onClick?: () => void;
    type?: ButtonVariant;
    testId?: string;
} & MarginProps;

export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
    (
        {
            text,
            textOnSubmit,
            isSubmitting = false,
            isValid = false,
            disabled = false,
            fluid = false,
            describedBy = '',
            className = '',
            justifyContent = 'center',
            margin,
            marginLeft,
            marginRight,
            marginBottom,
            marginTop,
            dataKey,
            type,
            testId,
            onClick,
            ...restProps
        },
        ref
    ) => (
        <Row justifyContent={justifyContent}>
            <DeprecatedButton
                type="submit"
                isLoading={isSubmitting}
                disabled={disabled || !isValid}
                aria-describedby={describedBy}
                className={className}
                margin={margin}
                marginLeft={marginLeft}
                marginRight={marginRight}
                marginBottom={marginBottom}
                marginTop={marginTop}
                data-key={dataKey}
                variant={type}
                onClick={onClick}
                fluid={fluid}
                data-testid={testId}
                ref={ref}
                {...restProps}
            >
                {isSubmitting && textOnSubmit ? textOnSubmit : text}
            </DeprecatedButton>
        </Row>
    )
);
