import React, { type FC } from 'react';
import { useLogout } from 'bb/account/hooks/useLogout';
import { useIsLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { useRouter } from 'bb/app/router';
import { SignupButton } from 'bb/common/components/buttons/SignupButton';
import { DeprecatedLinkButton } from 'bb/common/DeprecatedLinkButton';
import { useTranslation } from 'bb/i18n';
import { DeprecatedButton } from 'bb/ui';
import { getCampaignCodeFromQuery } from 'bb/utils';

type MenuActionsProps = {
    fluid?: boolean;
    inverted?: boolean;
};

export const MenuActions: FC<MenuActionsProps> = ({ fluid, inverted }) => {
    const { routes, router } = useRouter();
    const { t } = useTranslation(['nav', 'common']);
    const isLoggedIn = useIsLoggedIn();

    const { isMutating, logout } = useLogout();

    const variant = inverted ? 'tertiary' : 'secondary';

    return (
        <>
            {isLoggedIn && (
                <DeprecatedButton
                    fluid={fluid}
                    variant={variant}
                    isLoading={isMutating}
                    onClick={logout}
                >
                    {t('common:logout')}
                </DeprecatedButton>
            )}
            {!isLoggedIn && (
                <DeprecatedLinkButton
                    route={routes.login}
                    fluid={fluid}
                    inline={false}
                    variant={variant}
                    data-testid="desktop-menu-login"
                >
                    {t('nav:login')}
                </DeprecatedLinkButton>
            )}
            {!isLoggedIn && !router?.query?.wb && (
                <SignupButton
                    prefetch
                    fluid={fluid}
                    variant="primary"
                    name="navigation"
                    inline={false}
                    query={(() => {
                        const campaign = getCampaignCodeFromQuery(router.query);

                        return campaign ? { campaign } : undefined;
                    })()}
                    data-testid="desktop-menu-create-account-btn"
                >
                    {t('nav:registration')}
                </SignupButton>
            )}
        </>
    );
};
