import type { HalLink, HalObject } from './types';

export const getHalLinkHref = (link?: HalLink | null) =>
    link?.href ? `/api/hal/${link.href.replace(/.+(\/api\/)/, '')}` : null;

export const getHalLinkMethod = (link?: HalLink | null) =>
    link?.method ? link.method.toLowerCase() : null;

export const getHalLinks = <
    T extends HalObject<unknown>,
    Link extends keyof HalObject['_links'] | undefined
>(
    obj?: T | null,
    link?: Link
):
    | (undefined extends Link ? T['_links'] : T['_links'][NonNullable<Link>])
    | undefined =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (link ? obj?._links[link] : obj?._links) as any;

export const getHalEmbeddedData = <T extends HalObject<unknown>>(obj?: T) =>
    obj?._embedded as NonNullable<T['_embedded']> | undefined;
