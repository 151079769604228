import React, { type PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { CommonError } from './CommonError';

const ErrorFallback = () => <CommonError />;

export const CustomErrorBoundary = ({ children }: PropsWithChildren) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error) => {
                if (window.newrelic) {
                    window.newrelic.noticeError(error);
                }
            }}
        >
            {children}
        </ErrorBoundary>
    );
};
