/* eslint-disable no-nested-ternary */
import React from 'react';
import classnames from 'classnames';
import { useIsClient } from 'bb/common/hooks';
import { MODAL_CLASSNAME } from './consts';
import {
    useHeadlessModal,
    type UseHeadlessModalReturnProps,
    type UseHeadlessModalProps
} from './useHeadlessModal';

export type HeadlessModalProps = {
    children?:
        | React.ReactNode
        | ((ctx: UseHeadlessModalReturnProps) => React.ReactNode);
    className?: string;
} & UseHeadlessModalProps;

export const HeadlessModal = ({
    children,
    className,
    persistChildrenWhenClosed = false,
    ...useModalProps
}: HeadlessModalProps) => {
    const useModalReturnProps = useHeadlessModal({
        ...useModalProps,
        persistChildrenWhenClosed
    });
    const isClient = useIsClient();
    const { isOpen } = useModalReturnProps;

    /**
     * We don't want to attempt to render a modal on the server-side
     * because it will cause a hydration mismatch. Also createPortal
     * doesn't work on that end.
     */
    if (!isClient) return null;

    return (
        <div className={classnames(MODAL_CLASSNAME, className)}>
            {isOpen || persistChildrenWhenClosed
                ? typeof children === 'function'
                    ? children(useModalReturnProps)
                    : children
                : null}
        </div>
    );
};
