import React, { type FC } from 'react';

type DisplayProps = {
    on: boolean;
    children?: React.ReactNode;
    fallback?: React.ReactElement;
};

export const Display: FC<DisplayProps> = ({ on, children, fallback }) => {
    if (on) {
        return <>{children}</>;
    }
    return fallback || null;
};
