import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const CollectionIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="nonzero">
                <path d="M18 2v7h-7V2h7Zm-1.3 1.3h-4.4v4.4h4.4V3.3ZM18 11v7h-7v-7h7Zm-1.3 1.3h-4.4v4.4h4.4v-4.4ZM9 2v16H2V2h7ZM7.7 3.3H3.3v13.4h4.4V3.3Z" />
            </g>
        </svg>
    </Icon>
);
