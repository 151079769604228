import { useApp } from 'bb/app/AppContext';
import { type RouterQuery } from 'bb/app/router/types';
import { getUrl } from 'bb/app/router/utils';
import { isBrowser } from 'bb/utils/environment';

export const useShareUrl = (pathname: string, query?: RouterQuery) => {
    const { market } = useApp();

    return isBrowser()
        ? `${window.location.origin}${getUrl(pathname, { ...query, market })}`
        : '';
};
