import React from 'react';
import classNames from 'classnames';
import css from './star.module.scss';

export type StarProps = {
    /**
     * The fill of the star, from 0 to 1
     */
    fill: number;
} & React.ComponentProps<'span'>;

export const Star = ({
    fill,
    className,
    style: passedStyle,
    ...restProps
}: StarProps) => (
    <span
        {...restProps}
        style={
            {
                ...passedStyle,
                '--fill': fill
            } as React.CSSProperties
        }
        className={classNames(css.root, className)}
    >
        {fill === 1 ? '★' : '☆'}
    </span>
);
