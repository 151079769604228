export const getDateFromNow = (daysFromNow: number): Date => {
    const date = new Date();
    date.setDate(date.getDate() + daysFromNow);
    return date;
};

export type PassableDate = ConstructorParameters<typeof Date>[0];

/**
 * Returns a new Date with given hours added.
 */
export const addHours = (date: PassableDate, hours: number): Date =>
    new Date(new Date(date).setHours(new Date(date).getHours() + hours));

/**
 * Returns a new Date with given days added.
 */
export const addDays = (date: PassableDate, days: number): Date =>
    new Date(new Date(date).setDate(new Date(date).getDate() + days));

/**
 * Returns true if the given date is after now.
 */
export const isFuture = (date: PassableDate) =>
    new Date(date).getTime() > new Date().getTime();
