import { type NextRouter } from 'next/router';
import { getFromQuery } from './urlUtils';

export const campaignQueryCodes = [
    'kod',
    'koodi',
    'code',
    'promocode',
    'campaign'
];

export const getCampaignCodeFromQuery = (query: NextRouter['query']) => {
    if (!query) return undefined;

    return getFromQuery(
        query,
        campaignQueryCodes.filter((candidate) => candidate in query)[0]
    );
};
