import i18n, { type i18n as I18n } from 'i18next';
import ICU from 'i18next-icu';
import { addPluginsToI18Next } from '../addPluginsToI18Next';
import { type CreateI18NextConfig } from '../createI18NextConfig';

let globalI18nInstance: I18n | null;

export const getGlobalI18nInstance = () => globalI18nInstance;

export const setGlobalI18nInstance = (instance: I18n | null) => {
    globalI18nInstance = instance;
};

/**
 * Returns the global i18n instance if it exists. Otherwise, creates a new
 * clone of the global instance with the passed configuration.
 */
export const getOrCloneGlobalI18nInstance = (config: CreateI18NextConfig) => {
    let i18nInstance: I18n;

    if (!globalI18nInstance) {
        globalI18nInstance = i18n.createInstance(config);
        i18nInstance = globalI18nInstance;
    } else {
        /**
         * If there is an existing instance we need to clone it. Otherwise
         * translations won't work when changing the market since the old
         * language will persist.
         */
        i18nInstance = globalI18nInstance.cloneInstance({
            ...config,
            initImmediate: false
        });
    }

    return i18nInstance;
};

export const createSSRI18nClient = async (config: CreateI18NextConfig) => {
    const i18nInstance = getOrCloneGlobalI18nInstance(config);

    /**
     * Add plugins to the i18n instance. We don't need to include the
     * HttpBackend plugin since it doesn't run on the server.
     *
     * The ICU plugin memoizes by default, and we need to disable that
     * behaviour in order to not run into hydration issues.
     */
    addPluginsToI18Next(i18nInstance, [new ICU({ memoize: false })]);

    if (!i18nInstance.isInitialized) {
        await i18nInstance.init();
    }

    return i18nInstance;
};
