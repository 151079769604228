import React, { createContext, useContext } from 'react';
import { type PublicEnvProviderProps, type PublicEnvVariables } from './types';

export const PublicEnvContext = createContext({} as PublicEnvVariables);

export const usePublicEnvContext = () => useContext(PublicEnvContext);

export const PublicEnvProvider = ({
    children,
    publicEnvVariables
}: PublicEnvProviderProps) => {
    /**
     * Attach publicEnvVariables to __PUBLIC_ENV_VARIABLES__ so we can
     * get access to the variables even outside the React context.
     *
     * We cannot run useEffect here since it is run too late and we end
     * up with the window object being undefined.
     */
    if (typeof window !== 'undefined') {
        window.__PUBLIC_ENV_VARIABLES__ = publicEnvVariables;
    }

    return (
        <PublicEnvContext.Provider value={publicEnvVariables}>
            {children}
        </PublicEnvContext.Provider>
    );
};
