import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const SortIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="m14.169 17.351 4.786-5.053-.99-.898-2.054 2.036-1.078 1.258.062-1.649V3.29h-1.453v9.755l.063 1.649-1.078-1.258L10.42 11.4l-1.048.898 4.796 5.053ZM5.705 3 1 8.023l1.007.881 2.017-2.072L5.08 5.556l-.033 1.65.17 9.753 1.453-.025L6.5 7.18l-.092-1.647 1.1 1.239 2.04 2 1.033-.916L5.705 3Z"
                fillRule="nonzero"
            />
        </svg>
    </Icon>
);
