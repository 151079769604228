import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const PadlockIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -1 25 25">
            <path
                d="M8.55 16.541v2.177h1.9V16.54c1.202-.637 1.267-2.536.112-3.255-1.052-.784-2.72-.083-2.924 1.205-.17.789.21 1.65.912 2.05zM1.9 12.956c-.048-1.662 1.54-3.048 3.17-2.878 3.147.01 6.295-.02 9.44.014 1.578.113 2.756 1.666 2.59 3.207-.01 1.902.02 3.807-.015 5.71-.115 1.575-1.664 2.751-3.205 2.589-3.13-.01-6.26.02-9.39-.014-1.578-.113-2.756-1.666-2.59-3.207z"
                fillRule="evenodd"
            />
            <path
                d="M10.35 2.372c-.653-.026-1.248.05-1.712.028l-.014-.002h-.017a3.8 3.8 0 0 0-3.739 2.894l-.008.032-.005.032c-.197 1.168-.076 2.227-.101 3.147-.105 1.304.299 2.794 1.539 3.682 1.31 1.039 2.848.745 3.797.772l-.068-.005c1.938.194 3.804-1.12 4.156-3.082l.006-.03.002-.032c.13-1.124.042-2.166.064-3.105l-.002.062c.13-1.45-.47-3.013-1.855-3.814-.691-.423-1.395-.555-2.043-.58zM8.542 4.316c1.296.067 2.252-.101 2.858.274l.014.008.014.01c.629.36.978 1.184.906 1.985l-.003.032v.03c-.025 1.057.043 2.022-.051 2.889-.168.88-1.162 1.59-2.067 1.5l-.035-.005h-.034c-1.246-.036-2.136.08-2.674-.357l-.026-.022-.028-.02c-.558-.39-.814-1.17-.749-1.984l.002-.022.001-.024c.036-1.093-.056-2.06.076-2.886.198-.777 1.024-1.42 1.828-1.406z"
                fillRule="evenodd"
            />
        </svg>
    </Icon>
);
