import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

export const useOnScreen = <T extends Element>(
    ref: React.RefObject<T>,
    threshold = 1,
    rootMargin = '0px',
    once = true
): [boolean, () => void] => {
    const [isIntersecting, setIntersecting] = useState(false);

    // If rendered on the server we dont have access to IntersectionObserver, it will be instantiated on the client instead
    const canObserve = typeof IntersectionObserver !== 'undefined';

    const observer = useMemo(
        () =>
            canObserve
                ? new IntersectionObserver(
                      ([entry]) => {
                          if (entry) {
                              setIntersecting(entry.isIntersecting);
                          }
                      },
                      {
                          threshold,
                          rootMargin
                      }
                  )
                : null,
        [canObserve, threshold, rootMargin]
    );

    useEffect(() => {
        if (canObserve) {
            if (ref?.current && observer) observer.observe(ref.current);
        }

        if (once && isIntersecting) observer?.disconnect();

        // Remove the observer as soon as the component is unmounted
        return (): void => {
            observer?.disconnect();
        };
    }, [isIntersecting, observer, ref, canObserve, once]);

    const disconnect = () => observer?.disconnect();

    return [isIntersecting, disconnect];
};
