/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useCallback } from 'react';

/**
 * Will keep a stable function reference with the same signature as `cb` but let `cb` be re-created
 * whenever to keep in sync with state etc. See https://github.com/reactjs/rfcs/pull/220
 */
export const useStableCallback = <T extends (...args: any[]) => any>(
    cb: T | undefined
) => {
    const val = useRef(cb);

    val.current = cb;

    return useCallback(
        (...args: Parameters<T>) => val.current?.(...args) as ReturnType<T>,
        []
    ) as T;
};
