import React from 'react';

export type LogoProps = {
    variant: 'white' | 'black';
} & React.ComponentProps<'img'>;
const Logo: React.FC<LogoProps> = ({
    alt = 'Bookbeat logo',
    variant,
    ...restProps
}) => {
    const logoSrc =
        variant === 'white'
            ? '/images/svg/BookBeat_white.svg'
            : '/images/svg/BookBeat_black.svg';

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={logoSrc} alt={alt} {...restProps} />;
};

export default Logo;
