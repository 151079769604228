/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useMarket } from 'bb/app/useMarket';
import { getMarketConfig } from 'bb/config/marketConfig';
import { useTranslation } from 'bb/i18n';
import { Input } from 'bb/ui/Form/Input/Input';
import { Select } from 'bb/ui/Form/Select/Select';
import selectCss from 'bb/ui/Form/Select/select.module.scss';
import { Gap } from 'bb/ui/Gap';
import { ChevronDown } from 'bb/ui/Icons';
import { Typography } from 'bb/ui/Typography';
import { makeMarketItems } from './helpers';
import css from './marketPhoneSelect.module.scss';
import {
    type MarketPhoneSelectProps,
    type MarketPhoneSelectFlagProps
} from './MarketPhoneSelect.types';

export const MarketPhoneSelectFlag = ({
    item,
    className,
    ...restProps
}: MarketPhoneSelectFlagProps) => (
    <img
        {...restProps}
        alt={item.name}
        className={classNames(css.flag, className)}
        src={`/images/flags/${item.marketCode}.png`}
    />
);

const MarketPhoneSelectComponent = (
    props: MarketPhoneSelectProps,
    passedRef: React.ForwardedRef<HTMLSpanElement>
) => {
    const {
        onChange,
        defaultSelectedItem: passedDefaultSelectedItem,
        disabled,
        ...restProps
    } = props;
    const { t } = useTranslation(['countries']);
    const { current: items } = useRef(makeMarketItems(t));
    const lastActualValue = useRef<string | undefined>();
    const { market } = useMarket();

    /**
     * If no defaultSelectedItem is passed, we use the current market as default.
     */
    const defaultSelectedItem =
        passedDefaultSelectedItem ?? String(getMarketConfig(market).code);

    const [selectValue, setSelectValue] =
        useState<typeof passedDefaultSelectedItem>(defaultSelectedItem);
    const [inputValue, setInputValue] = useState('');

    const actualValue =
        selectValue && inputValue ? `+${selectValue}${inputValue}` : '';

    useEffect(() => {
        if (lastActualValue.current !== actualValue) {
            lastActualValue.current = actualValue;
            onChange?.(actualValue);
        }
    }, [actualValue, onChange]);

    const flagDisabledClassName = disabled ? css.flagDisabled : undefined;

    return (
        <Select
            onChange={(value) => {
                const newValue = items.find((item) => item.value === value);

                setSelectValue(newValue ? String(newValue.code) : undefined);
            }}
            defaultSelectedItem={defaultSelectedItem}
            renderSelected={({
                selectedItem,
                className: _className,
                ref,
                ...restRenderSelectedProps
            }) => (
                <span
                    ref={ref}
                    className={classNames(selectCss.select, css.select)}
                >
                    <span
                        className={classNames(
                            css.openSelect,
                            !disabled && css.openSelectEnabled
                        )}
                        {...restRenderSelectedProps}
                    >
                        {selectedItem && (
                            <Gap spacing={2} direction="row">
                                <MarketPhoneSelectFlag
                                    className={flagDisabledClassName}
                                    item={selectedItem}
                                />
                                <Typography>
                                    {selectedItem.marketCode.toUpperCase()}
                                </Typography>
                            </Gap>
                        )}
                        <ChevronDown size="small" />
                    </span>

                    <Input
                        startAdornment={
                            selectedItem ? (
                                <Typography>{`+${selectedItem.code}`}</Typography>
                            ) : null
                        }
                        className={css.input}
                        disableFocusIndicator
                        disableBorder
                        disablePadding
                        placeholder="000 000 00 00"
                        label=""
                        onChange={(event) => {
                            setInputValue(event.target.value);
                        }}
                        disabled={disabled}
                    />
                </span>
            )}
            renderItem={({ isHighlighted, item, ...restRenderItemProps }) => (
                <li
                    className={classNames(
                        selectCss.item,
                        isHighlighted && selectCss.itemIsHighlighted
                    )}
                    {...restRenderItemProps}
                >
                    <Gap direction="row" spacing={2}>
                        <MarketPhoneSelectFlag
                            className={flagDisabledClassName}
                            item={item}
                        />
                        <Typography>{item.label}</Typography>
                    </Gap>
                </li>
            )}
            items={items}
            isSearchable
            endAdornment={null}
            disablePadding
            disableDefaultGetButtonProps
            disabled={disabled}
            ref={passedRef}
            {...restProps}
        />
    );
};

export const MarketPhoneSelect = React.forwardRef(
    MarketPhoneSelectComponent
) as (
    props: MarketPhoneSelectProps &
        Pick<React.ComponentProps<typeof Select>, 'ref'>
) => React.ReactNode;
