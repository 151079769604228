import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'bb/i18n/translationUtils';
import { Flex } from 'bb/ui/Flex';
import { CrossIcon } from 'bb/ui/Icons';
import { type IconProps } from 'bb/ui/Icons/Icon';
import { CLOSE_BUTTON_CLASSNAME } from '../consts';
import css from './closeButton.module.scss';

export type CloseButtonProps = React.ComponentPropsWithRef<'button'> &
    Pick<IconProps, 'color'>;

export const CloseButton = React.forwardRef<
    HTMLButtonElement,
    CloseButtonProps
>((props: CloseButtonProps, ref) => {
    const {
        className,
        'aria-label': ariaLabel,
        color = 'primary-black',
        ...restProps
    } = props;
    const { t } = useTranslation(['common']);

    return (
        <button
            {...restProps}
            className={classnames(CLOSE_BUTTON_CLASSNAME, css.root, className)}
            ref={ref}
            type="button"
            aria-label={ariaLabel || t('common:close')}
        >
            <Flex alignItems="center" justifyContent="center">
                <CrossIcon color={color} size="small" />
            </Flex>
        </button>
    );
});
