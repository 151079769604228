import React from 'react';
import { createRoot } from 'react-dom/client';
import { FeatureProvider } from 'bb/config/features';
import { appWithTranslation } from 'bb/i18n/resources/appWithTranslation';
import { getModalRoot } from 'bb/ui/Modal/utils';
import { isBrowser } from './environment';

export const STRAY_ELEMENT_SELECTOR = 'stray-element-root';

export type RenderStrayComponentRenderProps = {
    onClose: () => void;
};

export type RenderStrayComponentOptions = {
    /**
     * Must be a valid selector for document.querySelector
     * to be able to get the element.
     */
    strayElementRootSelector?: string;
    /**
     * First or last. Defaults to first.
     */
    position?: 0 | -1;
};

/**
 * Utility function for rendering an element outside of the React app
 * boundary. Useful programmatically rendering a modal (or similar).
 */
export function renderStrayComponent(
    callback: (props: RenderStrayComponentRenderProps) => React.ReactNode,
    options: RenderStrayComponentOptions = {}
) {
    if (!isBrowser())
        throw new Error(
            `renderStrayComponent(): This function can only be run in the browser`
        );

    const { strayElementRootSelector = STRAY_ELEMENT_SELECTOR, position = 0 } =
        options;

    const modalRoot = getModalRoot();
    let strayElementRoot = document.querySelector(
        `#${strayElementRootSelector}`
    );

    if (!modalRoot)
        throw new Error(
            'renderStrayComponent(): modalRoot is not present in the DOM'
        );

    if (!strayElementRoot) {
        strayElementRoot = document.createElement('div');
        strayElementRoot.id = strayElementRootSelector;
        strayElementRoot.classList.add(strayElementRootSelector);
        modalRoot.appendChild(strayElementRoot);
    }

    const strayElement = document.createElement('div');

    if (position === -1) {
        strayElementRoot.append(strayElement);
    } else {
        strayElementRoot.prepend(strayElement);
    }

    const reactRoot = createRoot(strayElement);

    const onClose = () => {
        strayElementRoot?.removeChild(strayElement);
        if (!strayElementRoot?.hasChildNodes()) {
            modalRoot.removeChild(strayElementRoot);
        }
        reactRoot.unmount();
    };

    const AppWithTranslation = appWithTranslation(() => callback({ onClose }));

    reactRoot.render(
        /**
         * Below is a hack to pass the pageProps to the AppWithTranslation.
         * It's not supposed to work, but it does. If we don't
         * do @ts-ignore here, we get a type error.
         */
        <FeatureProvider features={window?.__PUBLIC_FEATURES__}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <AppWithTranslation
                pageProps={window?.__NEXT_DATA__?.props?.pageProps || {}}
            />
        </FeatureProvider>
    );
}
