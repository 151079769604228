import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { type Route } from './types';
import { persistQueryParams } from './utils';

/**
 * Global variable since useRouter gets unmounted
 * which causes loss of state/ref.
 */
const persistentQueryKeysSet = new Set<string>();

export type PersistQueryParams = ReturnType<typeof usePersistQueryParams>;

export const usePersistQueryParams = () => {
    const { query, pathname } = useRouter();

    const add = useCallback(
        (href: Route['href'], queryKey: string) => {
            if (href === pathname) {
                persistentQueryKeysSet.add(queryKey);
            }
        },
        [pathname]
    );
    const remove = useCallback(
        (queryKey: string) => persistentQueryKeysSet.delete(queryKey),
        []
    );

    const make = useCallback(
        () => persistQueryParams(Array.from(persistentQueryKeysSet), query),
        [query]
    );

    return {
        add,
        remove,
        make
    };
};
