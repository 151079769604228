/*
 * Check if the market is in the list of markets that should show the soft opt-out
 * why we dont check discovery for create_account_terms is because we would still need to check markets
 * that should not show the soft opt-out
 */
export const getTextKey = (market: string) => {
    if (['de', 'uk', 'fr', 'at', 'ch'].includes(market)) {
        return 'softOptOut:create_account_terms_and_electronic_messages';
    }
    if (['it', 'pl', 'no'].includes(market)) {
        return 'consents:termsAndConditions';
    }
    return 'softOptOut:create_account_terms';
};

export const shouldShowSoftOptOut = (market: string) =>
    !['it', 'pl', 'no'].includes(market);
