import { type NextRouter } from 'next/router';

/**
 * Extracts specified keys from the query params and parses
 * the values with a given function (if specified).
 *
 * Example usage:
 * const { foo } = extractQueryParams(['foo'], router, decodeURI);
 */
export const extractQueryParams = <T extends string>(
    keys: T[],
    router: NextRouter,
    fn: (str: string) => string = String
) =>
    keys.reduce((object, key) => {
        const value = router.query[key];

        return {
            ...object,
            [key]: typeof value === 'string' ? fn(value) : null
        };
    }, {}) as Record<T, string | null>;
