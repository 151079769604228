import React from 'react';
import classNames from 'classnames';
import css from './overlay.module.scss';

export type OverlayProps = React.PropsWithChildren<
    { className?: string } & React.ComponentProps<'div'>
>;

export const Overlay = ({
    children,
    className,
    ...restProps
}: OverlayProps) => (
    <div className={classNames(css.root, className)} {...restProps}>
        {children}
    </div>
);
