import { useEffect, useRef } from 'react';

export const useActionOnce = (callback?: boolean | null | (() => void)) => {
    const done = useRef(false);

    useEffect(() => {
        if (!done.current && typeof callback === 'function') {
            done.current = true;
            callback();
        }
    }, [callback]);

    return null;
};
