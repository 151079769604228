import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const LogOutIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M6.569 10.624h8.218l1.113-.09-1.885 1.834.772.761L17.91 10l-3.122-3.183-.772.812L15.9 9.451l-1.115-.096H6.567l.002 1.27ZM2.758 16.91h9.813v-4.607l-1.239.02v3.285H4.079V4.353h7.231V7.67l1.262.032V3.088H2.758v13.82Z"
                fillRule="nonzero"
            />
        </svg>
    </Icon>
);
