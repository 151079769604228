// @ts-strict-ignore
import { useCallback, useReducer } from 'react';
import { type AxiosError } from 'axios';
import { Debug } from 'bb/app/debug';
import { useSimpleLogger } from 'bb/common/logger/useSimpleLogger';
import { del } from './api';
import { createReducer } from './apiReducer';
import { type AxiosErrorResponse, handleError } from './handleError';
import { EXECUTING, SUCCESS, ERROR, IDLE } from './symbols';
import { type DeleteRequestReturnType, type ExecuteFunction } from './types';

const debug = Debug('useDelete');
export function useDelete<Response>(
    initUrl: string
): DeleteRequestReturnType<Response> {
    const logger = useSimpleLogger();
    const [state, dispatch] = useReducer(createReducer<Response>(), {
        result: null,
        error: null,
        status: IDLE
    });

    const postData: ExecuteFunction<string> = useCallback(
        async (id?: string): Promise<void> => {
            debug.info(`Deleting ${initUrl}/${id}`);
            dispatch({ type: EXECUTING });
            await del<Response>(id ? `${initUrl}/${id}` : `${initUrl}`)
                .then(({ data }) => {
                    debug.info(data);
                    dispatch({ type: SUCCESS, result: data });
                })
                .catch((err: AxiosError<AxiosErrorResponse, unknown>) => {
                    const error = handleError(err);
                    logger.error(
                        `DELETE ${initUrl} ${error?.status} ${error?.data?.message}`,
                        { path: initUrl }
                    );
                    dispatch({ type: ERROR, error });
                    return Promise.reject();
                });
        },
        [initUrl, logger]
    );

    return { ...state, execute: postData };
}
