import React from 'react';
import classNames from 'classnames';
import { type Tuple } from 'bb/common/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { getLabelId } from '../helpers';
import { HelperText as HelperTextComponent } from '../HelperText';
import { Label } from '../Label';
import { type HelperText } from '../types';
import css from './inputController.module.scss';
import { type InputControllerProps } from './InputController.types';

const DEFAULT_BORDER_COLOR = 'gray-03';

export const InputController = React.forwardRef<
    HTMLSpanElement,
    InputControllerProps
>((props, ref) => {
    const {
        borderColor = DEFAULT_BORDER_COLOR,
        children,
        className,
        disableBorder = false,
        disablePadding = false,
        disabled = false,
        endAdornment,
        error,
        fluid,
        helperText: passedHelperText,
        inputProps = {},
        label,
        labelProps = {},
        name = '',
        startAdornment,
        style: passedStyle = {},
        block = false,
        inputRef,
        ...restProps
    } = props;

    const helperText = (
        Array.isArray(passedHelperText) ? passedHelperText : [passedHelperText]
    ) as Tuple<HelperText, 2>;

    const getBorderColor = () => {
        if (error) return 'secondary-red';
        if (disabled) return DEFAULT_BORDER_COLOR;

        return borderColor;
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span
            style={{
                ...makeCSSVariableFromColor(
                    '--input-border-color',
                    getBorderColor()
                ),
                ...passedStyle
            }}
            className={classNames(
                css.root,
                disabled && css.disabled,
                fluid && css.fluid,
                block && css.block,
                className
            )}
            ref={ref}
            /**
             * When clicking on the container, focus the input.
             *
             * This is useful mainly for a11y reasons, but it does
             * also improve the UX in general.
             */
            onClick={(...args) => {
                inputRef?.current?.focus();
                restProps.onClick?.(...args);
            }}
            {...restProps}
        >
            {label && (
                <Label id={getLabelId(name)} htmlFor={name} {...labelProps}>
                    {label}
                </Label>
            )}
            <span
                {...inputProps}
                className={classNames(
                    css.input,
                    disableBorder && css.disableBorder,
                    disablePadding && css.disablePadding,
                    inputProps.className
                )}
            >
                {startAdornment && (
                    <span
                        className={classNames(
                            css.adornment,
                            css.startAdornment
                        )}
                    >
                        {startAdornment}
                    </span>
                )}

                {children}

                {endAdornment && (
                    <span
                        className={classNames(css.adornment, css.endAdornment)}
                    >
                        {endAdornment}
                    </span>
                )}
            </span>

            <HelperTextComponent helperText={helperText} error={error} />
        </span>
    );
});
