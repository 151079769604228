import React, { type CSSProperties, type FC } from 'react';
import { type AlignItems, type GapSize } from '../../types';
import { Flex } from '../Flex';
import { type FlexWrapType } from '../types';

export type InlineProps = {
    style?: CSSProperties;
    className?: string;
    wrap?: FlexWrapType;
    gap?: GapSize;
    alignItems?: AlignItems;
    children?: React.ReactNode;
};

export const Inline: FC<InlineProps> = ({
    style,
    className,
    wrap = 'noWrap',
    gap = 'small',
    alignItems = 'center',
    children
}) => (
    <Flex
        style={style}
        className={className}
        direction="row"
        gap={gap}
        alignItems={alignItems}
        wrap={wrap}
    >
        {children}
    </Flex>
);
