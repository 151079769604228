import { useRef } from 'react';
import { useApi } from 'bb/api/browser/useApi';
import { getHalEmbeddedData } from 'bb/common/hal';
import { useAccount } from './useAccount';
import { useIsLoggedIn } from './useVerifyLoggedIn';

export const useMyAccountViews = () => {
    const activatedRef = useRef(false);
    const res = useApi('/api/views/web/myaccount', {
        enabled: useIsLoggedIn()
    });

    useAccount({
        onSuccess: ({ isActivated }) => {
            if (activatedRef.current !== isActivated) {
                res.mutate();
                activatedRef.current = isActivated;
            }
        }
    });

    return Object.assign(res, { views: getHalEmbeddedData(res.data)?.views });
};
