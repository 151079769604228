import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const HourglassIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <g fillRule="evenodd">
                <path d="M4.5 2.7h11V4h-11zM4.5 16h11v1.3h-11z" />
                <path d="M12.85 3.5v2.535l-7 7.42V16.5h1.3v-2.527l7-7.422V3.5Z" />
                <path d="M7.15 3.5v2.535l7 7.42V16.5h-1.3v-2.527l-7-7.422V3.5Z" />
            </g>
        </svg>
    </Icon>
);
