import qs, { type StringifiableRecord } from 'query-string';
import { interpolateUrlString } from '../../interpolateUrlString';

export const DYNAMIC_SEGMENT_REGEXP = /\{([a-zA-Z0-9_-]+)\}/;

export const assignUrl = (
    pathname: string,
    query?: StringifiableRecord,
    onError?: (error: Error) => void
) => {
    try {
        const interpolatedUrl = query
            ? interpolateUrlString(pathname, query, DYNAMIC_SEGMENT_REGEXP)
            : null;

        return qs.stringifyUrl({
            url: interpolatedUrl?.result ?? pathname,
            query: interpolatedUrl?.rest
        });
    } catch (error) {
        // eslint-disable-next-line max-depth
        if (error instanceof Error) {
            onError?.(error);
        }

        return null;
    }
};
