import React from 'react';
import classnames from 'classnames';
import { type Routes } from 'bb/app/router';
import { Box, type BoxProps } from 'bb/ui/Box';
import { Breadcrumbs } from './Breadcrumbs';
import css from './breadcrumbs.module.scss';
import { type BreadcrumbsProps } from './Breadcrumbs.types';

export type NavBreadcrumbsProps<TRoute extends keyof Routes> = {
    boxProps?: BoxProps;
} & BreadcrumbsProps<TRoute>;

const NavBreadcrumbsComponent = (
    /**
     * We need to trick this component to think that the
     * route is for the 'start' page because of the generic
     * nature of Breacrumbs.
     */
    props: NavBreadcrumbsProps<'start'>,
    ref?: React.ForwardedRef<HTMLDivElement | null>
) => {
    const { boxProps: { padding = 4, ...restBoxProps } = {}, ...restProps } =
        props;

    return (
        <Box
            {...restBoxProps}
            className={classnames(css.navBreadcrumbs, restBoxProps.className)}
            padding={padding}
        >
            <Breadcrumbs {...restProps} ref={ref} />
        </Box>
    );
};

export const NavBreadcrumbs = React.forwardRef(NavBreadcrumbsComponent) as <
    TRoute extends keyof Routes
>(
    props: NavBreadcrumbsProps<TRoute>
) => JSX.Element;
