export const SupportedPaymentMethods = {
    SCHEME: 'scheme',
    PAYPAL: 'paypal',
    KLARNA: 'klarna_paynow',
    GIFTCARD: 'giftCard',
    IDEAL: 'ideal',
    APPLEPAY: 'applepay',
    GOOGLEPAY: 'googlepay',
    CARD: 'card'
} as const;
