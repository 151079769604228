import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const CrossIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="m16.85 15.828-5.924-5.993 5.78-5.862L15.668 3l-5.74 5.824L4.15 3 3.049 4.012l5.785 5.862L3 15.828l1.05 1.02L9.9 10.883l5.82 5.991z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    </Icon>
);
