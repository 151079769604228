import React, {
    type ComponentType,
    type PropsWithChildren,
    useEffect,
    useMemo,
    useRef
} from 'react';
import dynamic from 'next/dynamic';
import { SWRConfig } from 'swr';
import { RequiredTermsModal } from 'bb/account/components/RequiredTermsModal';
import { fetcher } from 'bb/api/browser';
import { registerRefreshTokenInterceptor } from 'bb/api/browser/api';
import { AppRemappedRoutesProvider } from 'bb/app/AppRemappedRoutesProvider';
import { MarketPickerModal } from 'bb/app/nav/MarketPicker';
import { Logger } from 'bb/common/logger/Logger';
import { LoggerStoreProvider } from 'bb/common/logger/useLoggerStore';
import { FeatureProvider } from 'bb/config/features';
import { type Features } from 'bb/config/features/types';
import { type Locale } from 'bb/config/marketConfigUtils';
import { type Market } from 'bb/config/types';
import { Cookies } from 'bb/cookies/config';
import { getCookieValue } from 'bb/cookies/utils';
import { useTranslation } from 'bb/i18n';
import { type I18NSSRConfig } from 'bb/i18n/resources/types';
import { type FallbackData } from 'bb/page/types';
import { withFeatureFlaggedComponent } from 'bb/page/withFeatureFlaggedComponent';
import { IdempotencyProvider } from 'bb/payment/idempotency';
import { createAnalyticsEventStrategy } from 'bb/tracker/analyticsEventStrategy';
import { createDataLayerStrategy } from 'bb/tracker/strategies/dataLayer';
import { useModalStore } from 'bb/ui/HeadlessModal/store';
import { AppContext } from './AppContext';
import { ToastContainer } from './ToastContainer';
import { usePersistentAppQueryParams } from './usePersistentAppQueryParams';

const SubscriptionStoreProvider = dynamic(
    () =>
        import('bb/subscription/useSubscriptionStore').then(
            (m) => m.SubscriptionStoreProvider
        ),
    { ssr: true }
);

const CampaignStoreProvider = dynamic(
    () =>
        import('bb/campaigns/hooks/useCampaignStore').then(
            (m) => m.CampaignStoreProvider
        ),
    { ssr: true }
);

const AccountStoreProvider = dynamic(
    () =>
        import('bb/account/hooks/useAccountStore').then(
            (m) => m.AccountStoreProvider
        ),
    { ssr: true }
);

const GiftcardStoreProvider = dynamic(
    () =>
        import('bb/giftcard/useGiftcardStore').then(
            (m) => m.GiftcardStoreProvider
        ),
    { ssr: true }
);

const FetchAccountSettings = dynamic(
    () =>
        import('bb/account/components/FetchAccountSettings').then(
            (m) => m.FetchAccountSettings
        ),
    { ssr: true }
);

const FetchSubscriptionProducts = dynamic(
    () =>
        import('bb/subscription/FetchSubscriptionProducts').then(
            (m) => m.FetchSubscriptionProducts
        ),
    { ssr: true }
);

const GeneralAlert = withFeatureFlaggedComponent(
    () => null,
    dynamic(() =>
        import('bb/common/components/notifications/GeneralAlert').then(
            (m) => m.GeneralAlert
        )
    ),
    'alert-general'
);

export type AppLayoutIncludedStoreProviders = {
    /**
     * If true, the AccountStoreProvider will be included in the AppLayout.
     *
     * @defaultValue `true`
     */
    accountStore?: boolean;
    /**
     * If true, the PropsWithChildrenvider will be included in the AppLayout.
     *
     * @defaultValue `true`
     */
    campaignStore?: boolean;
    /**
     * If true, the GiftcardStoreProvider will be included in the AppLayout.
     *
     * @defaultValue `true`
     */
    giftcardStore?: boolean;
    /**
     * If true, the SubscriptionStoreProvider will be included in the AppLayout.
     *
     * @defaultValue `true`
     */
    subscriptionStore?: boolean;
};

export type MaybeStoreProviderProps = {
    /**
     * If `true`, the store provider will be rendered.
     *
     * If `false` only children will be rendered.
     *
     * @defaultValue `true`
     */
    enabled?: boolean;
    /**
     * A React component that will be rendered if `enabled` is `true`.
     */
    component: ComponentType<PropsWithChildren>;
} & PropsWithChildren;

export const MaybeStoreProvider = ({
    enabled = true,
    children,
    component: Component
}: MaybeStoreProviderProps) => {
    return enabled ? <Component>{children}</Component> : children;
};

export const GlobalMarketPicker = () => {
    const [[marketPickerOpen, setMarketPickerOpen], getModalProps] =
        useModalStore('marketPicker');
    const { t } = useTranslation(['common']);

    return (
        <MarketPickerModal
            isOpen={marketPickerOpen}
            onAfterClose={() => setMarketPickerOpen(false)}
            buttonProps={{
                'aria-label': t('common:close')
            }}
            {...getModalProps()}
        />
    );
};

export type AppLayoutProps = {
    market?: Market;
    features?: Features;
    fallback?: FallbackData;
    showTermsModal: boolean;
    children: React.ReactNode;
    /**
     * This property adds the possibility to include or exclude store
     * providers from the AppLayout. By default, all store providers are included.
     *
     * @defaultValue `{}`
     */
    includedStoreProviders?: AppLayoutIncludedStoreProviders;
    /**
     * Disables the global fetch components.
     *
     * @defaultValue `false`
     */
    disableGlobalFetchComponents?: boolean;
} & I18NSSRConfig;

export const AppLayout = ({
    fallback = {},
    features,
    market,
    i18nSSRConfig,
    showTermsModal = true,
    children,
    includedStoreProviders = {},
    disableGlobalFetchComponents = false
}: AppLayoutProps) => {
    const interceptorCleanup = useRef<() => void>();

    interceptorCleanup.current =
        interceptorCleanup.current ?? registerRefreshTokenInterceptor();

    usePersistentAppQueryParams();

    const locale = (i18nSSRConfig?.locale || 'en') as Locale;

    const appContext = useMemo(
        () => ({
            trackingStrategy: createDataLayerStrategy(),
            analyticsEventTrackingStrategy: createAnalyticsEventStrategy(),
            market: market || (getCookieValue(Cookies.MARKET_COOKIE) as Market),
            locale
        }),
        [market, locale]
    );

    useEffect(() => () => interceptorCleanup.current?.(), []);

    return (
        <SWRConfig value={{ fetcher, fallback }}>
            <FeatureProvider addToWindowObject features={features}>
                <LoggerStoreProvider>
                    <AppContext.Provider value={appContext}>
                        <AppRemappedRoutesProvider>
                            <MaybeStoreProvider
                                component={SubscriptionStoreProvider}
                                enabled={
                                    includedStoreProviders.subscriptionStore
                                }
                            >
                                <MaybeStoreProvider
                                    component={CampaignStoreProvider}
                                    enabled={
                                        includedStoreProviders.campaignStore
                                    }
                                >
                                    <MaybeStoreProvider
                                        component={AccountStoreProvider}
                                        enabled={
                                            includedStoreProviders.accountStore
                                        }
                                    >
                                        <MaybeStoreProvider
                                            component={GiftcardStoreProvider}
                                            enabled={
                                                includedStoreProviders.giftcardStore
                                            }
                                        >
                                            <IdempotencyProvider>
                                                <Logger />
                                                {!disableGlobalFetchComponents && (
                                                    <>
                                                        <FetchAccountSettings />
                                                        <FetchSubscriptionProducts />
                                                    </>
                                                )}
                                                <GeneralAlert />

                                                <ToastContainer />
                                                <GlobalMarketPicker />
                                                <div className="bb-app">
                                                    {children}
                                                </div>
                                                {showTermsModal && (
                                                    <RequiredTermsModal />
                                                )}
                                            </IdempotencyProvider>
                                        </MaybeStoreProvider>
                                    </MaybeStoreProvider>
                                </MaybeStoreProvider>
                            </MaybeStoreProvider>
                        </AppRemappedRoutesProvider>
                    </AppContext.Provider>
                </LoggerStoreProvider>
            </FeatureProvider>
        </SWRConfig>
    );
};
