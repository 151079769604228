import React from 'react';
import { Icon, type BaseIconProps } from './Icon';

export const ArrowLeftIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="M9.368 4.085 3.5 9.755l5.868 5.847.982-.911-2.306-2.452-2.087-1.909 1.582.094h10.326V9.065H7.539l-1.59.095 2.095-1.91 2.285-2.24Z"
            />
        </svg>
    </Icon>
);
