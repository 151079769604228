import React from 'react';
import classnames from 'classnames';
import { Box, type BoxProps } from '../Box';
import { HeadlessModal, type HeadlessModalProps } from '../HeadlessModal';
import { Overlay } from '../Overlay';
import css from './baseModal.module.scss';

export type BaseModalProps = {
    /**
     * Disables the `Overlay` component.
     *
     * @default false
     */
    disableOverlay?: boolean;
    /**
     * Props to pass to the `Box` component.
     *
     * @default {}
     */
    boxProps?: BoxProps & {
        /**
         * Allow for passing data attributes which for some reason
         * aren't supported through React.InputHTMLAttributes.
         */
        [key: `data-${string}`]: string;
    };
} & HeadlessModalProps;

export const BaseModal = ({
    children,
    boxProps = {},
    disableOverlay = false,
    ...headlessModalProps
}: BaseModalProps) => {
    const {
        bgColor = 'primary-white',
        fluid = false,
        ...restBoxProps
    } = boxProps;

    return (
        <HeadlessModal {...headlessModalProps}>
            {(ctx) => {
                const { getModalProps, isActive } = ctx;

                return (
                    <>
                        {isActive && !disableOverlay && <Overlay />}
                        <Box
                            {...restBoxProps}
                            {...getModalProps()}
                            fluid={fluid}
                            bgColor={bgColor}
                            className={classnames(
                                css.root,
                                restBoxProps.className
                            )}
                        >
                            <div className={css.content}>
                                {typeof children === 'function'
                                    ? children(ctx)
                                    : children}
                            </div>
                        </Box>
                    </>
                );
            }}
        </HeadlessModal>
    );
};
