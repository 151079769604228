import React, {
    createContext,
    useEffect,
    useState,
    type PropsWithChildren,
    useContext
} from 'react';
import classnames from 'classnames';
import css from './spinner.module.scss';

type SpinnerConfig = {
    delay?: number;
};

const SpinnerContext = createContext<SpinnerConfig>({});

export const SpinnerConfig: React.FC<PropsWithChildren<SpinnerConfig>> = ({
    children,
    ...config
}) => (
    <SpinnerContext.Provider value={config}>{children}</SpinnerContext.Provider>
);

export type SpinnerProps = React.PropsWithChildren<{
    show: boolean;
    delay?: number;
    inline?: boolean;
    nested?: boolean;
    text?: React.ReactNode;
    ghost?: boolean;
    ghostNode?: React.ReactNode;
    className?: string;
    size?: 'small' | 'large';
}> &
    React.ComponentProps<'div'>;

export const Spinner = ({
    show,
    delay: providedDelay,
    inline,
    ghost,
    className,
    size = 'large',
    nested,
    text,
    children,
    ghostNode,
    ...restProps
}: SpinnerProps) => {
    const { delay: configDelay = 0 } = useContext(SpinnerContext);
    const [_show, setDelayedShow] = useState(false);
    const delay = providedDelay ?? configDelay;

    useEffect(() => {
        const timer = show
            ? window.setTimeout(() => setDelayedShow(true), delay)
            : 0;

        if (!timer) setDelayedShow(false);

        return () => window.clearTimeout(timer);
    }, [show, delay]);

    const active = delay && show ? _show : show;

    return (
        <div
            className={classnames(
                css.base,
                className,
                inline && css.inline,
                active && css.active,
                css[size],
                nested && css.nested
            )}
            {...restProps}
        >
            {active && (
                <div
                    className={classnames(
                        className,
                        css.spinnerWrapper,
                        active && css.active
                    )}
                >
                    {!ghost && (
                        <div className={css.spinner}>
                            <div className={css.spinning} />
                            {ghostNode}
                        </div>
                    )}
                    {text}
                </div>
            )}

            {!inline && (
                <div
                    className={classnames(
                        className,
                        !inline && css.overlay,
                        active && css.active
                    )}
                >
                    {children}
                </div>
            )}
        </div>
    );
};
