// @ts-strict-ignore
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Icon, type BaseIconProps } from './Icon';
import * as svgs from './svgs';

export const UserIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.UserIcon />
    </Icon>
);
export const EmailIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.EmailIcon />
    </Icon>
);
export const KeyIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.KeyIcon />
    </Icon>
);
export const CreditCardIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.CreditCardIcon />
    </Icon>
);

export const PaypalIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.PaypalIcon />
    </Icon>
);
export const KlarnaIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.KlarnaIcon />
    </Icon>
);

export const ApplePayIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.ApplePayIcon />
    </Icon>
);

export const IdealIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.IdealIcon />
    </Icon>
);
export const DiscountIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.DiscountIcon />
    </Icon>
);
export const ArrowDownIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ArrowDownIcon />
    </Icon>
);
export const GiftcardIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.GiftcardIcon />
    </Icon>
);

export const ProfileBlueIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileBlue />
    </Icon>
);

export const ProfileBlueKidsIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileBlueKids />
    </Icon>
);

export const ProfileGreenIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileGreen />
    </Icon>
);

export const ProfileGreenKidsIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileGreenKids />
    </Icon>
);

export const ProfileInactiveIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileInactive />
    </Icon>
);

export const ProfileInactiveKidsIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileInactiveKids />
    </Icon>
);

export const ProfilePurpleIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfilePurple />
    </Icon>
);

export const ProfilePurpleKidsIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfilePurpleKids />
    </Icon>
);

export const ProfileRedIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileRed />
    </Icon>
);

export const ProfileRedKidsIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileRedKids />
    </Icon>
);

export const ProfileYellowIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileYellow />
    </Icon>
);

export const ProfileYellowKidsIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileYellowKids />
    </Icon>
);

/**
 * Used for account without subscription
 */
export const ProfileGrayIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ProfileGray />
    </Icon>
);

export const DownIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.Down />
    </Icon>
);
export const UpIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.Up />
    </Icon>
);
export const LeftIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.Left />
    </Icon>
);
export const RightIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.Right />
    </Icon>
);

export const MenuIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.Menu />
    </Icon>
);

export const CircleArrowRightIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.CircleArrowRight />
    </Icon>
);

export const ChevronRightIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ChevronRight />
    </Icon>
);

export const ChevronLeftIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ChevronLeft />
    </Icon>
);

export const ChevronEndIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ChevronEnd />
    </Icon>
);

export const ChevronStartIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ChevronStart />
    </Icon>
);

export const ErrorIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.ErrorIcon />
    </Icon>
);

export const ErrorIconFilled: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.ErrorIconFilled />
    </Icon>
);

export const UnlockedIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.UnlockedIcon />
    </Icon>
);

export const LockedIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.LockedIcon />
    </Icon>
);

export const AppleIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.AppleIcon />
    </Icon>
);

export const GoogleIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.GoogleIcon />
    </Icon>
);

export const SuccessIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.SuccessIcon />
    </Icon>
);
export const WarningIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.WarningIcon />
    </Icon>
);

export const MagicWandIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.MagicWand />
    </Icon>
);
export const InformationIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.InformationIcon />
    </Icon>
);

export const PasswordIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.PasswordIcon />
    </Icon>
);

export const AtIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.AtIcon />
    </Icon>
);

export const ChevronDown: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.ChevronDown />
    </Icon>
);

export const InfoIconOutlined: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.InfoIconOutlined />
    </Icon>
);

export const GlassesIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props}>
        <svgs.GlassesIcon />
    </Icon>
);

export const HeadphonesIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.HeadphonesIcon />
    </Icon>
);

export const BookUpcomingIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.UpcomingIcon />
    </Icon>
);

export const BoxedArrowRightIcon: React.FunctionComponent<BaseIconProps> = (
    props
) => (
    <Icon {...props}>
        <svgs.BoxedArrowRightIcon />
    </Icon>
);

export const ConfettiIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props} icon={svgs.ConfettiIcon} />
);

export const SpinnerIcon: React.FunctionComponent<BaseIconProps> = (props) => (
    <Icon {...props} icon={svgs.SpinnerIcon} />
);

export { StarIcon } from './StarIcon';
export { RelevanceIcon } from './RelevanceIcon';
export { AbcIcon } from './AbcIcon';
export { NoteCircleIcon } from './NoteCircleIcon';
export { HourglassIcon } from './HourglassIcon';
export { PlayIcon } from './PlayIcon';
export { AccountIcon } from './AccountIcon';
export { ArrowLeftIcon } from './ArrowLeftIcon';
export { ArrowRightIcon } from './ArrowRightIcon';
export { PencilIcon } from './PencilIcon';
export { BackIcon } from './BackIcon';
export { BookIcon } from './BookIcon';
export { BookmarkIcon } from './BookmarkIcon';
export { CalendarIcon } from './CalendarIcon';
export { CheckmarkIcon } from './CheckmarkIcon';
export { CollectionIcon } from './CollectionIcon';
export { CrossIcon } from './CrossIcon';
export { EyeIcon } from './EyeIcon';
export { EyeCrossedIcon } from './EyeCrossedIcon';
export { FilterIcon } from './FilterIcon';
export { LogOutIcon } from './LogOutIcon';
export { MoreIcon } from './MoreIcon';
export { BellIcon } from './BellIcon';
export { ProfilesIcon } from './ProfilesIcon';
export { SearchIcon } from './SearchIcon';
export { SortIcon } from './SortIcon';
export { UpcomingIcon } from './UpcomingIcon';
export { InfoIcon } from './InfoIcon';
export { PadlockIcon } from './PadlockIcon';
export { BooksIcon } from './BooksIcon';
export { AuthorIcon } from './AuthorIcon';
export { CopyIcon } from './CopyIcon';
export { SmileyIcon } from './SmileyIcon';
export { ShareIcon } from './ShareIcon';
