import { api } from './api';
import { handleError } from './handleError';
import type { UseApiOptions } from './useApi';

export const FIXED_OPTIONS = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
} as const;

export const createSWRConfig = <Response>(
    config?: UseApiOptions<Response>
) => ({
    ...config,
    ...(config?.fixed ? FIXED_OPTIONS : {}),
    enabled: config?.enabled ?? true,
    addAsLiveQuery: config?.addAsLiveQuery ?? false
});

export type FetcherMethod =
    | 'get'
    | 'patch'
    | 'put'
    | 'delete'
    | 'post'
    | 'head';

export const fetcher = <T>(url: string, method: FetcherMethod = 'get') =>
    api[method]<T>(url)
        .then((res) => res.data)
        .catch((err) => {
            throw handleError(err);
        });
