import React from 'react';
import { PageLayoutFallback } from 'bb/page/PageLayoutFallback';
import css from './errorLayout.module.scss';

export type ErrorLayoutProps = {
    className?: string;
} & React.PropsWithChildren;

export const ErrorLayout = ({ children, className = '' }: ErrorLayoutProps) => (
    <PageLayoutFallback classNames={[css.root ?? '', className]}>
        {children}
    </PageLayoutFallback>
);
