// @ts-strict-ignore
import React, { type FunctionComponent } from 'react';
import classnames from 'classnames';
import { getMargins, type MarginProps } from '../Margin';
import css from './selectorButton.module.scss';

export type SelectorButtonProps = {
    onClick?(): void;
    selected?: boolean;
    disabled?: boolean;
    ariaDescribedBy?: string;
    noFocus?: boolean;
    label?: string;
    show?: boolean;
    className?: string;
    children?: React.ReactNode;
} & MarginProps;

export const SelectorButton: FunctionComponent<SelectorButtonProps> = ({
    onClick,
    selected = false,
    disabled = false,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    ariaDescribedBy,
    noFocus = false,
    label,
    show = true,
    className,
    children
}) => {
    const handleOnKeyDown = (event: React.KeyboardEvent): void => {
        // Yes, this is a space... React doesn't have "code",
        // but will in a future version. Then we can have
        // ['Enter', 'Space'] and check event.code instead.
        const triggers = ['Enter', ' '];
        if (triggers.includes(event.key) && onClick) onClick();
    };

    return (
        <div
            role="radio"
            onClick={onClick}
            onKeyDown={handleOnKeyDown}
            aria-describedby={ariaDescribedBy}
            aria-checked={selected}
            aria-label={label}
            tabIndex={noFocus ? -1 : 0}
            className={classnames(className, css.base, {
                [css.selected]: selected,
                [css.disabled]: disabled
            })}
        >
            {show && (
                <div
                    className={classnames(
                        css.selectorButton,
                        getMargins({
                            margin,
                            marginLeft,
                            marginRight,
                            marginBottom,
                            marginTop
                        })
                    )}
                >
                    {selected && (
                        <div className={classnames(css.selectorDot)} />
                    )}
                </div>
            )}
            {children}
        </div>
    );
};
