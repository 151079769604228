import { useEffect, useRef } from 'react';
import { useStableCallback } from 'bb/common/hooks/useStableCallback';
import { unRef } from 'bb/utils';
import { type MaybeRef } from '../types';

export function useResizeObserver(
    target: MaybeRef<Element | null | undefined>,
    passedCallback: ResizeObserverCallback
) {
    const observer = useRef<ResizeObserver | null>(null);
    const callback = useStableCallback(passedCallback);

    useEffect(() => {
        const element = unRef(target);
        observer.current = new ResizeObserver(callback);

        if (element) {
            observer.current.observe(element);
        }

        return () => {
            observer.current?.disconnect();
        };
    }, [callback, target]);

    return observer.current;
}
